import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

const TeamArticleTable = props => (
    <ReactTable
        filterable
        defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
                ? String(row[id])
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase())
                : true;
        }}
        defaultSortMethod={(a, b, desc) => {
            // force null and undefined to the bottom
            a = a === null || a === undefined ? "" : a;
            b = b === null || b === undefined ? "" : b;
            // force any string values to lowercase
            a = typeof a === "string" ? a.toLowerCase() : parseInt(a);
            b = typeof b === "string" ? b.toLowerCase() : parseInt(b);
            // Return either 1 or -1 to indicate a sort priority
            return a - b;
            // returning 0, undefined or any falsey value will use subsequent sorts or
            // the index as a tiebreaker
            return 0;
        }}
        style={{ width: "100%" }}
        data={Object.values(props.data)}
        columns={[
            {
                columns: [
                    {
                        Header: "Headline",
                        accessor: "headline",
                        Cell: e => (
                            <a
                                href={`https://${e.original.pagePath}`}
                                target="_blank"
                            >
                                {e.value}
                            </a>
                        ),
                        Footer: "Article Totals"
                    }
                ]
            },
            {
                columns: [
                    {
                        Header: "Name",
                        accessor: "author"
                    }
                ]
            },
            {
                columns: [
                    {
                        Header: "Unique Pageviews",
                        id: "uniquePageviews",
                        accessor: "uniquePageviews",
                        Footer: (
                            <span>
                                {// Get the total of the price
                                Object.values(props.data).reduce(
                                    (total, { uniquePageviews }) =>
                                        (total += uniquePageviews),
                                    0
                                )}
                            </span>
                        )
                    }
                ]
            },
            {
                columns: [
                    {
                        Header: "Published Date",
                        id: "publishedDate",
                        accessor: "publishedDate",
                        sortMethod: (a, b) => {
                            let dateA = new Date(a).getTime();
                            let dateB = new Date(b).getTime();
                            return dateA - dateB;
                        },
                        Cell: e => (
                            <p>
                                {new Date(e.value).toLocaleDateString()}
                                {" - "}
                                {new Date(e.value).toLocaleTimeString()}
                            </p>
                        )
                    }
                ]
            }
        ]}
        defaultSorted={[
            {
                id: "publishedDate",
                desc: true
            }
        ]}
        defaultPageSize={50}
        className="-striped -highlight"
    />
);

export default TeamArticleTable;
