import styled from "styled-components";

const Wrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-areas: "nav body";
    grid-template-columns: minmax(100px, 200px) 1fr;
    width: 100vw;

    @media (max-width: 600px) {
        background: var(--grey-light-1);
        grid-template-columns: none;
    }
`;

const Sidebar = styled.nav`
    @media (max-width: 500px) {
        display: none;
    }
    padding: 1rem;
    min-height: calc(100vh + 500px);
    border-right: 5px solid var(--grey-dark-1);
    background-color: #481bcf;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='124' height='124' viewBox='0 0 120 120'%3E%3Cpolygon fill='%23000' fill-opacity='0.11' points='120 0 120 60 90 30 60 0 0 0 0 0 60 60 0 120 60 120 90 90 120 60 120 0'/%3E%3C/svg%3E");
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(80px, 1fr));
    justify-items: center;
    color: white;
    font-size: 1.5rem;
    align-items: center;
    grid-gap: 8px;
    overflow-y: auto;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(${props => (props.img ? props.img : null)});
        width: 100%;
        height: 100%;
        max-height: 7rem;
        min-height: 80px;
        border-radius: 5px;
        background: #3f19b59c;
        border: 2px solid var(--grey-light-1);
        font-size: 2rem;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.2s;
        outline: none;
        box-shadow: var(--shadow-light);
        font-weight: 500;
        color: white;
        font-family: "Oswald";

        &:hover {
            transform: translateY(-1px) scale(1.02);
            box-shadow: var(--shadow-dark);
        }

        &:active {
            transform: translateY(0px) scale(1);
        }

        p {
            font-weight: 500;
            padding: 0.4rem;
            background-color: #565656b8;
            color: white;
            font-family: "Oswald";
            font-size: 1.5rem;
            border-radius: 3px;
            position: absolute;
            z-index: 100;
            margin: 0;
        }

        h1 {
            font-weight: 500;
            color: white;
            font-family: "Oswald";
            font-size: 2.1rem;
        }

        img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }
    }
`;

const Body = styled.main`
    padding: 2rem;
    grid-gap: 10px;
    background-color: var(--grey-light-1);
    h1,
    h2 {
        font-weight: 300;
    }
    header {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        margin-bottom: 1.5rem;
        h2 {
            display: flex;
            align-items: center;
            margin-left: 10px;
            font-size: 2rem;
            font-family: "Roboto";
            font-weight: 500;
            letter-spacing: 1.7px;
            color: #3f18b5;
            @media (max-width: 500px) {
                font-size: 1.4rem;
                white-space: nowrap;
                letter-spacing: 1.2px;
                margin-left: 5px;
            }
        }
        .buttons {
            display: flex;
            align-items: center;
            justify-content: center;

            .left {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-right: 1px solid var(--grey-light-3);
            }

            .right {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            .selected {
                background: #faf9f9;
            }

            button {
                cursor: pointer;
                border: none;
                padding: 0.7rem;
                background: white;
                font-size: 1.4rem;
                font-family: "Roboto";
                font-weight: 300;
                box-shadow: var(--shadow-light);
                transition: transform 0.1s;
                outline: none;

                &:hover {
                    transform: translateY(-1px);
                }
            }
        }
        .date {
            align-items: center;
            text-transform: uppercase;
            letter-spacing: 1px;
            display: flex;
            grid-gap: 10px;
            font-size: 1.3rem;
            margin-right: 1rem;
            justify-content: flex-end;
            @media (max-width: 500px) {
                font-size: 0.8rem;
                white-space: nowrap;
                margin-right: 0.5rem;
            }

            button {
                border: none;
                background: var(--grey-light-2);
                padding: 0.5rem;
                cursor: pointer;
                transition: all 0.2s;
                &:hover {
                    transform: translateY(-1px);
                    box-shadow: var(--shadow-light);
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }

    .dashItems {
        padding: 1rem 0;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
`;

const DashItem = styled.div`
    grid-template-rows: 20px 1fr;
    background-color: ${props => (props.bgColor ? props.bgColor : "white")};
    border-radius: 3px;
    box-shadow: var(--shadow-light);
    padding: 2rem 2rem;
    grid-gap: 10px;
    color: white;

    p {
        font-size: 1.2rem;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.8);
        letter-spacing: 0.5px;
    }

    h1 {
        padding: 1rem 0;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 1.1;
    }
`;

const ChartDash = styled.div`
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
`;

const TeamWrapper = styled.div`
    display: grid;
    grid-template-rows: 60px 1fr;
    background-color: white;
    border-radius: 3px;
    box-shadow: var(--shadow-light);

    @media (max-width: 500px) {
        min-height: 30rem;
    }

    h2 {
        display: flex;
        align-items: center;
        margin-left: 2rem;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: var(--grey-dark-2);
        letter-spacing: 0.5px;
    }
`;

const TableDash = styled.div`
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr;
`;

const ProgressDash = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    p {
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-left: 0.5rem;
        font-size: 1rem;
    }
`;

const IndividualWrapper = styled.div`
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 350px));
    justify-content: center;
    grid-gap: 15px;
`;

const Individual = styled.div`
    width: 100%;
    height: 30rem;
    padding: 2rem;
    background-color: white;
    border-radius: 5px;
    box-shadow: var(--shadow-light);
    display: grid;
    grid-template-rows: 30px repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 10px;

    h1 {
        display: flex;
        align-items: center;

        svg {
            margin-left: 0.4rem;
        }
    }
`;

const IndividualItem = styled.div`
    grid-template-rows: 20px 1fr;
    background-color: ${props => (props.bgColor ? props.bgColor : "white")};
    border-radius: 3px;
    box-shadow: var(--shadow-light);
    padding: 2rem 2rem;
    grid-gap: 10px;
    color: white;

    p {
        font-size: 1.2rem;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.8);
        letter-spacing: 0.5px;
    }

    h1 {
        padding: 1rem 0;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.1;
    }
`;

export {
    Wrapper,
    Sidebar,
    Body,
    DashItem,
    ChartDash,
    TeamWrapper,
    TableDash,
    ProgressDash,
    Individual,
    IndividualWrapper,
    IndividualItem
};
