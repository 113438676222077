import React from "react";
import "./App.css";
import { Consumer } from "./Context";
import { Redirect, Switch, Route } from "react-router-dom";
import { firebase, firebaseAuth } from "./firebase";
import { CircularProgress } from "@material-ui/core";

import Home from "./scenes/Home";
import Report from "./components/Report";
import Reports from "./scenes/Reports";
import Login from "./scenes/Login";
import Logout from "./scenes/Logout";
import Header from "./components/Header";
import Landing from "./scenes/Landing";
import CreateReport from "./scenes/CreateReport";
import UserManagement from "./scenes/UserManagement";
import firestore from "./firebase";
import RevenueInsights from "./scenes/RevenueInsights";
import PublisherInsights from "./scenes/PublisherInsights";
import TrafficInsights from "./scenes/TrafficInsights";
import Ai from "./scenes/Ai";
import LTV from "./scenes/LTV";

let styles = {
    content: {
        height: `calc(100% - ${120}px)`,
        justifyContent: "center",
        backgroundColor: "white",
        display: "flex"
    }
};

let portalUrls = {
    CI: "/reports",
    RI: "/revenueinsights",
    PI: "/publisherinsights",
    AD: "/admin/usermanagement",
    TI: "/trafficinsights",
    LT: "/ltv"
};

let AuthenticatedRoute = ({ component: Component, authenticated, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                authenticated === true ? (
                    <Component authenticated {...props} {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

let AdminRoute = ({ component: Component, authenticated, admin, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                authenticated === true && admin === true ? (
                    <Component authenticated {...props} {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/home",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

let PortalRoute = ({
    component: Component,
    authenticated,
    hasAccess,
    adminAccess,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props =>
                authenticated === true ? (
                    hasAccess === true || adminAccess === true ? (
                        <Component authenticated {...props} {...rest} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/home",
                                state: { from: props.location }
                            }}
                        />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

export default class App extends React.Component {
    constructor() {
        super();
        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.handleSideBar = this.handleSideBar.bind(this);
        this.state = {
            authenticated: false,
            currentUser: null,
            sidebar: false,
            admin: false,
            loading: true,
            hasAccessTo: []
        };
    }
    setCurrentUser(user) {
        if (user) {
            let userData = user.providerData[0];
            let ref = firestore.collection("userbase").doc(user.uid);
            ref.get().then(doc => {
                if (doc.exists) {
                    let data = doc.data();
                    let updatedUserData = Object.keys(userData).reduce(
                        (acc, key) => {
                            userData[key] === data[key]
                                ? void 0
                                : (acc[key] = userData[key]);
                            return acc;
                        },
                        {}
                    );
                    ref.update({
                        ...updatedUserData,
                        log:
                            data.log.length < 20
                                ? firebase.firestore.FieldValue.arrayUnion(
                                      Date.now()
                                  )
                                : [...data.log.splice(0, 19), Date.now()]
                    });
                    this.setState({
                        currentUser: data,
                        authenticated: true,
                        loading: false
                    });
                } else {
                    let newUser = {
                        ...userData,
                        roles: [],
                        appAccess: ["CI"],
                        log: [Date.now()]
                    };
                    ref.set(newUser);
                    this.setState({
                        loading: false,
                        currentUser: newUser,
                        authenticated: true,
                        admin: false
                    });
                }
            });
        } else {
            this.setState({
                currentUser: null,
                authenticated: false,
                loading: false
            });
        }
    }

    componentDidMount() {
        // this.setState({loading:true})
        this.removeAuthListener = firebaseAuth().onAuthStateChanged(user => {
            this.setCurrentUser(user);
        });
    }

    componentWillUnmount() {
        this.removeAuthListener();
    }
    handleSideBar = () => {
        this.setState({ sidebar: !this.state.sidebar });
    };

    render() {
        return (
            <Consumer>
                {context => {
                    return this.state.loading ? (
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <CircularProgress size={50} />
                        </div>
                    ) : (
                        <div id="App">
                            {!window.location.href.includes("/login") && (
                                <Header
                                    authenticated={this.state.authenticated}
                                    user={this.state.currentUser}
                                    handleSideBar={this.handleSideBar}
                                    open={this.state.sidebar}
                                    admin={
                                        this.state.authenticated &&
                                        this.state.currentUser &&
                                        this.state.currentUser.roles.includes(
                                            "admin"
                                        )
                                    }
                                />
                            )}

                            <div id="Content" style={styles.content}>
                                <Switch>
                                    <AuthenticatedRoute
                                        exact
                                        path="/myaccount"
                                        authenticated={this.state.authenticated}
                                        component={() => <h1>My account</h1>}
                                    />
                                    <AuthenticatedRoute
                                        exact
                                        path="/home"
                                        authenticated={this.state.authenticated}
                                        component={Home}
                                        user={this.state.currentUser}
                                    />
                                    <AdminRoute
                                        exact
                                        path="/ai"
                                        authenticated={this.state.authenticated}
                                        admin={
                                            this.state.authenticated &&
                                            this.state.currentUser.roles.includes(
                                                "admin"
                                            )
                                        }
                                        component={Ai}
                                    />

                                    <AdminRoute
                                        exact
                                        path="/admin/usermanagement"
                                        authenticated={this.state.authenticated}
                                        admin={
                                            this.state.authenticated &&
                                            this.state.currentUser.roles.includes(
                                                "admin"
                                            )
                                        }
                                        component={UserManagement}
                                    />
                                    {/* <AuthenticatedRoute exact path="/home" authenticated={this.state.authenticated} component={Home} /> */}
                                    <PortalRoute
                                        exact
                                        path="/reports"
                                        authenticated={this.state.authenticated}
                                        hasAccess={
                                            this.state.authenticated &&
                                            this.state.currentUser.appAccess.includes(
                                                "CI"
                                            )
                                        }
                                        admin={
                                            this.state.authenticated &&
                                            (this.state.currentUser.roles.includes(
                                                "admin"
                                            ) ||
                                                this.state.currentUser.roles.includes(
                                                    "prod"
                                                ))
                                        }
                                        component={Reports}
                                        user={this.state.currentUser}
                                    />

                                    <PortalRoute
                                        exact
                                        path="/ltv"
                                        authenticated={this.state.authenticated}
                                        hasAccess={
                                            this.state.authenticated &&
                                            this.state.currentUser.roles.includes(
                                                "admin"
                                            )
                                        }
                                        admin={
                                            this.state.authenticated &&
                                            this.state.currentUser.roles.includes(
                                                "admin"
                                            )
                                        }
                                        component={LTV}
                                        user={this.state.currentUser}
                                    />
                                    <PortalRoute
                                        exact
                                        path="/trafficinsights"
                                        authenticated={this.state.authenticated}
                                        hasAccess={
                                            this.state.authenticated &&
                                            this.state.currentUser.appAccess.includes(
                                                "TI"
                                            )
                                        }
                                        admin={
                                            this.state.authenticated &&
                                            this.state.currentUser.roles.includes(
                                                "admin"
                                            )
                                        }
                                        component={TrafficInsights}
                                        user={this.state.currentUser}
                                    />
                                    <PortalRoute
                                        exact
                                        path="/revenueinsights"
                                        authenticated={this.state.authenticated}
                                        hasAccess={
                                            this.state.authenticated &&
                                            this.state.currentUser.appAccess.includes(
                                                "RI"
                                            )
                                        }
                                        admin={
                                            this.state.authenticated &&
                                            this.state.currentUser.roles.includes(
                                                "admin"
                                            )
                                        }
                                        component={RevenueInsights}
                                    />
                                    <PortalRoute
                                        exact
                                        path="/reports/:id"
                                        authenticated={this.state.authenticated}
                                        user={this.state.currentUser}
                                        hasAccess={
                                            this.state.authenticated &&
                                            this.state.currentUser.appAccess.includes(
                                                "CI"
                                            )
                                        }
                                        admin={
                                            this.state.authenticated &&
                                            this.state.currentUser.roles.includes(
                                                "admin"
                                            )
                                        }
                                        component={Report}
                                    />
                                    <PortalRoute
                                        exact
                                        path="/publisherinsights"
                                        authenticated={this.state.authenticated}
                                        user={this.state.currentUser}
                                        hasAccess={
                                            this.state.authenticated &&
                                            this.state.currentUser.appAccess.includes(
                                                "PI"
                                            )
                                        }
                                        admin={
                                            this.state.authenticated &&
                                            this.state.currentUser.roles.includes(
                                                "admin"
                                            )
                                        }
                                        component={PublisherInsights}
                                    />
                                    <AdminRoute
                                        exact
                                        path="/createreport"
                                        authenticated={this.state.authenticated}
                                        admin={
                                            this.state.authenticated &&
                                            (this.state.currentUser.roles.includes(
                                                "admin"
                                            ) ||
                                                this.state.currentUser.roles.includes(
                                                    "prod"
                                                ))
                                        }
                                        component={CreateReport}
                                    />
                                    <Route
                                        exact
                                        path="/welcome"
                                        component={Landing}
                                    />
                                    <Route
                                        path="/404"
                                        component={() => <h1>ERROR PAGE</h1>}
                                    />

                                    <Route
                                        exact
                                        path="/login"
                                        render={props => {
                                            return (
                                                <Login
                                                    setCurrentUser={
                                                        this.setCurrentUser
                                                    }
                                                    authenticated={
                                                        this.state.authenticated
                                                    }
                                                    {...props}
                                                />
                                            );
                                        }}
                                    />
                                    <Route
                                        exact
                                        path="/logout"
                                        component={Logout}
                                    />
                                    <Redirect from="/" to="home" />
                                </Switch>
                            </div>
                        </div>
                    );
                }}
            </Consumer>
        );
    }
}
