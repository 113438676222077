import React, { Component } from "react";
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";
import randomColors from "../../assets/randomColors.json";

class LeaderboardGraph extends Component {
    state = {
        teamArticleStats: {}
    };

    componentWillMount() {
        const { data } = this.props;
        // let startOf = new moment().startOf("month").format("MM/DD/YYYY");
        // let endOf = new moment().endOf("month").format("MM/DD/YYYY");

        // let articlesByDate = Object.values(data.memberStats).reduce(
        //     (acc, member) => {
        //         Object.values(member.articles).map(article => {
        //             let toDate = new Date(
        //                 article.publishedDate
        //             ).toLocaleDateString();

        //             if (acc[toDate]) {
        //                 acc[toDate] = [...acc[toDate], article];
        //             } else {
        //                 acc[toDate] = [article];
        //             }
        //         });

        //         return acc;
        //     },
        //     {}
        // );

        let articlesAllDates = Object.values(data.memberStats).reduce(
            (acc, val) => {
                const { author } = val;
                Object.values(val.articles).map(article => {
                    let toDate = new Date(
                        article.pudlishedDate
                            ? article.pudlishedDate
                            : article.publishedDate
                    ).toLocaleDateString();
                    if (acc[toDate]) {
                        acc[toDate].pageviews += article.uniquePageviews;
                    } else {
                        acc[toDate] = {
                            day: new Date(toDate).getDate(),
                            date: toDate,
                            pageviews: article.uniquePageviews
                        };
                    }
                });
                return acc;
            },
            []
        );

        this.setState({
            teamArticleStats: Object.values(articlesAllDates).sort(
                (a, b) => a.day - b.day
            )
        });
    }

    render() {
        return (
            <ResponsiveContainer width="99%" height="100%">
                <LineChart
                    data={this.state.teamArticleStats}
                    margin={{ top: 10, right: 30, left: 0, bottom: 10 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" name="Date" />
                    <YAxis />
                    <Tooltip
                        formatter={(value, name, props) =>
                            `${value} Unique Pageviews`
                        }
                    />
                    <Line
                        dot={false}
                        name="Unique Pageviews"
                        type="monotone"
                        dataKey="pageviews"
                        stroke="#01a88c"
                    />

                    <Legend />
                </LineChart>
            </ResponsiveContainer>
        );
    }
}

export default LeaderboardGraph;
