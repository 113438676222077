import React from "react";
import { Consumer } from "../Context";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Label,
    ResponsiveContainer,
    CartesianAxis
} from "recharts";
import {
    Magnify,
    MagnifyClose,
    Crosshairs,
    ArrowLeft,
    ArrowRight
} from "mdi-material-ui";
import Button from "../components/styled/Button";
import { StylishInput } from "../components/styled/Inputs";

export default class TrafficInsightsGraph extends React.Component {
    static contextType = Consumer;
    constructor(props) {
        super(props);
        this.state = {
            dataType: "all",
            articleSearchInput: "",
            articleURLArr: [],
            currentPage: 0,
            paginatedAmount: 5,
            searchData: null,
            statusMessage: ""
        };
    }

    componentWillMount() {
        const { trafficUrl } = this.context.state;
        if (trafficUrl) {
            this.setState({
                articleURLArr: [trafficUrl],
                dataType: "searchByUrl"
            });
        }
    }

    previousPage = () => {
        if (this.state.currentPage !== 0)
            this.setState(prevState => ({
                currentPage: prevState.currentPage - 1
            }));
    };

    nextPage = () => {
        if (
            (this.state.currentPage + 1) * this.state.paginatedAmount <
            (this.state.dataType === "all"
                ? Object.keys(this.props.data[0]).length
                : this.state.dataType === "searchByInput"
                ? this.state.searchData.length
                : null)
        ) {
            this.setState(prevState => ({
                currentPage: prevState.currentPage + 1
            }));
        }
    };

    handleSearch() {
        let filtered = Object.entries(this.props.data[0]).filter((val, i) => {
            if (val[0] !== "name") {
                return val[0].indexOf(this.state.articleSearchInput) !== -1;
            }
        });
        if (filtered.length > this.state.paginatedAmount) {
            let sorted = filtered.sort((a, b) => {
                return b - a;
            });
            this.setState({
                searchData: sorted,
                statusMessage: "Too many results... showing top 6!",
                paginatedAmount: 5
            });
        } else {
            this.setState({ searchData: filtered, paginatedAmount: 5 });
        }
    }

    findArticleByUrl = type => {
        if (type === "url") {
            if (this.state.articleSearchInput === "") {
                return alert("Search field cannot be empty!");
            } else {
                this.setState({
                    dataType: "searchByUrl",
                    articleURLArr: [
                        ...this.state.articleURLArr,
                        this.state.articleSearchInput
                    ],
                    articleSearchInput: "",
                    currentPage: 0
                });
            }
        } else {
            if (this.state.articleSearchInput === "") {
                return alert("Search field cannot be empty!");
            } else {
                this.handleSearch();
                this.setState({
                    dataType: "searchByInput",
                    currentPage: 0
                });
            }
        }
    };

    render() {
        let defaultState = {
            dataType: "all",
            articleSearchInput: "",
            articleURLArr: [],
            statusMessage: "",
            currentPage: 0,
            paginatedAmount: 5,
            searchData: null
        };
        //Move this later
        let colorArray = [
            "#FF6633",
            "#00B3E6",
            "#FF33FF",
            "#E6B3B3",
            "#FF99E6",
            "#FF1A66",
            "#E6331A",
            "#33FFCC",
            "#66994D",
            "#B366CC",
            "#4D8000",
            "#B33300",
            "#CC80CC",
            "#66664D",
            "#991AFF",
            "#E666FF",
            "#4DB3FF",
            "#1AB399"
        ];

        let pageStart = this.state.currentPage * this.state.paginatedAmount;
        let pageEnd =
            this.state.currentPage * this.state.paginatedAmount +
            this.state.paginatedAmount;

        return (
            <>
                {this.state.articleURLArr &&
                    this.state.articleURLArr.length > 0 && (
                        <>
                            <h2 style={{ marginLeft: "2rem" }}>
                                Currently viewing...
                            </h2>
                            <ul style={{ marginLeft: "2rem" }}>
                                {this.state.articleURLArr.map(item => (
                                    <div key={item} style={{ display: "flex" }}>
                                        <li>{item}</li>
                                        <Button
                                            height="2rem"
                                            width="2rem"
                                            style={{
                                                marginLeft: "1rem"
                                            }}
                                            onClick={() => {
                                                let x = this.state.articleURLArr.filter(
                                                    url => item !== url
                                                );
                                                if (x.length === 0) {
                                                    return this.setState(
                                                        defaultState
                                                    );
                                                }
                                                this.setState({
                                                    articleURLArr: x
                                                });
                                            }}
                                        >
                                            &times;
                                        </Button>
                                    </div>
                                ))}
                            </ul>
                        </>
                    )}
                <div className="ti-header-bar">
                    <div
                        style={{
                            margin: "1rem",
                            marginRight: "1rem",
                            display: "flex"
                        }}
                    >
                        <StylishInput
                            placeholder="search"
                            value={this.state.articleSearchInput}
                            onChange={e =>
                                this.setState({
                                    articleSearchInput: e.target.value
                                })
                            }
                        />
                        <Button
                            width="12rem"
                            onClick={e => {
                                this.findArticleByUrl("url");
                            }}
                        >
                            <div className="button-text">Fetch URL</div>
                            <Crosshairs color="primary" />
                        </Button>
                        <Button
                            onClick={e => {
                                this.findArticleByUrl();
                            }}
                        >
                            <div className="button-text">Search</div>
                            <Magnify color="primary" />
                        </Button>
                        <Button onClick={e => this.setState(defaultState)}>
                            <div className="button-text">Reset</div>
                            <MagnifyClose color="primary" />
                        </Button>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <StylishInput
                                name="paginationInput"
                                id="paginationInput"
                                min={1}
                                max={30}
                                style={{
                                    marginLeft: "1rem",
                                    marginRight: "1rem"
                                }}
                                type="number"
                                onChange={e =>
                                    this.setState({
                                        paginatedAmount: parseInt(
                                            e.target.value
                                        )
                                    })
                                }
                                width="5rem"
                                value={this.state.paginatedAmount}
                            />
                            <label htmlFor="paginationInput"># Of Lines</label>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        width: "90%",
                        margin: "0 auto",
                        marginBottom: "1rem"
                    }}
                    className="pagination-buttons"
                >
                    <div
                        className="paginationButton"
                        onClick={e => this.previousPage()}
                    >
                        <ArrowLeft color="primary" />{" "}
                        <span className="padingationButton__text-left">
                            Back
                        </span>
                    </div>
                    <div className="revenue-table__footer-rowAmount">
                        Page {this.state.currentPage + 1}
                    </div>
                    <div
                        className="paginationButton"
                        onClick={e => this.nextPage()}
                    >
                        <span className="padingationButton__text">Next</span>{" "}
                        <ArrowRight color="primary" />
                    </div>
                </div>
                {this.props.data && (
                    <ResponsiveContainer width={"97%"} height={"100%"}>
                        <LineChart
                            syncId="top"
                            data={this.props.data}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                            <XAxis
                                dataKey="prettyDate"
                                minTickGap={1}
                                tick={{ minTickGap: 1 }}
                            />
                            <YAxis
                                label={{
                                    value: "Pageviews",
                                    angle: -90,
                                    position: "insideLeft"
                                }}
                                scale="log"
                                domain={["dataMin", "dataMax"]}
                            />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            {this.state.paginatedAmount < 20 && <Legend />}
                            {this.state.dataType === "all" &&
                                Object.entries(this.props.data[0])
                                    .sort((a, b) => {
                                        return b[1] - a[1];
                                    })
                                    .filter(val => val[0] !== "name")
                                    .slice(pageStart, pageEnd)
                                    .map((val, i) => {
                                        return (
                                            <Line
                                                dot={false}
                                                connectNulls={true}
                                                key={i}
                                                type="monotone"
                                                dataKey={val[0]}
                                                stroke={colorArray[i]}
                                            />
                                        );
                                    })}

                            {this.state.dataType === "searchByInput" &&
                                this.state.searchData
                                    .slice(pageStart, pageEnd)
                                    .map((val, i) => {
                                        return val[0] !== "name" ? (
                                            <Line
                                                dot={false}
                                                connectNulls={true}
                                                key={i}
                                                type="monotone"
                                                dataKey={val[0]}
                                                stroke={colorArray[i]}
                                            />
                                        ) : null;
                                    })}

                            {this.state.dataType === "searchByUrl" &&
                                (this.state.articleURLArr.length ? (
                                    this.state.articleURLArr.map((url, i) => {
                                        return (
                                            <Line
                                                dot={false}
                                                connectNulls={true}
                                                key={url}
                                                type="monotone"
                                                dataKey={url.split(".com")[1]}
                                                stroke={colorArray[i]}
                                            />
                                        );
                                    })
                                ) : (
                                    <Line
                                        dot={false}
                                        connectNulls={true}
                                        type="monotone"
                                        dataKey={
                                            this.state.articleSearchInput.split(
                                                ".com"
                                            )[1]
                                        }
                                        stroke="#f33d22"
                                    />
                                ))}
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </>
        );
    }
}
