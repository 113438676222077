import React from "react";
import { Link } from "react-router-dom";

let portalNames = {
    LT: "Visitor Value",
    CI: "Campaign Insights",
    RI: "Revenue Insights",
    PI: "Publisher Insights",
    AD: "Admin",
    TI: "Traffic Insights"
};

let portalUrls = {
    LT: "/ltv",
    CI: "/reports",
    RI: "/revenueinsights",
    PI: "/publisherinsights",
    AD: "/admin/usermanagement",
    TI: "/trafficinsights"
};

let portalImgs = {
    LT: require("../assets/LT.png"),
    CI: "https://i.imgur.com/fV4uZ6I.jpg",
    PI: "https://i.imgur.com/KBWaqw1.png",
    RI: "https://i.imgur.com/LoTEtAx.png",
    TI: "https://i.imgur.com/IK1ewHi.png",
    AD: "https://i.gyazo.com/561752939eb25dc339bb0c18d4277a6f.png"
};

let adminAccess = ["LT", "CI", "RI", "TI", "PI", "AD"];

export default class Home extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="homepage">
                <div
                    className="homepage-portals"
                    style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    {!!this.props.user &&
                    this.props.user.roles.includes("admin")
                        ? adminAccess.map(val => (
                              <Link key={val} to={portalUrls[val]}>
                                  <div
                                      key={val}
                                      className={`homepage-portals__item homepage-portals__item--${val}`}
                                  >
                                      <div
                                          style={{
                                              background: `url(${
                                                  portalImgs[val]
                                              })`,
                                              width: "100%",
                                              backgroundSize: "cover",
                                              boxShadow: "var(--shadow-light)",
                                              border: "1px solid black"
                                          }}
                                          className="portal-item-icon"
                                      >
                                          <p
                                              style={{
                                                  backgroundColor: "#000000b8",
                                                  padding: "1rem",
                                                  borderRadius: "5px"
                                              }}
                                          >
                                              {portalNames[val]}
                                          </p>
                                      </div>
                                  </div>
                              </Link>
                          ))
                        : this.props.user.appAccess.length > 0 &&
                          this.props.user.appAccess.map((val, i) => {
                              return (
                                  <Link key={val} to={portalUrls[val]}>
                                      <div
                                          key={val}
                                          className={`homepage-portals__item homepage-portals__item--${val}`}
                                      >
                                          <div
                                              style={{
                                                  background: `url(${
                                                      portalImgs[val]
                                                  })`,
                                                  width: "100%",
                                                  backgroundSize: "cover",
                                                  boxShadow:
                                                      "var(--shadow-light)",
                                                  border: "1px solid black"
                                              }}
                                              className="portal-item-icon"
                                          >
                                              <p
                                                  style={{
                                                      backgroundColor:
                                                          "#000000b8",
                                                      padding: "1rem",
                                                      borderRadius: "5px"
                                                  }}
                                              >
                                                  {portalNames[val]}
                                              </p>
                                          </div>
                                      </div>
                                  </Link>
                              );
                          })}
                </div>
            </div>
        );
    }
}
