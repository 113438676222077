import React from "react";
import { Consumer } from "../Context";
import { Menu, MenuItem, TextField, Button } from "@material-ui/core";
import PreviewData from "../components/PreviewData";
import firestore, { storage } from "../firebase";
import { Redirect } from "react-router-dom";

let dev = false;

let date = new Date();
let today =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2);
date.setDate(date.getDate() - 7);
let lastWeek =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2);

export default class CreateReport extends React.Component {
    initialState = {
        value: 0,
        form: {
            clientName: "",
            owners: "",
            projectName: "",
            projectManager: "",
            date: {
                startDate: lastWeek,
                endDate: today
            }
        },
        anchorEl: null,
        inputs: {},
        customInputs: {},
        tempfiles: {}
    };
    constructor(props) {
        super(props);
        this.state = { ...this.initialState, previewData: null };
        this.clearReport = this.clearReport.bind(this);
        this.saveReport = this.saveReport.bind(this);
        this.editReport = this.editReport.bind(this);
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };
    updateForm = (e, formName, grouping) => {
        if (grouping) {
            let existing =
                this.state.form && this.state.form[grouping]
                    ? this.state.form[grouping]
                    : [];

            this.setState({
                form: {
                    ...this.state[formName],
                    [grouping]: { ...existing, [e.target.id]: e.target.value }
                }
            });
        } else {
            this.setState({
                form: { ...this.state[formName], [e.target.id]: e.target.value }
            });
        }
    };
    uploadFiles = (file, project) => {
        return new Promise((resolve, reject) => {
            var pathReference = storage.ref(
                project + "/" + file.loc + "_" + file.name
            );
            pathReference
                .put(file)
                .then(function(snapshot) {
                    pathReference.getDownloadURL().then(function(url) {
                        resolve(url);
                    });
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    submitForm = (e, context) => {
        e.preventDefault();
        context.setState({ loading: true });
        console.log("submit");
        let uploads = Object.keys(this.state.tempfiles).reduce((acc, key) => {
            this.state.tempfiles[key].map(file => {
                file.loc = key;
                acc.push(file);
            });
            return acc;
        }, []);

        Promise.all(uploads.map(x => this.uploadFiles(x, "uploads"))).then(
            uploadedUrls => {
                let sortedCustomFields = [];
                if (
                    this.state.form.custom != null ||
                    this.state.form.custom != undefined
                ) {
                    sortedCustomFields = Object.keys(
                        this.state.form.custom
                    ).reduce((acc, key) => {
                        let i = key.split("custom_field_")[1].split("_")[0];
                        acc[i] = {
                            ...acc[i],
                            [key]: this.state.form.custom[key]
                        } || {
                            [key]: this.state.form.custom[key]
                        };
                        return acc;
                    }, {});

                    Object.keys(sortedCustomFields).forEach(key => {
                        sortedCustomFields[key] = Object.keys(
                            sortedCustomFields[key]
                        ).reduce((acc, k) => {
                            if (!k.includes("title")) {
                                acc[
                                    k
                                        .split("custom_field_" + key + "_")[1]
                                        .split("_")[0]
                                ] = {
                                    ...acc[
                                        [
                                            k
                                                .split(
                                                    "custom_field_" + key + "_"
                                                )[1]
                                                .split("_")[0]
                                        ]
                                    ],
                                    [[
                                        k
                                            .split(
                                                "custom_field_" + key + "_"
                                            )[1]
                                            .split("_")[1]
                                    ]]: sortedCustomFields[key][k]
                                } || {
                                    [[
                                        k
                                            .split(
                                                "custom_field_" + key + "_"
                                            )[1]
                                            .split("_")[0]
                                    ]]: sortedCustomFields[key][k]
                                };
                            } else {
                                acc["title"] = sortedCustomFields[key][k];
                            }
                            return acc;
                        }, {});
                    });
                    console.log(sortedCustomFields);

                    // uploadedUrls.forEach((u, i) => {
                    //     sortedCustomFields[
                    //         u.split("custom_field_")[1].split("_")[0]
                    //     ]["media_" + i] = u;
                    // });
                    uploadedUrls.forEach((u, i) => {
                        sortedCustomFields[
                            u.split("custom_field_")[1].split("_")[0]
                        ]["media"] =
                            sortedCustomFields[
                                u.split("custom_field_")[1].split("_")[0]
                            ]["media"] || [];
                        sortedCustomFields[
                            u.split("custom_field_")[1].split("_")[0]
                        ]["media"].push(u);
                    });
                }
                console.log(sortedCustomFields);
                if (!dev) {
                    let owners = [];
                    this.state.form.owners
                        ? (owners = this.state.form.owners
                              .toLowerCase()
                              .split(","))
                        : (owners = []);
                    let form = {
                        ...this.state.form,
                        custom: sortedCustomFields,
                        owners: owners,
                        type: "Prod-report",
                        createdDate: new Date()
                    };
                    let newStartDate = form.date.startDate.replace(/-/g, "");
                    let newEndDate = form.date.endDate.replace(/-/g, "");
                    if (newStartDate <= newEndDate) {
                        fetch("/createProdReport", {
                            credentials: "include",
                            method: "POST",
                            body: JSON.stringify(form),
                            headers: {
                                "Content-Type":
                                    "application/json; charset=utf-8"
                            }
                        })
                            .then(response => {
                                return response.json();
                            })
                            .then(res => {
                                console.log(res);
                                context.setState({ loading: false });
                                // firestore
                                //     .collection("campaign-reports")
                                //     .add(res)
                                //     .then(x => {
                                //         console.log(x);
                                //     });

                                this.setState({
                                    previewData: res
                                });
                            })
                            .catch(err => {
                                console.log(err);
                                context.setState({
                                    loading: false,
                                    dialog: {
                                        title: "Error",
                                        msg:
                                            "An Error occurred fetching this report, please verify your information."
                                    }
                                });
                            });
                    } else {
                        context.setState({
                            loading: false,
                            dialog: {
                                title: "Date Error",
                                msg: "End date cannot be before start date!"
                            }
                        });
                    }
                }
            }
        );
    };
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    clearReport = () => {
        console.log("called clearreport");
        this.setState({
            ...this.initialState,
            previewData: false,
            redirect: false
        });
    };
    editReport = () => {
        console.log("called editReport");
        this.setState({
            previewData: null,
            redirect: false
        });
    };
    saveReport = () => {
        console.log("called savereport");
        firestore
            .collection("campaign-reports")
            .add(this.state.previewData)
            .then(x => {
                console.log(x);
                this.clearReport();
            });
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    createInput(field) {
        let typeCount = 0;
        Object.values(this.state.inputs).forEach(input => {
            if (input.type === field.type) {
                typeCount++;
            }
        });
        let newInput = {
            label: field.name,
            key:
                field.name
                    .toLowerCase()
                    .split(" ")
                    .join("_") +
                "_" +
                typeCount,
            type: field.type
        };
        !!field.goalName ? (newInput.goalLabel = field.goalName) : void 0;
        !!field.goalName
            ? (newInput.goalKey =
                  field.goalName
                      .toLowerCase()
                      .split(" ")
                      .join("_") +
                  "_" +
                  typeCount)
            : void 0;
        this.setState({
            inputs: { ...this.state.inputs, [newInput.key]: newInput }
        });
    }
    handleFiles = (files, key) => {
        this.setState({
            tempfiles: { ...this.state.tempfiles, [key]: [...files] }
        });
    };
    render() {
        const fields = [
            { name: "Page path", type: "content", goalName: "Booked reads" },
            {
                name: "Facebook post",
                type: "fborganic"
            },
            {
                name: "Facebook adset",
                type: "fbads",
                goalName: "Booked impressions"
            },
            // { name: "Instagram story", type: "insta" },
            { name: "DFP lineitem", type: "dfp" },
            { name: "Custom Field", type: "custom" }
            // { name: "Youtube video", type: "yt" },
        ];
        return (
            <Consumer>
                {context => {
                    const { anchorEl, redirect } = this.state;
                    return redirect ? (
                        <Redirect to={"/reports"} />
                    ) : (
                        <div className="create-report">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <h2 className="create-report__title">
                                    {"Create Report".toUpperCase()}
                                </h2>
                            </div>
                            <div className="report-form-container">
                                <form
                                    style={{ width: "90%", maxWidth: "1000px" }}
                                >
                                    <input
                                        className="create-report__textfield"
                                        id="projectName"
                                        value={this.state.form.projectName}
                                        placeholder="Project Name"
                                        required={true}
                                        style={{ width: "100%" }}
                                        onChange={e => {
                                            this.updateForm(e, "form");
                                        }}
                                    />
                                    <input
                                        className="create-report__textfield"
                                        id="projectManager"
                                        value={this.state.form.projectManager}
                                        placeholder="Project Manager"
                                        required={false}
                                        style={{ width: "100%" }}
                                        onChange={e => {
                                            this.updateForm(e, "form");
                                        }}
                                    />
                                    <input
                                        className="create-report__textfield"
                                        id="clientName"
                                        value={this.state.form.clientName}
                                        placeholder="Client Name"
                                        style={{ width: "100%" }}
                                        onChange={e => {
                                            this.updateForm(e, "form");
                                        }}
                                    />
                                    <TextField
                                        className="create-report-label"
                                        id="startDate"
                                        label="Start Date"
                                        type="date"
                                        style={{
                                            width: "50%",
                                            paddingRight: "20px"
                                        }}
                                        value={this.state.form.date.startDate}
                                        required
                                        onChange={e => {
                                            this.updateForm(e, "form", "date");
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <TextField
                                        className="create-report-label"
                                        id="endDate"
                                        label="End Date"
                                        type="date"
                                        style={{ width: "50%" }}
                                        required
                                        value={this.state.form.date.endDate}
                                        onChange={e => {
                                            this.updateForm(e, "form", "date");
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />

                                    <input
                                        className="create-report__textfield"
                                        id="owners"
                                        placeholder="Report Administrator Emails"
                                        value={this.state.form.owners}
                                        style={{ width: "100%" }}
                                        required
                                        onChange={e => {
                                            this.updateForm(e, "form");
                                        }}
                                    />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        aria-owns={anchorEl ? "features" : null}
                                        aria-haspopup="true"
                                        onClick={this.handleClick}
                                    >
                                        Add feature
                                    </Button>
                                    <Menu
                                        id="features"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={this.handleClose}
                                    >
                                        {" "}
                                        {fields.map(field => {
                                            return (
                                                <MenuItem
                                                    key={field.name}
                                                    onClick={e =>
                                                        this.createInput(field)
                                                    }
                                                >
                                                    {field.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                    <Button
                                        type="submit"
                                        required
                                        style={{ margin: 10 }}
                                        variant="outlined"
                                        color="primary"
                                        onClick={e =>
                                            this.submitForm(e, context)
                                        }
                                        className="button-submit"
                                        primary="true"
                                    >
                                        Preview
                                    </Button>
                                    <Button
                                        onClick={e => {
                                            this.setState(this.initialState);
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    {this.state.inputs &&
                                        Object.keys(this.state.inputs).map(
                                            key => {
                                                let field = this.state.inputs[
                                                    key
                                                ];

                                                return key.includes(
                                                    "custom_field"
                                                ) ? (
                                                    <div
                                                        style={{
                                                            border:
                                                                ".5px solid grey",
                                                            padding: 5,
                                                            margin: 3
                                                        }}
                                                        key={
                                                            "button1" +
                                                            field.key
                                                        }
                                                    >
                                                        <label
                                                            htmlFor={
                                                                field.key +
                                                                "_" +
                                                                "title"
                                                            }
                                                        >
                                                            section title:
                                                        </label>
                                                        <input
                                                            id={
                                                                field.key +
                                                                "_" +
                                                                "title"
                                                            }
                                                            onChange={e => {
                                                                this.updateForm(
                                                                    e,
                                                                    "form",
                                                                    field.type
                                                                );
                                                            }}
                                                        />
                                                        <input
                                                            id={key}
                                                            type="file"
                                                            accept="image/*"
                                                            multiple={true}
                                                            onChange={e => {
                                                                this.handleFiles(
                                                                    e.target
                                                                        .files,
                                                                    field.key
                                                                );
                                                            }}
                                                        />
                                                        <Button
                                                            id={
                                                                "button2" +
                                                                field.key
                                                            }
                                                            onClick={e => {
                                                                let fieldKeyArr = [];
                                                                this.state
                                                                    .customInputs[
                                                                    field.key
                                                                ]
                                                                    ? (fieldKeyArr = [
                                                                          ...this
                                                                              .state
                                                                              .customInputs[
                                                                              field
                                                                                  .key
                                                                          ],
                                                                          field.key
                                                                      ])
                                                                    : (fieldKeyArr = [
                                                                          field.key
                                                                      ]);
                                                                this.setState({
                                                                    customInputs: {
                                                                        ...this
                                                                            .state
                                                                            .customInputs,
                                                                        [field.key]: fieldKeyArr
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            add field
                                                        </Button>
                                                        {this.state
                                                            .customInputs[
                                                            key
                                                        ] &&
                                                            this.state
                                                                .customInputs[
                                                                key
                                                            ].length > 0 &&
                                                            this.state.customInputs[
                                                                key
                                                            ].map((k, i) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            k +
                                                                            "_" +
                                                                            i +
                                                                            "-div"
                                                                        }
                                                                    >
                                                                        <label
                                                                            htmlFor={
                                                                                k +
                                                                                "_" +
                                                                                i +
                                                                                "_label"
                                                                            }
                                                                        >
                                                                            label:
                                                                        </label>
                                                                        <input
                                                                            id={
                                                                                k +
                                                                                "_" +
                                                                                i +
                                                                                "_label"
                                                                            }
                                                                            onChange={e => {
                                                                                this.updateForm(
                                                                                    e,
                                                                                    "form",
                                                                                    field.type
                                                                                );
                                                                            }}
                                                                        />
                                                                        <label
                                                                            htmlFor={
                                                                                k +
                                                                                "_" +
                                                                                i +
                                                                                "_stat"
                                                                            }
                                                                        >
                                                                            value:
                                                                        </label>
                                                                        <input
                                                                            id={
                                                                                k +
                                                                                "_" +
                                                                                i +
                                                                                "_stat"
                                                                            }
                                                                            onChange={e => {
                                                                                this.updateForm(
                                                                                    e,
                                                                                    "form",
                                                                                    field.type
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                        {/* <input
                                                                id={field.key}
                                                                placeholder={
                                                                    field.label
                                                                }
                                                                style={{
                                                                    flexGrow: 1
                                                                }}
                                                                onChange={e => {
                                                                    this.updateForm(
                                                                        e,
                                                                        "form",
                                                                        field.type
                                                                    );
                                                                }}
                                                            /> */}
                                                    </div>
                                                ) : (
                                                    <div
                                                        key={field.key}
                                                        style={{
                                                            width: "100%",
                                                            display: "flex"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexGrow: 1
                                                            }}
                                                        >
                                                            <input
                                                                className="create-report__textfield"
                                                                id={field.key}
                                                                placeholder={
                                                                    field.label
                                                                }
                                                                style={{
                                                                    flexGrow: 1
                                                                }}
                                                                onChange={e => {
                                                                    this.updateForm(
                                                                        e,
                                                                        "form",
                                                                        field.type
                                                                    );
                                                                }}
                                                            />
                                                            {!!field.goalKey && (
                                                                <input
                                                                    className="create-report__textfield"
                                                                    type={
                                                                        "number"
                                                                    }
                                                                    id={
                                                                        field.goalKey
                                                                    }
                                                                    placeholder={
                                                                        field.goalLabel
                                                                    }
                                                                    style={{
                                                                        width:
                                                                            "40%",
                                                                        marginLeft: 10
                                                                    }}
                                                                    onChange={e => {
                                                                        this.updateForm(
                                                                            e,
                                                                            "form",
                                                                            "goal"
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        <Button
                                                            id={key}
                                                            onClick={e => {
                                                                let newData = this
                                                                    .state
                                                                    .inputs;
                                                                delete newData[
                                                                    key
                                                                ];

                                                                this.setState({
                                                                    inputs: newData
                                                                });
                                                            }}
                                                        >
                                                            delete
                                                        </Button>
                                                    </div>
                                                );
                                            }
                                        )}
                                </form>
                            </div>

                            {this.state.previewData && (
                                <PreviewData
                                    report={this.state.previewData}
                                    clearReport={this.clearReport}
                                    saveReport={this.saveReport}
                                    editReport={this.editReport}
                                />
                            )}
                        </div>
                    );
                }}
            </Consumer>
        );
    }
}
