import React from "react";
import { Slide } from "@material-ui/core";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebaseAuth } from "../firebase";
import { Redirect } from "react-router-dom";
import Background from "../assets/bg2.jpg";

let styles = {
    background: {
        background: "url(" + Background + ")",
        width: "100%",
        height: `100vh`,
        backgroundSize: "cover",
        top: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    title: {
        // borderTop: "5px solid #ff2634",
        fontFamily: "Oswald",
        fontSize: "2rem",
        textAlign: "left",
        color: "dark grey"
    },
    loginLogo: {
        width: "200px",
        paddingTop: 15
    }
};

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
    }
    uiConfig = () => {
        return {
            signInFlow: "popup",
            signInOptions: [
                firebaseAuth.GoogleAuthProvider.PROVIDER_ID,
                firebaseAuth.EmailAuthProvider.PROVIDER_ID,
                firebaseAuth.FacebookAuthProvider.PROVIDER_ID
            ],
            callbacks: {
                signInSuccessWithAuthResult: user => {
                    if (user) {
                        this.props.setCurrentUser(user);
                    }
                }
            }
        };
    };

    render() {
        let from = this.props.location.state
            ? this.props.location.state.from.pathname
            : "/home";
        if (this.props.authenticated) {
            return <Redirect to={from} />;
        } else {
            return (
                <div style={styles.background}>
                    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                        <div
                            style={{
                                background: "rgba(255,255,255,0.9)",
                                boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.1)",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "10px"
                            }}
                        >
                            <img
                                alt="logoFull"
                                src={require("../assets/nmfulllogo.png")}
                                style={styles.loginLogo}
                            />
                            <StyledFirebaseAuth
                                uiConfig={this.uiConfig()}
                                firebaseAuth={firebaseAuth()}
                            />
                        </div>
                    </Slide>
                </div>
            );
        }
    }
}
