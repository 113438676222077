import React from "react";
import { Button } from "@material-ui/core";
import firestore from "../firebase";
import { Redirect } from "react-router-dom";
import { Consumer } from "../Context";

export default class ReportOptions extends React.Component {
    state = {
        redirect: false
    };
    render() {
        return (
            <Consumer>
                {context => {
                    return this.state.redirect ? (
                        <Redirect to="/reports" />
                    ) : (
                        <div
                            style={{
                                position: "fixed",
                                bottom: 10,
                                right: 10,
                                zIndex: 2
                            }}
                        >
                            <Button
                                className="deleteButton"
                                style={{ margin: 10 }}
                                color="secondary"
                                variant="contained"
                                onClick={e =>
                                    window.confirm("Are you sure?")
                                        ? firestore
                                              .collection("campaign-reports")
                                              .doc(this.props.reportId)
                                              .delete()
                                              .then(res => {
                                                  context.setState({
                                                      dialog: {
                                                          title:
                                                              "Report Deleted",
                                                          msg:
                                                              "The report has been deleted."
                                                      }
                                                  });
                                                  this.setState({
                                                      redirect: true
                                                  });
                                              })
                                              .catch(err => {
                                                  context.setState({
                                                      dialog: {
                                                          title:
                                                              "Error Occured",
                                                          msg:
                                                              "An Error occured please try again."
                                                      }
                                                  });
                                              })
                                        : null
                                }
                            >
                                DELETE
                            </Button>
                            {/* <Button
                                    style={{ margin: 10 }}
                                    variant="outlined"
                                    color="primary"
                                    variant="contained"
                                    onClick={e => {
                                        this.props.setPreview({ preview: null });
                                    }}
                                >
                                    EDIT
                                </Button> */}
                        </div>
                    );
                }}
            </Consumer>
        );
    }
}
