import React from "react";
import {
    Legend,
    Cell,
    PieChart,
    Pie,
    ResponsiveContainer,
    Tooltip
} from "recharts";

let colors = {
    like: "#3598DB",
    love: "#E74C3C",
    anger: "#C0392B",
    sorry: "#26AE60",
    haha: "#dc7633",
    wow: "#8E44AD"
};

let faces = {
    like: "👍",
    love: "😍",
    anger: "😡",
    sorry: "😢",
    haha: "😂",
    wow: "😮"
};

let words = {
    like: "like",
    love: "love",
    anger: "anger",
    sorry: "sad",
    haha: "haha",
    wow: "wow"
};

const renderLegend = props => {
    const { payload } = props;

    return (
        <div className="reactions">
            <ul className="reactionsList">
                {payload.map((entry, index) => (
                    <div
                        key={`key-${index}`}
                        className="reactionsList__container"
                    >
                        <li
                            style={{ fontSize: "2rem" }}
                            className="reactionsList__face"
                            key={`face-${index}`}
                        >
                            {faces[entry.value]}
                        </li>
                        <li
                            style={{
                                color: colors[entry.value],
                                textTransform: "capitalize",
                                fontSize: "1.4rem",
                                fontWeight: "600"
                            }}
                            className="reactionsList__name"
                            key={`name-${index}`}
                        >
                            {words[entry.value]}
                        </li>
                        <li
                            className="reactionsList__percent"
                            key={`percent-${index}`}
                        >
                            {Math.round(entry.payload.percent * 100)}%
                        </li>
                    </div>
                ))}
            </ul>
        </div>
    );
};

export const Reactions = props => {
    let data = props.data;
    let totalReactions = 0;
    let formatedData = Object.keys(data).reduce((acc, val) => {
        acc.push({ name: val, value: data[val] });
        totalReactions += data[val];
        return acc;
    }, []);
    let formattedReactionsData = formatedData.map(x => {
        return {
            value: parseInt((x.value / totalReactions) * 100),
            name: x.name
        };
    });
    return (
        <ResponsiveContainer width={400} height={400}>
            <PieChart>
                <Pie
                    isAnimationActive={false}
                    labelLine={false}
                    dataKey="value"
                    data={formatedData}
                >
                    {formattedReactionsData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[entry.name]} />
                    ))}
                </Pie>
                <Legend content={renderLegend} />
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
};
