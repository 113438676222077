import React from "react";

import { Legend, Cell, ResponsiveContainer, Pie, PieChart } from "recharts";

const colors = {
    female: "lightpink",
    male: "#2196F3"
};

const ageColors = {
    "18-24": "#80D8FF",
    "25-34": "#40C4FF",
    "35-44": "#00B0FF",
    "45-54": "#0091EA",
    "55-64": "#0277BD",
    "65+": "#01579B"
};

const renderLegendAge = props => {
    const { payload } = props;

    return (
        <div className="age-chart">
            <ul className="age-chartList">
                {payload.map((entry, index) => (
                    <div
                        key={`key-${index}`}
                        className="age-chartList__container"
                    >
                        <li
                            style={{ fontSize: "2rem" }}
                            className="age-chartList__face"
                            key={`face-${index}`}
                        />
                        <li
                            style={{
                                color: ageColors[entry.value],
                                textTransform: "capitalize",
                                fontSize: "1.4rem",
                                fontWeight: "600"
                            }}
                            className="age-chartList__name"
                            key={`name-${index}`}
                        >
                            {entry.value}
                        </li>
                        <li
                            className="age-chartList__percent"
                            key={`percent-${index}`}
                        >
                            {Math.round(entry.payload.percent * 100)}%
                        </li>
                    </div>
                ))}
            </ul>
        </div>
    );
};

const renderLegendGender = props => {
    const { payload } = props;

    return (
        <div className="gender-chart">
            <ul className="gender-chartList">
                {payload.map((entry, index) => (
                    <div
                        key={`key-${index}`}
                        className="gender-chartList__container"
                    >
                        <li
                            style={{
                                color: colors[entry.value],
                                textTransform: "capitalize",
                                fontSize: "1.4rem",
                                fontWeight: "600"
                            }}
                            className="gender-chartList__name"
                            key={`name-${index}`}
                        >
                            {entry.value}
                        </li>
                        <li
                            className="gender-chartList__percent"
                            key={`percent-${index}`}
                        >
                            {Math.round(entry.payload.percent * 100)}%
                        </li>
                    </div>
                ))}
            </ul>
        </div>
    );
};

const CustomizedLabel = props => {
    const { x, y, fill, value } = props;
    return (
        <text x={x} y={y} fontSize="14" fill={fill} textAnchor="middle">
            {Math.round(value)}%
        </text>
    );
};

export class EditorialInsights extends React.Component {
    render() {
        let ref = this.props.report.content[this.props.index].genderData;
        let gendertotal = 0;
        let genderdata = Object.keys(ref).reduce((acc, key) => {
            acc.push({ name: key, value: ref[key] });
            gendertotal += parseInt(ref[key]);
            return acc;
        }, []);
        let formattedGenderData = genderdata.map(x => {
            return {
                value: parseInt((x.value / gendertotal) * 100),
                name: x.name
            };
        });
        return (
            <ResponsiveContainer width={300} height={400}>
                <PieChart>
                    <Pie
                        isAnimationActive={false}
                        // innerRadius={40}
                        labelLine={false}
                        dataKey="value"
                        data={formattedGenderData}
                    >
                        {formattedGenderData.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={colors[entry.name]}
                            />
                        ))}
                    </Pie>
                    <Legend content={renderLegendGender} />
                </PieChart>
            </ResponsiveContainer>
        );
    }
}

export const AgeChart = props => {
    let agetotal = 0;
    let ref = props.report.content[props.index].ageData;
    let agedata = Object.keys(ref).reduce((acc, key) => {
        acc.push({ name: key, value: parseInt(ref[key]) });
        agetotal += parseInt(ref[key]);
        return acc;
    }, []);
    let formattedAgeData = agedata.map(x => {
        return {
            value: parseInt((x.value / agetotal) * 100),
            name: x.name
        };
    });
    return (
        <ResponsiveContainer width={300} height={400}>
            <PieChart>
                <Pie
                    isAnimationActive={false}
                    labelLine={false}
                    dataKey="value"
                    data={formattedAgeData}
                >
                    {formattedAgeData.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={ageColors[entry.name]}
                        />
                    ))}
                </Pie>
                <Legend content={renderLegendAge} />
            </PieChart>
        </ResponsiveContainer>
    );
};
