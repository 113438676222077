import React from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Avatar,
    Input,
    MenuItem,
    Menu,
    CircularProgress,
    Badge
} from "@material-ui/core";
import firestore, { firebase, firebaseAuth } from "../../../../firebase";
import moment from "moment";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import {
    Person,
    Add,
    RefreshRounded,
    Edit,
    Check,
    CancelRounded,
    Print
} from "@material-ui/icons";
import { Eye } from "mdi-material-ui";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

export default class HeaderComponent extends React.Component {
    constructor() {
        super();
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.updateReport = this.updateReport.bind(this);
        this.state = {
            open: false,
            changeEnabled: false,
            admin: false,
            from: "",
            to: "",
            dense: false,
            email: "",
            emailForm: "",
            anchorEl: null,
            permissionSet: "viewer"
        };
    }

    componentDidMount() {
        let user = firebaseAuth().currentUser;
        let email = user.email;
        firestore
            .collection("userbase")
            .doc(user.uid)
            .get()
            .then(doc => {
                let data = doc.data();
                if (
                    data.roles.includes("admin") ||
                    data.roles.includes("prod")
                ) {
                    this.setState({
                        admin: true
                    });
                } else {
                    this.setState({
                        email,
                        name: user.displayName
                    });
                }
            });
    }

    removeOwner = (email, type) => {
        this.state.admin ||
        this.props.report.form.owners.includes(this.state.email)
            ? type === "owner"
                ? firestore
                      .collection("campaign-reports")
                      .doc(window.location.href.split("reports/")[1])
                      .update({
                          "form.owners": firebase.firestore.FieldValue.arrayRemove(
                              email
                          )
                      })
                      .then(res => {
                          this.props.refreshData();
                      })
                : firestore
                      .collection("campaign-reports")
                      .doc(window.location.href.split("reports/")[1])
                      .update({
                          "form.viewers": firebase.firestore.FieldValue.arrayRemove(
                              email
                          )
                      })
                      .then(res => {
                          this.props.refreshData();
                      })
            : console.log("No Permission");
    };

    handleDeleteRequest = email => {
        let ownerRequests = [];
        let id = window.location.href.split("reports/")[1];
        let ref = firestore.collection("campaign-reports").doc(id);
        ref.get().then(doc => {
            let data = doc.data();
            data.ownerRequests.forEach(x => {
                if (!x.email === email) {
                    ownerRequests.push(x);
                }
            });
        });
        // console.log(ownerRequests)
        ref.update({
            ownerRequests: ownerRequests
        }).then(e => this.props.refreshData());
    };

    handleApprove = email => {
        this.addOwner(email);

        let id = window.location.href.split("reports/")[1];
        let ownerRequests = [];
        let ref = firestore.collection("campaign-reports").doc(id);
        ref.get().then(doc => {
            let data = doc.data();
            data.ownerRequests.forEach(x => {
                if (!x.email === email) {
                    ownerRequests.push(x);
                }
            });
        });
        // console.log(ownerRequests)
        ref.update({
            ownerRequests: ownerRequests
        });
    };

    addOwner = email => {
        const msg = {
            to: email,
            from: "NarcityMedia@narcitymedia.com",
            subject: `                
            Someone has invited you to a Narcity Media Report`,
            html: `<h2>Narcity Media</h2><br>                
            Someone has invited you to view a report at ${window.location.href}`
        };
        const msgAdmin = {
            to: email,
            from: "NarcityMedia@narcitymedia.com",
            subject: `                
            Someone has granted you access to a Narcity Media Report`,
            html: `<h2>Narcity Media</h2><br>                
            Someone has granted you access to view a report at ${
                window.location.href
            }`
        };

        this.state.admin ||
        this.props.report.form.owners.includes(this.state.email)
            ? this.state.permissionSet === "viewer"
                ? firestore
                      .collection("campaign-reports")
                      .doc(window.location.href.split("reports/")[1])
                      .update({
                          "form.viewers": firebase.firestore.FieldValue.arrayUnion(
                              email
                          )
                      })
                      .then(res => {
                          this.props.refreshData();
                          fetch(`/sendmail`, {
                              credentials: "include",
                              method: "POST",
                              body: JSON.stringify({
                                  msg: msgAdmin
                              }),
                              headers: {
                                  "Content-Type":
                                      "application/json; charset=utf-8"
                              }
                          });
                      })
                : firestore //set to formowner
                      .collection("campaign-reports")
                      .doc(window.location.href.split("reports/")[1])
                      .update({
                          "form.owners": firebase.firestore.FieldValue.arrayUnion(
                              email
                          )
                      })
                      .then(res => {
                          this.props.refreshData();
                          fetch(`/sendmail`, {
                              credentials: "include",
                              method: "POST",
                              body: JSON.stringify({
                                  msg: msgAdmin
                              }),
                              headers: {
                                  "Content-Type":
                                      "application/json; charset=utf-8"
                              }
                          });
                      })
            : fetch("/sendmail", {
                  credentials: "include",
                  method: "POST",
                  body: JSON.stringify({ msg }),
                  headers: {
                      "Content-Type": "application/json; charset=utf-8"
                  }
              }).then(res => alert("Successfully invited."));
    };

    updateReport = () => {
        this.setState({ loading: true });
        let that = this;
        let temp = this.props.report.form;
        if (!!this.state.from && !!this.state.to) {
            temp["subDates"] = {
                startDate: moment(this.state.from).format("YYYY-MM-DD"),
                endDate: moment(this.state.to).format("YYYY-MM-DD")
            };
        }

        fetch("/createProdReport", {
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                ...temp,
                metaData: {
                    lastRefreshData: new Date()
                }
            }),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        })
            .then(response => {
                return response.json();
            })
            .then(res => {
                let id = window.location.href.split("reports/")[1];
                let ref = firestore.collection("campaign-reports").doc(id);
                ref.update(res).then(function() {
                    that.props.refreshData();
                    console.log("Document successfully updated!");
                    that.setState({
                        loading: false,
                        changeEnabled: false,
                        from: "",
                        to: ""
                    });
                });
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
    };

    handleDayClick(day) {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState({
            from: range.from,
            to: range.to
        });
    }

    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.addOwner(this.state.emailForm);
            this.setState({ emailForm: "" });
        }
    }

    printMode() {
        let classPrint = document.querySelectorAll(".reports-container__print");
        classPrint.forEach(el => (el.style.display = "unset"));

        setTimeout(function() {
            window.print();
        }, 100);
        setTimeout(function() {
            classPrint.forEach(el => (el.style.display = "none"));
        }, 200);
    }

    handleEmailChange(event) {
        this.setState({
            emailForm: event.target.value
        });
    }

    handleClickPerms = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleClosePerms = permission => {
        permission
            ? this.setState({ anchorEl: null, permissionSet: permission })
            : this.setState({ anchorEl: null });
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        let startDate = this.props.report.form.date.startDate.replace(/-/g, "");
        let endDate = this.props.report.form.date.endDate.replace(/-/g, "");
        let startDateDayPicker = new Date(
            this.props.report.form.date.startDate
        );
        let endDateDayPicker = new Date(this.props.report.form.date.endDate);

        var OneDay = new Date().getTime() + 1 * 24 * 60 * 60 * 1000;

        const { from, to, loading } = this.state;
        const modifiers = { start: from, end: to };
        const { fullScreen } = this.props;
        return (
            <div className="reports-container">
                {loading && (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "fixed",
                            top: 0,
                            left: 0,
                            background: "rgba(255,255,255,0.7)",
                            zIndex: 3
                        }}
                    >
                        <CircularProgress
                            style={{
                                position: "absolute",
                                left: "50%",
                                top: "50%"
                            }}
                        />
                    </div>
                )}
                <div className="reports">
                    <div className="reports__components reports__components--header">
                        <div className="report-header">
                            <div className="report-header__container">
                                <div className="report-header__left">
                                    <div className="report-header__generated">
                                        Report generated on{" "}
                                        {this.props.report.form.createdDate.substr(
                                            0,
                                            10
                                        )}
                                    </div>
                                    <div className="report-header__sponsored-editorial">
                                        Campaign Report
                                    </div>
                                    {this.props.report.form.clientName ? (
                                        <div className="report-header__client">
                                            {this.props.report.form.clientName}
                                        </div>
                                    ) : null}

                                    <div className="report-header__report-heading">
                                        {this.props.report.form.projectName}
                                    </div>
                                    <div className="report-header__report-id">
                                        Report ID:{" "}
                                        {
                                            window.location.href.split(
                                                "reports/"
                                            )[1]
                                        }
                                    </div>
                                    <div className="report-header__report-id">
                                        {this.props.report.form.projectManager
                                            ? `Acc Manager: ${
                                                  this.props.report.form
                                                      .projectManager
                                              }`
                                            : null}
                                    </div>
                                </div>
                                <div className="report-header__right">
                                    <div className="report-header__buttons">
                                        <div className="refresh-button__icon">
                                            <IconButton
                                                aria-label="Refresh"
                                                onClick={e =>
                                                    this.updateReport()
                                                }
                                                disabled={
                                                    this.props.report.form
                                                        .viewers &&
                                                    this.props.report.form.viewers.includes(
                                                        this.state.email
                                                    )
                                                        ? true
                                                        : this.props.report.form
                                                              .metaData
                                                        ? !!(
                                                              OneDay <
                                                              moment(
                                                                  this.props.report.form.metaData.lastRefreshData
                                                                      .substr(
                                                                          0,
                                                                          10
                                                                      )
                                                                      .replace(
                                                                          /-/g,
                                                                          ""
                                                                      )
                                                              ).format("x")
                                                          )
                                                            ? false
                                                            : true
                                                        : false
                                                }
                                            >
                                                <RefreshRounded
                                                    style={{
                                                        width: 40,
                                                        height: 40
                                                    }}
                                                />
                                            </IconButton>
                                        </div>

                                        <Button
                                            color="primary"
                                            variant="contained"
                                            id="button-share"
                                            disabled={
                                                this.state.admin ||
                                                this.props.report.form.owners.includes(
                                                    this.state.email
                                                )
                                                    ? false
                                                    : true
                                            }
                                            onClick={this.handleClickOpen}
                                        >
                                            {this.props.report.ownerRequests &&
                                            this.props.report.ownerRequests
                                                .length > 0 ? (
                                                <Badge
                                                    className="share-notifications"
                                                    color="secondary"
                                                    children=""
                                                    badgeContent={
                                                        this.props.report
                                                            .ownerRequests
                                                            .length
                                                    }
                                                />
                                            ) : null}
                                            Report Sharing
                                        </Button>
                                        <div className="print-button__icon">
                                            <IconButton
                                                aria-label="Print"
                                                onClick={e => this.printMode()}
                                            >
                                                <Print />
                                            </IconButton>
                                        </div>
                                        {this.props.report.form.date.endDate.replace(
                                            /-/g,
                                            ""
                                        ) <=
                                            this.props.report.form.date.startDate.replace(
                                                /-/g,
                                                ""
                                            )}

                                        <Dialog
                                            style={{ overflow: "hidden" }}
                                            id="dialog-share"
                                            fullScreen={fullScreen}
                                            open={this.state.open}
                                            onClose={this.handleClose}
                                            aria-labelledby="responsive-dialog-title"
                                        >
                                            <DialogTitle id="responsive-dialog-title">
                                                {"Share This Report"}
                                            </DialogTitle>
                                            <div
                                                style={{
                                                    padding:
                                                        "0 1rem .8rem 1rem",
                                                    marginBottom: "1rem",
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-around",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <IconButton
                                                    aria-label="Add"
                                                    onClick={e =>
                                                        this.addOwner(
                                                            this.state.emailForm
                                                        )
                                                    }
                                                >
                                                    <Add />
                                                </IconButton>
                                                <Input
                                                    required
                                                    autoFocus
                                                    type="email"
                                                    onKeyPress={this.enterPressed.bind(
                                                        this
                                                    )}
                                                    value={this.state.emailForm}
                                                    onChange={
                                                        this.handleEmailChange
                                                    }
                                                    style={{ width: "70%" }}
                                                    placeholder={
                                                        this.state.admin ||
                                                        this.props.report.form.owners.includes(
                                                            this.state.email
                                                        )
                                                            ? "email to add"
                                                            : "email to invite"
                                                    }
                                                    inputProps={{
                                                        "aria-label":
                                                            "Description"
                                                    }}
                                                />

                                                <IconButton
                                                    onClick={
                                                        this.handleClickPerms
                                                    }
                                                    disabled={
                                                        this.state.admin ||
                                                        this.props.report.form.owners.includes(
                                                            this.state.email
                                                        )
                                                            ? false
                                                            : true
                                                    }
                                                    aria-owns={
                                                        this.state.anchorEl
                                                            ? "simple-menu"
                                                            : undefined
                                                    }
                                                    aria-haspopup="true"
                                                >
                                                    {this.state
                                                        .permissionSet ===
                                                    "viewer" ? (
                                                        <Eye color="primary" />
                                                    ) : (
                                                        <Edit color="secondary" />
                                                    )}
                                                </IconButton>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={
                                                        this.state.anchorEl
                                                    }
                                                    open={Boolean(
                                                        this.state.anchorEl
                                                    )}
                                                    onClose={e =>
                                                        this.handleClosePerms()
                                                    }
                                                >
                                                    <MenuItem
                                                        onClick={e => {
                                                            this.handleClosePerms(
                                                                "viewer"
                                                            );
                                                        }}
                                                    >
                                                        Viewer
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={e => {
                                                            this.handleClosePerms(
                                                                "admin"
                                                            );
                                                        }}
                                                    >
                                                        Admin
                                                    </MenuItem>
                                                </Menu>
                                            </div>
                                            {this.props.report.form.owners &&
                                            this.props.report.form.owners
                                                .length > 0 ? (
                                                <div className="owner-request-section">
                                                    <div className="owner-request-section__title">
                                                        Report Administrators
                                                    </div>
                                                    <List
                                                        style={{
                                                            minWidth: "27rem"
                                                        }}
                                                        dense={this.state.dense}
                                                    >
                                                        {this.props.report.form.owners.map(
                                                            (val, index) => {
                                                                return (
                                                                    <ListItem
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <ListItemAvatar>
                                                                            <Avatar>
                                                                                <Edit />
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            style={{
                                                                                marginRight:
                                                                                    "1rem",
                                                                                overflow:
                                                                                    "hidden",
                                                                                maxWidth:
                                                                                    "16rem"
                                                                            }}
                                                                            primary={
                                                                                val
                                                                            }
                                                                        />
                                                                        <ListItemSecondaryAction
                                                                            style={{
                                                                                marginLeft:
                                                                                    "0.5rem"
                                                                            }}
                                                                        >
                                                                            <IconButton
                                                                                onClick={e =>
                                                                                    this.removeOwner(
                                                                                        val,
                                                                                        "owner"
                                                                                    )
                                                                                }
                                                                                disabled={
                                                                                    this
                                                                                        .state
                                                                                        .admin
                                                                                        ? false
                                                                                        : this.props.report.form.owners.includes(
                                                                                              this
                                                                                                  .state
                                                                                                  .email
                                                                                          )
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                aria-label="Delete"
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                );
                                                            }
                                                        )}
                                                    </List>
                                                </div>
                                            ) : null}
                                            {this.props.report.form.viewers &&
                                            this.props.report.form.viewers
                                                .length > 0 ? (
                                                <div className="owner-request-section">
                                                    <div className="owner-request-section__title">
                                                        Report Viewers
                                                    </div>
                                                    <List
                                                        style={{
                                                            minWidth: "27rem"
                                                        }}
                                                        dense={this.state.dense}
                                                    >
                                                        {this.props.report.form.viewers.map(
                                                            (val, index) => {
                                                                return (
                                                                    <ListItem
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <ListItemAvatar>
                                                                            <Avatar>
                                                                                <Person />
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            style={{
                                                                                marginRight:
                                                                                    "1rem",
                                                                                overflow:
                                                                                    "hidden",
                                                                                maxWidth:
                                                                                    "16rem"
                                                                            }}
                                                                            primary={
                                                                                val
                                                                            }
                                                                        />
                                                                        <ListItemSecondaryAction
                                                                            style={{
                                                                                marginLeft:
                                                                                    "0.5rem"
                                                                            }}
                                                                        >
                                                                            <IconButton
                                                                                onClick={e =>
                                                                                    this.removeOwner(
                                                                                        val,
                                                                                        "viewer"
                                                                                    )
                                                                                }
                                                                                disabled={
                                                                                    this
                                                                                        .state
                                                                                        .admin
                                                                                        ? false
                                                                                        : this.props.report.form.owners.includes(
                                                                                              this
                                                                                                  .state
                                                                                                  .email
                                                                                          )
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                aria-label="Delete"
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                );
                                                            }
                                                        )}
                                                    </List>
                                                </div>
                                            ) : null}
                                            {this.props.report.ownerRequests &&
                                                this.props.report.ownerRequests
                                                    .length > 0 && (
                                                    <div className="owner-request-section">
                                                        <div className="owner-request-section__title">
                                                            Access Requests
                                                        </div>
                                                        <List
                                                            dense={
                                                                this.state.dense
                                                            }
                                                        >
                                                            {this.props.report.ownerRequests.map(
                                                                (
                                                                    val,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <ListItem
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <ListItemAvatar>
                                                                                <Avatar>
                                                                                    <Person />
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                style={{
                                                                                    marginRight:
                                                                                        "1rem",
                                                                                    overflow:
                                                                                        "hidden",
                                                                                    maxWidth:
                                                                                        "13rem"
                                                                                }}
                                                                                primary={
                                                                                    val.email
                                                                                }
                                                                            />
                                                                            <ListItemSecondaryAction
                                                                                style={{
                                                                                    marginLeft:
                                                                                        "0.5rem"
                                                                                }}
                                                                            >
                                                                                <IconButton
                                                                                    disabled={
                                                                                        this
                                                                                            .state
                                                                                            .admin ||
                                                                                        this.props.report.form.owners.includes(
                                                                                            this
                                                                                                .state
                                                                                                .email
                                                                                        )
                                                                                            ? false
                                                                                            : true
                                                                                    }
                                                                                    style={{
                                                                                        width: 40,
                                                                                        height: 40,
                                                                                        padding: 0
                                                                                    }}
                                                                                    aria-label="Check"
                                                                                    onClick={e =>
                                                                                        this.handleApprove(
                                                                                            val.email
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Check
                                                                                        style={{
                                                                                            color:
                                                                                                "#3DA132"
                                                                                        }}
                                                                                    />
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    disabled={
                                                                                        this
                                                                                            .state
                                                                                            .admin ||
                                                                                        this.props.report.form.owners.includes(
                                                                                            this
                                                                                                .state
                                                                                                .email
                                                                                        )
                                                                                            ? false
                                                                                            : true
                                                                                    }
                                                                                    style={{
                                                                                        width: 40,
                                                                                        height: 40,
                                                                                        padding: 0
                                                                                    }}
                                                                                    aria-label="Add"
                                                                                    onClick={e =>
                                                                                        this.handleDeleteRequest(
                                                                                            val.email
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <CancelRounded color="secondary" />
                                                                                </IconButton>
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    );
                                                                }
                                                            )}
                                                        </List>
                                                    </div>
                                                )}
                                        </Dialog>
                                        {/* </Tooltip> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-dates">
                    <div className="reports__components">
                        <div className="report-header">
                            <div className="report-header__container">
                                <div
                                    className="report-dates__date"
                                    style={{ width: "100%" }}
                                >
                                    {this.state.changeEnabled ? (
                                        <>
                                            <DayPicker
                                                month={startDateDayPicker}
                                                className="Selectable"
                                                numberOfMonths={2}
                                                disabledDays={
                                                    this.state.admin
                                                        ? []
                                                        : [
                                                              {
                                                                  before: startDateDayPicker,
                                                                  after: endDateDayPicker
                                                              }
                                                          ]
                                                }
                                                selectedDays={[
                                                    from,
                                                    { from, to }
                                                ]}
                                                modifiers={modifiers}
                                                onDayClick={this.handleDayClick}
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                        className="report-dates__date"
                                    >
                                        {!this.state.changeEnabled ? (
                                            this.props.report.form.subDates ? (
                                                <div>
                                                    {`${moment(
                                                        this.props.report.form.subDates.startDate.replace(
                                                            /-/g,
                                                            ""
                                                        )
                                                    ).format(
                                                        "MMMM DD"
                                                    )} - ${moment(
                                                        this.props.report.form.subDates.endDate.replace(
                                                            /-/g,
                                                            ""
                                                        )
                                                    ).format("MMMM DD, YYYY")}`}
                                                </div>
                                            ) : (
                                                <div>
                                                    {`${moment(
                                                        startDate
                                                    ).format(
                                                        "MMMM DD"
                                                    )} - ${moment(
                                                        endDate
                                                    ).format("MMMM DD, YYYY")}`}
                                                </div>
                                            )
                                        ) : null}
                                        {!this.state.changeEnabled &&
                                            (this.props.report.form.owners.includes(
                                                this.state.email
                                            ) ||
                                                this.state.admin) && (
                                                <p
                                                    onClick={e =>
                                                        this.setState({
                                                            changeEnabled: !this
                                                                .state
                                                                .changeEnabled
                                                        })
                                                    }
                                                    style={{
                                                        paddingLeft: 5,
                                                        fontWeight: "500",
                                                        fontSize: "1.2rem",
                                                        marginLeft: "1rem"
                                                    }}
                                                >
                                                    <span className="report-dates__changedate">
                                                        Change Dates
                                                    </span>
                                                </p>
                                            )}
                                    </div>
                                    {this.state.changeEnabled && (
                                        <>
                                            <Button
                                                color={"primary"}
                                                onClick={this.updateReport}
                                            >
                                                Apply Changes
                                            </Button>
                                            <Button
                                                color={"secondary"}
                                                onClick={e => {
                                                    this.setState({
                                                        changeEnabled: false
                                                    });
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
