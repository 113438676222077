import React from "react";
import DFPComponent from "./components/DFP/DFPComponent";

export default class ReportDFP extends React.Component {
  render() {
    return (
      <div>
        <DFPComponent report={this.props.report} index={this.props.index} />
        <div className="component-seperator" />
      </div>
    );
  }
}
