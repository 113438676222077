import React from "react";
import {
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar
} from "recharts";

const prepareData = data => {
    let sorted = data.sort((a, b) => {
        var nameA = a.author.split(" ")[0].toLowerCase(),
            nameB = b.author.split(" ")[0].toLowerCase();
        if (nameA < nameB)
            //sort string ascending
            return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
    });
    let prepared = sorted.reduce((acc, val) => {
        acc = [
            ...acc,
            {
                ...val,
                shortName: `${val.author.split(" ")[0]} ${val.author
                    .split(" ")[1]
                    .charAt(0)}`
            }
        ];
        return acc;
    }, []);

    return prepared;
};

const TeamGraph = props => (
    <ResponsiveContainer width="99%" height="100%">
        <BarChart
            data={prepareData(props.data.memberStats)}
            margin={{ right: 10, left: 10, bottom: 40 }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                interval={0}
                angle={-35}
                textAnchor="end"
                name="Author"
                dataKey="shortName"
                allowDataOverflow={true}
                tick={{ fontSize: "sans-serif!important" }}
            />
            <YAxis yAxisId="left" orientation="left" stroke="#82ca9d" />
            <Tooltip />
            <Bar
                isAnimationActive={false}
                name="Unique Pageviews"
                yAxisId="left"
                dataKey="uniquePageviews"
                fill="#82ca9d"
            />
        </BarChart>
    </ResponsiveContainer>
);

export default TeamGraph;
