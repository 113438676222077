import React from "react";
import {
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip
} from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import Zoom from "@material-ui/core/Zoom";

let styles = {
    tableCell: {
        padding: 0,
        textAlign: "left",
        fontSize: "1.2rem",
        border: "none"
    }
};

export default class PongLinks extends React.Component {
    state = {
        cursor: 0,
        open: false
    };

    handleTooltipClose = () => {
        this.setState({ open: false });
    };

    handleTooltipOpen = () => {
        this.setState({ open: true });
    };

    render() {
        let data = Object.keys(
            this.props.report.content[this.props.index].articleMeta.pongLinks
                .breakDown
        )
            .map(key => {
                return {
                    ...this.props.report.content[this.props.index].articleMeta
                        .pongLinks.breakDown[key],
                    version: key
                };
            })
            .map(x => {
                let link = x.destination;
                return ["campaign", "medium", "source", "content"].reduce(
                    (acc, y) => {
                        acc[y] = link.split(`utm_${y}=`)[1]
                            ? link
                                  .split(`utm_${y}=`)[1]
                                  .split("&")[0]
                                  .replace(/\+/g, " ")
                            : "(not set)";
                        return acc;
                    },
                    { ...x }
                );
            })
            .sort((a, b) => b.clicks - a.clicks);

        return true ? (
            <div style={{ margin: "0 20px" }}>
                <div className="reports__heading">Clicks Out Overview</div>
                <Table padding="dense">
                    <TableHead>
                        <TableRow
                            style={{ backgroundColor: "#F9F9F9", height: 30 }}
                        >
                            <TableCell style={styles.tableCell} />

                            <TableCell style={styles.tableCell}>
                                Version
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                                Src/Medium
                            </TableCell>
                            <TableCell
                                style={{
                                    ...styles.tableCell,
                                    textAlign: "center"
                                }}
                            >
                                Clicks
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((n, i) => {
                            return (
                                <TableRow
                                    style={{
                                        height: 5,
                                        backgroundColor:
                                            i % 2
                                                ? "rgba(253, 255, 218, 0.43)"
                                                : "white"
                                    }}
                                    key={"ponglinks-" + i}
                                >
                                    <TableCell
                                        padding="dense"
                                        style={{
                                            ...styles.tableCell,
                                            textAlign: "right",
                                            paddingRight: 5
                                        }}
                                    >
                                        <a
                                            className="tooltip-right"
                                            data-tooltip={n.destination}
                                            href={n.destination}
                                            target="_blank"
                                        >
                                            <Launch />
                                        </a>
                                    </TableCell>
                                    <TableCell
                                        padding="dense"
                                        style={styles.tableCell}
                                    >
                                        {n.version}
                                    </TableCell>
                                    <TableCell
                                        padding="dense"
                                        style={styles.tableCell}
                                    >
                                        {n.source + "/" + n.medium}
                                    </TableCell>
                                    <TableCell
                                        padding="dense"
                                        style={{
                                            ...styles.tableCell,
                                            textAlign: "center"
                                        }}
                                    >
                                        {n.clicks}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        ) : (
            <div>
                <div
                    style={{
                        height: this.state.checked ? 300 : "100%",
                        overflow: "hidden"
                    }}
                    className="reports__content reports__content--pong"
                >
                    <div className="reports__breakdown reports__breakdown--full reports__breakdown--pong">
                        <div className="details__title-center">
                            URL Tracking
                        </div>
                        <ul className="breakdown__list">
                            <li className="breakdown__item">
                                <div className="breakdown__item--title">
                                    URL
                                </div>
                                <div className="breakdown__item--title">
                                    Content
                                </div>
                                <div className="breakdown__item--title">
                                    Clicks
                                </div>
                            </li>
                            {this.props.report.content[this.props.index]
                                .articleMeta.pongLinks
                                ? Object.values(
                                      this.props.report.content[
                                          this.props.index
                                      ].articleMeta.pongLinks.breakDown
                                  ).map((val, i) => {
                                      return (
                                          <li
                                              key={i}
                                              className="breakdown__item breakdown__item--pong breakdown__item--only-child"
                                          >
                                              <div className="breakdown__item--url">
                                                  {
                                                      <a
                                                          className="breakdown__item--url"
                                                          href={val.destination}
                                                          target="_blank"
                                                      >
                                                          <Launch />
                                                      </a>
                                                  }
                                              </div>
                                              <div className="breakdown__item--keyword">
                                                  {val.destination.split(
                                                      "utm_content="
                                                  ).length > 1
                                                      ? val.destination
                                                            .split(
                                                                "utm_content="
                                                            )[1]
                                                            .split("&")[0]
                                                            .substr(0, 22)
                                                      : val.destination.substr(
                                                            12,
                                                            12
                                                        )}
                                                  ...
                                              </div>
                                              <div className="breakdown__item--value">
                                                  {val.clicks}
                                              </div>
                                          </li>
                                      );
                                  })
                                : null}
                        </ul>
                    </div>
                </div>
                {Object.values(
                    this.props.report.content[this.props.index].articleMeta
                        .pongLinks.breakDown
                ).length >= 5 ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            on
                            onClick={e => {
                                this.setState({
                                    checked: !this.state.checked
                                });
                            }}
                        >
                            {this.state.checked ? "Collapse" : "Shrink"}
                        </Button>
                    </div>
                ) : null}
            </div>
        );
    }
}
