import React from "react";
import {
    PieChart,
    Pie,
    Legend,
    Tooltip,
    ResponsiveContainer,
    Cell,
    Label
} from "recharts";
import randomColors from "../../assets/randomColors";

const LeaderboardPie = props => (
    <ResponsiveContainer width="99%" height="100%">
        <PieChart margin={{ bottom: 10 }}>
            <Pie
                name="Unique Pageviews"
                dataKey={"value"}
                isAnimationActive={false}
                data={props.data}
                outerRadius={"70%"}
                fill="#8884d8"
                label
            >
                {props.data.map((entry, index) => (
                    <Cell
                        key={`cell-${index}`}
                        fill={randomColors.colors[index]}
                    />
                ))}
            </Pie>
            <Tooltip
                formatter={(value, name, props) => `${value} UP/Article`}
            />
            <Legend margin={{ top: 10 }} iconSize={9} iconType="circle" />
        </PieChart>
    </ResponsiveContainer>
);

export default LeaderboardPie;
