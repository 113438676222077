import styled from "styled-components";

const Container = styled.div`
    background-color: var(--grey-light-1);
    max-width: 100vw;
`;

const Wrapper = styled.div`
    padding: 3rem;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: 30px;
    max-width: 120rem;
    margin: 0 auto;
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;

    h1 {
        font-weight: 500;
        color: var(--grey-dark-1);
        font-family: "Roboto";
        font-size: 2.5rem;
    }

    button {
        border: none;
        background: var(--grey-light-2);
        padding: 0.5rem;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
            transform: translateY(-1px);
            box-shadow: var(--shadow-light);
        }

        &:focus {
            outline: none;
        }
    }

    .left {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-gap: 5px;

        .buttons {
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 10px;
            justify-content: flex-start;
        }
    }

    .right {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        align-items: center;

        button {
            &:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
    }
`;

const Leaderboard = styled.ul`
    list-style: none;
`;

const LeaderboardHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 80px 80px 80px;
    justify-items: center;
    padding: 0.7rem 0;

    .percent {
        white-space: nowrap;
    }
`;

const LeaderboardItem = styled.li`
    background-color: white;
    border: 1px solid var(--grey-light-3);
    color: var(--grey-dark-1);
    border-radius: 7px;
    transition: all 0.2s;

    .teamLeaderboardItem {
        display: grid;
        grid-template-columns: 1fr 80px 80px 80px;
        cursor: pointer;
    }

    &:not(:first-of-type) {
        margin-top: 1.4rem;
    }

    &:hover {
        transform: translateY(-2px);
        box-shadow: var(--shadow-light);
    }

    .rank {
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid var(--grey-light-3);
        font-size: 1.4rem;
    }

    .team {
        @media (max-width: 600px) {
            h1 {
                font-size: 1.2rem;
                white-space: nowrap;
            }
            margin-left: 1rem;
        }
        height: 8rem;
        /* for img */
        margin-left: 2rem;
        display: grid;
        /* grid-template-columns: auto 1fr; */

        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin: 1.5rem;
            object-fit: cover;
        }

        .chart {
            padding: 2rem;
        }

        &__details {
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                color: var(--grey-dark-3);
            }
        }
    }

    .articles {
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid var(--grey-light-3);
        border-left: 1px solid var(--grey-light-3);
        font-size: 1.4rem;
    }

    .pageviews {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        border-left: 1px solid var(--grey-light-3);
    }

    .percent {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        border-left: 1px solid var(--grey-light-3);
    }
`;

const LeaderboardItemDetails = styled.div`
    display: grid;
    grid-column: 1 / -1;
    border-top: 1px solid var(--grey-light-3);
    grid-template-rows: auto 1fr;

    .topSection {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        font-size: 1.7rem;
        border-bottom: 1px solid var(--grey-light-3);

        .section {
            &:not(:last-child) {
                border-right: 1px solid var(--grey-light-3);
            }
            padding: 1.3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: "Roboto Condensed";

            p {
                @media (max-width: 600px) {
                    font-size: 0.8rem;
                }
                font-size: 1.3rem;
                color: var(--grey-dark-2);
            }
        }
    }

    .bottomSection {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0.8rem;

        @media (max-width: 450px) {
            overflow-x: auto;
        }

        .graphs {
            padding: 2rem;
        }

        .pie {
            border-right: 1px solid var(--grey-light-3);
        }
    }
`;

const ViewButton = styled.button`
    @media (min-width: 500px) {
        display: none;
    }
    border: none;
    /* background-color: #bad; */
    border-radius: 3px;
    display: block;
    width: 80%;
    height: 22px;
`;

const ArticlePerButton = styled.button`
    padding: 1rem;
    box-shadow: none;
    background-color: #fefefe;
    border-radius: 3px;
    border: 1px solid var(--grey-light-3);
    cursor: pointer;
    transition: all 0.2s;
    border-bottom: ${props => (props.selected ? "2px solid purple" : "unset")};

    &:hover {
        border-bottom: 2px solid purple;
    }

    &:active {
        transform: translateY(1px);
    }

    &:focus {
        outline: none;
    }
`;

const ArticlePerSection = styled.div`
    display: flex;
    flex-direction: column;

    .buttons {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-gap: 10px;
        margin: 1rem 0;
    }
`;

export {
    Container,
    Wrapper,
    Header,
    Leaderboard,
    LeaderboardHeader,
    LeaderboardItem,
    LeaderboardItemDetails,
    ViewButton,
    ArticlePerSection,
    ArticlePerButton
};
