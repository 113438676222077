import React from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer
} from "recharts";

export class TrafficTimeline extends React.Component {
    render() {
        let data = !!this.props.report.content[this.props.index].generalData
            ? this.props.report.content[
                  this.props.index
              ].generalData.rows.reduce((acc, val) => {
                  let temp = val;
                  val["ga:pageviews"]
                      ? (temp["ga:pageviews"] = parseInt(val["ga:pageviews"]))
                      : (temp["ga:pageviews"] = parseInt(
                            val["ga:uniquePageviews"]
                        ));
                  temp["name"] = [
                      val.name.slice(0, 4),
                      " ",
                      val.name.slice(4, 6),
                      " ",
                      val.name.slice(6, 8)
                  ].join("");

                  acc.push(temp);
                  return acc;
              }, [])
            : void 0;

        return !!this.props.report.content[this.props.index].generalData ? (
            <ResponsiveContainer width={"100%"} height={250}>
                <AreaChart data={data}>
                    <defs>
                        <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="5%"
                                stopColor="#3F51B5"
                                stopOpacity={0.4}
                            />
                            <stop
                                offset="95%"
                                stopColor="#3F51B5"
                                stopOpacity={0.4}
                            />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <YAxis hide />
                    <Area
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="ga:uniquePageviews"
                        fillOpacity={1}
                        fill="url(#colorUv)"
                        stroke="#3F51B5"
                        // label={<CustomizedLabel />}
                    />
                    {/* <Legend
                        align="center"
                        verticalAlign="bottom"
                        iconType="circle"
                        layout="horizontal"
                    /> */}
                </AreaChart>
            </ResponsiveContainer>
        ) : (
            <div className="no-traffic-data">
                There is no traffic data to be displayed for this timeframe.
            </div>
        );
    }
}
