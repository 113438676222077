import React from "react";
import firestore, { firebase } from "../firebase";
import {
    Paper,
    Typography,
    Button,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell
} from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom";

let styles = {
    container: {
        display: "flex",
        overflow: "scroll",
        maxHeight: "600px",
        margin: "0 1.5rem"
    },
    title: {
        marginLeft: "2.7rem",
        fontFamily: "Oswald",
        fontSize: 24,
        textAlign: "left",
        color: "#3f19b5"
    }
};

export default class RequestPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: []
        };
        this.handleApprove = this.handleApprove.bind(this);
        this.handleDeleteRequest = this.handleDeleteRequest.bind(this);
    }

    componentWillMount() {
        this.getData();
    }
    handleApprove(id, email, request) {
        let ref = firestore.collection("campaign-reports").doc(id);
        ref.update({
            "form.viewers": firebase.firestore.FieldValue.arrayUnion(email)
        }).then(e => {
            const msg = {
                to: email,
                from: "NarcityMedia@narcitymedia.com",
                subject: "You have been granted access",
                text: `Dear ${
                    request.name
                }, your request has been approved at https://dashboard.narcitymedia.com/reports/${
                    request.id
                }`
            };
            fetch("/sendmail", {
                credentials: "include",
                method: "POST",
                body: JSON.stringify({ msg }),
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                }
            }).then(res => {
                this.handleDeleteRequest(id, email);
            });
        });
    }
    handleDeleteRequest(id, email) {
        let ownerRequests = [];
        let ref = firestore.collection("campaign-reports").doc(id);
        ref.get().then(doc => {
            let data = doc.data();

            data.ownerRequests.forEach(x => {
                if (!x.email === email) {
                    ownerRequests.push(x);
                }
            });
        });
        // console.log(ownerRequests)
        ref.update({
            ownerRequests: ownerRequests
        });
        this.getData();
    }
    getData() {
        firestore
            .collection("campaign-reports")
            .get()
            .then(querySnapshot => {
                let acc = [];
                querySnapshot.forEach(doc => {
                    let ownerRequests = doc.data().ownerRequests || [];
                    ownerRequests.forEach(x => {
                        acc.push(x);
                    });
                });
                this.setState({ requests: acc });
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <div>
                {this.state.requests.length > 0 && (
                    <div>
                        <h2 style={styles.title}>ACCESS REQUESTS</h2>
                        <Paper style={styles.container}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            style={{ fontSize: "1.5rem" }}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell
                                            style={{ fontSize: "1.5rem" }}
                                        >
                                            Company
                                        </TableCell>
                                        <TableCell
                                            style={{ fontSize: "1.5rem" }}
                                        >
                                            Email
                                        </TableCell>
                                        <TableCell
                                            style={{ fontSize: "1.5rem" }}
                                        >
                                            Phone
                                        </TableCell>
                                        <TableCell
                                            style={{ fontSize: "1.5rem" }}
                                        >
                                            Date
                                        </TableCell>
                                        <TableCell
                                            style={{ fontSize: "1.5rem" }}
                                        >
                                            Report
                                        </TableCell>
                                        <TableCell
                                            style={{ fontSize: "1.5rem" }}
                                        />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.requests.map(request => {
                                        let {
                                            date,
                                            name,
                                            email,
                                            company,
                                            phone,
                                            id
                                        } = request;
                                        return (
                                            <TableRow key={id + "-" + email}>
                                                <TableCell
                                                    style={{
                                                        fontSize: "1.2rem"
                                                    }}
                                                >
                                                    {name}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontSize: "1.2rem"
                                                    }}
                                                >
                                                    {company}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontSize: "1.2rem"
                                                    }}
                                                >
                                                    {email}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontSize: "1.2rem"
                                                    }}
                                                >
                                                    {phone}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontSize: "1.2rem"
                                                    }}
                                                >
                                                    {moment(
                                                        date.seconds
                                                    ).format("MMM-DD")}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        <Typography
                                                            component={Link}
                                                            to={
                                                                "/reports/" + id
                                                            }
                                                        >
                                                            {id}
                                                        </Typography>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <div
                                                        style={{
                                                            display: "flex"
                                                        }}
                                                    >
                                                        <Button
                                                            style={{
                                                                margin: ".4rem"
                                                            }}
                                                            variant="contained"
                                                            onClick={e =>
                                                                this.handleApprove(
                                                                    id,
                                                                    email,
                                                                    request
                                                                )
                                                            }
                                                            color="primary"
                                                        >
                                                            Approve
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                margin: ".4rem",
                                                                backgroundColor:
                                                                    "#f83727",
                                                                color: "#fff"
                                                            }}
                                                            variant="contained"
                                                            onClick={e =>
                                                                this.handleDeleteRequest(
                                                                    id,
                                                                    email
                                                                )
                                                            }
                                                        >
                                                            Decline
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </div>
                )}
            </div>
        );
    }
}
