import React from "react";
import {
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    Button
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import firestore, { firebase } from "../firebase";

let styles = {
    container: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        overflow: "scroll"
    },
    tiles: {
        margin: 10
    }
};

export default class UserManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allUsers: {},
            filteredItems: false,
            open: false,
            selectedUser: null,
            selectedRole: "client",
            selectedApp: "RI"
        };
    }

    changeField = (uid, field, operation, value) => {
        //example to add admin: "this.changeField(y, "roles", "add", "admin");"
        firestore
            .collection("userbase")
            .doc(uid)
            .update({
                [field]:
                    operation === "remove"
                        ? firebase.firestore.FieldValue.arrayRemove(value)
                        : firebase.firestore.FieldValue.arrayUnion(value)
            })
            .then(e => {
                this.getData();
            });
    };
    getData = () => {
        let accumulator = {};
        firestore
            .collection("userbase")
            .get()
            .then(qs => {
                qs.forEach(doc => {
                    accumulator[doc.id] = doc.data();
                });
                this.setState({
                    allUsers: accumulator
                });
            });
    };
    componentWillMount() {
        this.getData();
    }
    handleClickOpen = user => {
        this.setState({ open: true, selectedUser: user });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    filterList = event => {
        var updatedList = Object.keys(this.state.allUsers).map(key => {
            let data = this.state.allUsers[key];
            data.key = key;
            return data;
        });
        updatedList = updatedList
            .filter(function(item) {
                if (
                    item.displayName
                        .toLowerCase()
                        .search(event.target.value.toLowerCase()) !== -1 ||
                    item.email
                        .toLowerCase()
                        .search(event.target.value.toLowerCase()) !== -1 ||
                    JSON.stringify(item.roles)
                        .toLowerCase()
                        .search(event.target.value.toLowerCase()) !== -1 ||
                    JSON.stringify(item.appAccess)
                        .toLowerCase()
                        .search(event.target.value.toLowerCase()) !== -1
                ) {
                    return item;
                }
            })
            .reduce((acc, val) => {
                acc[val.key] = val;
                return acc;
            }, {});
        this.setState({ filteredItems: updatedList });
    };

    render() {
        let { allUsers } = this.state;
        const { fullScreen } = this.props;
        const roleOptions = ["admin", "prod", "client"];
        const appAccess = ["CI", "RI", "TI", "PI"];

        return (
            <div style={styles.container}>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {this.state.selectedUser
                            ? this.state.selectedUser.displayName
                            : "User"}
                    </DialogTitle>
                    <DialogContent>
                        <div className="admin-dialog-container">
                            <div className="admin-dialog-text">
                                <div className="admin-dialog-actions">
                                    <div className="admin-dialog-actions__header">
                                        Role Modification
                                    </div>
                                    <div className="admin-dialog-actions__container">
                                        <div className="admin-dialog-actions__select">
                                            <select
                                                value={this.state.selectedRole}
                                                onChange={this.handleChange(
                                                    "selectedRole"
                                                )}
                                                inputprops={{
                                                    name: "role",
                                                    id: "role-native-simple"
                                                }}
                                            >
                                                <option value={"admin"}>
                                                    Admin
                                                </option>
                                                <option value={"prod"}>
                                                    Prod
                                                </option>
                                                <option value={"client"}>
                                                    Client
                                                </option>
                                            </select>
                                        </div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={e =>
                                                this.changeField(
                                                    this.state.selectedUser
                                                        .firebaseUID,
                                                    "roles",
                                                    "add",
                                                    this.state.selectedRole
                                                )
                                            }
                                        >
                                            Add
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={e =>
                                                this.changeField(
                                                    this.state.selectedUser
                                                        .firebaseUID,
                                                    "roles",
                                                    "remove",
                                                    this.state.selectedRole
                                                )
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                </div>
                                <div className="admin-dialog-actions">
                                    <div className="admin-dialog-actions__header">
                                        App Access
                                    </div>
                                    <div className="admin-dialog-actions__container">
                                        <div className="admin-dialog-actions__select">
                                            <select
                                                value={this.state.selectedApp}
                                                onChange={this.handleChange(
                                                    "selectedApp"
                                                )}
                                                inputprops={{
                                                    name: "app",
                                                    id: "app-native-simple"
                                                }}
                                            >
                                                <option value={"RI"}>
                                                    Revenue
                                                </option>
                                                <option value={"TI"}>
                                                    Traffic
                                                </option>
                                                <option value={"PI"}>
                                                    Publisher
                                                </option>
                                                <option value={"CI"}>
                                                    Campaign
                                                </option>
                                                <option value={"AP"}>
                                                    Author
                                                </option>
                                            </select>
                                        </div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={e =>
                                                this.changeField(
                                                    this.state.selectedUser
                                                        .firebaseUID,
                                                    "appAccess",
                                                    "add",
                                                    this.state.selectedApp
                                                )
                                            }
                                        >
                                            Add
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={e =>
                                                this.changeField(
                                                    this.state.selectedUser
                                                        .firebaseUID,
                                                    "appAccess",
                                                    "remove",
                                                    this.state.selectedApp
                                                )
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <div className="user-management-header">
                    <div className="user-management-title">User Management</div>
                    <div className="user-management-filter">
                        <input
                            placeholder="filter"
                            type="text"
                            className="user-management-filter__search"
                            onChange={this.filterList}
                        />
                    </div>
                </div>
                <div className="user-management">
                    <table className="user-table sortable searchable">
                        <thead>
                            <tr className="table-head">
                                <th className="table-head__heading user-table--avatar">
                                    &nbsp;
                                </th>

                                <th className="table-head__heading rev-table-head__heading--name">
                                    Full Name
                                </th>
                                <th className="table-head__heading rev-table-head__heading--email">
                                    Email
                                </th>
                                <th className="table-head__heading">Roles</th>
                                <th className="table-head__heading">Apps</th>

                                <th className="table-head__heading">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.filteredItems
                                ? Object.keys(this.state.filteredItems).map(
                                      y => {
                                          let x = allUsers[y];
                                          return (
                                              <tr
                                                  key={y}
                                                  className={`table-row table-item`}
                                              >
                                                  <td className="table-item table-item__avatar user-table--avatar">
                                                      {x.photoURL ? (
                                                          <Avatar
                                                              src={x.photoURL}
                                                          />
                                                      ) : (
                                                          <Avatar>
                                                              {x.displayName
                                                                  .split(" ")
                                                                  .map(
                                                                      x => x[0]
                                                                  )
                                                                  .join("")
                                                                  .toUpperCase()}
                                                          </Avatar>
                                                      )}
                                                  </td>
                                                  <td className="table-item">
                                                      {x.displayName}
                                                  </td>
                                                  <td className="table-item">
                                                      {x.email}
                                                  </td>

                                                  <td className="table-item table-item__role">
                                                      {x.roles
                                                          ? x.roles ===
                                                                "no-set" ||
                                                            "" ||
                                                            (x.roles.length ===
                                                                1 &&
                                                                x.roles[0] ===
                                                                    "") ||
                                                            x.roles.length === 0
                                                              ? "no-set"
                                                              : x.roles.map(
                                                                    (
                                                                        res,
                                                                        i
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    i
                                                                                }
                                                                                className={`user-role user-role--${res}`}
                                                                            >
                                                                                {
                                                                                    res
                                                                                }
                                                                            </div>
                                                                        );
                                                                    }
                                                                )
                                                          : "no-set"}
                                                  </td>
                                                  <td className="table-item table-item__app">
                                                      {x.appAccess &&
                                                      (x.appAccess ===
                                                          "no-set" ||
                                                          x.appAccess.length ===
                                                              0)
                                                          ? "none"
                                                          : x.appAccess.map(
                                                                (res, i) => {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                i
                                                                            }
                                                                            className={`user-apps user-apps--${res}`}
                                                                        >
                                                                            {
                                                                                res
                                                                            }
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                  </td>

                                                  <td className="table-item__data table-item__buttons">
                                                      <Edit
                                                          onClick={e =>
                                                              this.handleClickOpen(
                                                                  {
                                                                      ...x,
                                                                      firebaseUID: y
                                                                  }
                                                              )
                                                          }
                                                          className="table-button table-button__edit"
                                                      />
                                                  </td>
                                              </tr>
                                          );
                                      }
                                  )
                                : Object.keys(allUsers).map(y => {
                                      let x = allUsers[y];
                                      return (
                                          <tr
                                              key={y}
                                              className={`table-row table-item`}
                                          >
                                              <td className="table-item table-item__avatar">
                                                  {x.photoURL ? (
                                                      <Avatar
                                                          src={x.photoURL}
                                                      />
                                                  ) : (
                                                      <Avatar>
                                                          {x.displayName
                                                              .split(" ")
                                                              .map(x => x[0])
                                                              .join("")
                                                              .toUpperCase()}
                                                      </Avatar>
                                                  )}
                                              </td>
                                              <td className="table-item">
                                                  {x.displayName}
                                              </td>
                                              <td className="table-item">
                                                  {x.email}
                                              </td>

                                              <td className="table-item table-item__role">
                                                  {x.roles
                                                      ? x.roles === "no-set" ||
                                                        "" ||
                                                        (x.roles.length === 1 &&
                                                            x.roles[0] ===
                                                                "") ||
                                                        x.roles.length === 0
                                                          ? "no-set"
                                                          : x.roles
                                                                .sort()
                                                                .map(
                                                                    (
                                                                        res,
                                                                        i
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    i
                                                                                }
                                                                                className={`user-role user-role--${res}`}
                                                                            >
                                                                                {
                                                                                    res
                                                                                }
                                                                            </div>
                                                                        );
                                                                    }
                                                                )
                                                      : "no-set"}
                                              </td>
                                              <td className="table-item table-item__app">
                                                  {x.appAccess &&
                                                  (x.appAccess === "no-set" ||
                                                      x.appAccess.length === 0)
                                                      ? "none"
                                                      : x.appAccess
                                                            .sort()
                                                            .map((res, i) => {
                                                                return (
                                                                    <div
                                                                        key={i}
                                                                        className={`user-apps user-apps--${res}`}
                                                                    >
                                                                        {res}
                                                                    </div>
                                                                );
                                                            })}
                                              </td>

                                              <td className="table-item__data table-item__buttons">
                                                  <Edit
                                                      onClick={e =>
                                                          this.handleClickOpen({
                                                              ...x,
                                                              firebaseUID: y
                                                          })
                                                      }
                                                      className="table-button table-button__edit"
                                                  />
                                              </td>
                                          </tr>

                                          // <div key={y} style={styles.tiles}>
                                          //     <Avatar src={x.photoURL} />
                                          //     <h3>{x.displayName}</h3>
                                          //     <h4>role: {x.role}</h4>
                                          //     <h4>apps: {x.appAccess}</h4>
                                          // </div>
                                      );
                                  })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
