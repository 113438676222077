import React, { Component } from "react";
import moment from "moment";
import { Progress } from "react-sweet-progress";
import TeamGraphTable from "../components/TeamGraphTable";
import {
    Container,
    Wrapper,
    Header,
    Leaderboard,
    LeaderboardHeader,
    LeaderboardItem,
    LeaderboardItemDetails,
    ViewButton,
    ArticlePerSection,
    ArticlePerButton
} from "../scenes/styles/TeamLeaderboardStyles";
import ArticlePerTable from "./Leaderboard/ArticlePerTable";

const colors = [
    "#e58b8b",
    "#ff9b9b",
    "#ffde00",
    "#aee537",
    "#4ae46d",
    "#00dca3",
    "#00d0d2",
    "#00c1f3",
    "#00adfe",
    "#2196f3"
];

class TeamLeaderboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prepData: {},
            showDetails: null,
            customOption: "",
            selectedOption: "THISWEEK"
        };
    }

    handleItemClick(team) {
        this.setState({
            showDetails: this.state.showDetails === team ? null : team
        });
    }

    componentWillMount() {
        const { data, currentMonth } = this.props;

        const prepData = Object.entries(data).reduce(
            (acc, [teamName, team]) => {
                acc.totalGoal += parseFloat(team.data.goal[currentMonth]);
                acc.totalArticles += parseFloat(team.articles.length);
                acc.totalPageviews += parseFloat(team.totalPageviews);
                acc.percentData.push({
                    percent: (
                        (team.totalPageviews / team.data.goal[currentMonth]) *
                        100
                    ).toFixed(2),
                    name: teamName
                });

                return acc;
            },
            {
                totalGoal: 0,
                totalArticles: 0,
                totalPageviews: 0,
                percentData: []
            }
        );

        this.setState({ prepData });
    }

    render() {
        const { data, currentMonth } = this.props;
        const { selectedOption } = this.state;

        return (
            <Container>
                <Wrapper>
                    <Header>
                        <div className="left">
                            <h1>Team Overview</h1>
                        </div>
                        <div className="right">
                            <button
                                disabled={currentMonth === "January"}
                                style={{
                                    borderBottom:
                                        currentMonth === "January"
                                            ? "1.5px solid purple"
                                            : "none"
                                }}
                                onClick={() => {
                                    this.props.getData("January");
                                }}
                            >
                                January
                            </button>
                            <button
                                disabled={currentMonth === "February"}
                                style={{
                                    borderBottom:
                                        currentMonth === "February"
                                            ? "1.5px solid purple"
                                            : "none"
                                }}
                                onClick={() => {
                                    this.props.getData("February");
                                }}
                            >
                                February
                            </button>
                            <button
                                disabled={currentMonth === "March"}
                                style={{
                                    borderBottom:
                                        currentMonth === "March"
                                            ? "1.5px solid purple"
                                            : "none"
                                }}
                                onClick={() => {
                                    this.props.getData("March");
                                }}
                            >
                                March
                            </button>
                            <button
                                disabled={currentMonth === "April"}
                                style={{
                                    borderBottom:
                                        currentMonth === "April"
                                            ? "1.5px solid purple"
                                            : "none"
                                }}
                                onClick={() => {
                                    this.props.getData("April");
                                }}
                            >
                                April
                            </button>
                            <button
                                disabled={
                                    currentMonth ===
                                    moment()
                                        .subtract(1, "month")
                                        .format("MMMM")
                                }
                                style={{
                                    borderBottom:
                                        currentMonth ===
                                        moment()
                                            .subtract(1, "month")
                                            .format("MMMM")
                                            ? "1.5px solid purple"
                                            : "none"
                                }}
                                onClick={() => {
                                    this.props.getData("lastMonth");
                                }}
                            >
                                May
                            </button>
                            <button
                                style={{
                                    borderBottom:
                                        currentMonth !== moment().format("MMMM")
                                            ? "none"
                                            : "1.5px solid purple"
                                }}
                                disabled={
                                    currentMonth === moment().format("MMMM")
                                }
                                onClick={() => {
                                    this.setState({
                                        customOption: ""
                                    });
                                    this.props.getData();
                                }}
                            >
                                {moment().format("MMMM")}
                            </button>
                        </div>
                    </Header>
                    <Leaderboard>
                        <LeaderboardHeader
                            style={{
                                gridTemplateColumns: this.props.user.roles.includes(
                                    "admin"
                                )
                                    ? "1fr 80px 80px 80px"
                                    : "1fr 80px"
                            }}
                        >
                            <div className="name">Team</div>
                            <div className="percent">UPs</div>
                            {this.props.user.roles.includes("admin") && (
                                <>
                                    <div className="percent">
                                        Aiming to Hit %
                                    </div>
                                    <div className="percent">Progress %</div>
                                </>
                            )}
                        </LeaderboardHeader>
                        {Object.entries(data)
                            .sort(([nameA, a], [nameB, b]) => {
                                return nameA - nameB;
                            })
                            .map(([teamName, team], i) => {
                                let percent =
                                    (team.totalPageviews /
                                        team.data.goal[currentMonth]) *
                                    100;
                                let trendingPercent =
                                    (Math.round(
                                        (team.totalPageviews /
                                            new Date().getDate()) *
                                            moment()
                                                .endOf("month")
                                                .format("DD")
                                    ) /
                                        team.data.goal[currentMonth]) *
                                    100;

                                return (
                                    <LeaderboardItem key={teamName}>
                                        <div
                                            className="teamLeaderboardItem"
                                            style={{
                                                gridTemplateColumns: this.props.user.roles.includes(
                                                    "admin"
                                                )
                                                    ? "1fr 80px 80px 80px"
                                                    : "1fr 80px"
                                            }}
                                            onClick={e =>
                                                this.handleItemClick(teamName)
                                            }
                                        >
                                            <div className="team">
                                                {/* <img
                                            src={
                                                team.data.image ||
                                                `https://avatars.dicebear.com/v2/identicon/${teamName}.svg`
                                            }
                                            alt="placehold"
                                        /> */}
                                                <div className="team__details">
                                                    <h1>{teamName}</h1>
                                                    <p>
                                                        {
                                                            team.data.members
                                                                .length
                                                        }{" "}
                                                        members{" "}
                                                        <ViewButton
                                                            onClick={() =>
                                                                this.props.setTeam(
                                                                    teamName
                                                                )
                                                            }
                                                        >
                                                            view
                                                        </ViewButton>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="pageviews">
                                                {team.totalPageviews.toLocaleString() ||
                                                    0}
                                            </div>
                                            {this.props.user.roles.includes(
                                                "admin"
                                            ) && (
                                                <>
                                                    <div className="percent">
                                                        {currentMonth ===
                                                        moment().format(
                                                            "MMMM"
                                                        ) ? (
                                                            <Progress
                                                                width={60}
                                                                type="circle"
                                                                theme={{
                                                                    active: {
                                                                        symbol:
                                                                            trendingPercent.toFixed() +
                                                                            "%",
                                                                        trailColor:
                                                                            "white",
                                                                        color:
                                                                            colors[
                                                                                Math.round(
                                                                                    trendingPercent /
                                                                                        10
                                                                                ) -
                                                                                    1
                                                                            ]
                                                                    },
                                                                    success: {
                                                                        symbol:
                                                                            trendingPercent.toFixed() +
                                                                            "%",
                                                                        trailColor:
                                                                            "white",
                                                                        color:
                                                                            "green"
                                                                    }
                                                                }}
                                                                status={
                                                                    trendingPercent <
                                                                    100
                                                                        ? "active"
                                                                        : "success"
                                                                }
                                                                percent={trendingPercent.toFixed()}
                                                            />
                                                        ) : (
                                                            <p>N/A</p>
                                                        )}
                                                    </div>
                                                    <div className="percent">
                                                        {currentMonth !==
                                                            "January" &&
                                                        currentMonth !==
                                                            "February" &&
                                                        currentMonth !==
                                                            "March" &&
                                                        currentMonth !==
                                                            "April" ? (
                                                            <Progress
                                                                width={60}
                                                                type="circle"
                                                                theme={{
                                                                    active: {
                                                                        symbol:
                                                                            percent.toFixed() +
                                                                            "%",
                                                                        trailColor:
                                                                            "white",
                                                                        color:
                                                                            colors[
                                                                                Math.round(
                                                                                    percent /
                                                                                        10
                                                                                ) -
                                                                                    1
                                                                            ]
                                                                    },
                                                                    success: {
                                                                        symbol:
                                                                            percent.toFixed() +
                                                                            "%",
                                                                        trailColor:
                                                                            "white",
                                                                        color:
                                                                            "green"
                                                                    }
                                                                }}
                                                                status={
                                                                    percent <
                                                                    100
                                                                        ? "active"
                                                                        : "success"
                                                                }
                                                                percent={percent.toFixed()}
                                                            />
                                                        ) : (
                                                            "N/A"
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {this.state.showDetails ===
                                            teamName && (
                                            <LeaderboardItemDetails>
                                                <div
                                                    className="topSection"
                                                    style={{
                                                        gridTemplateColumns: this.props.user.roles.includes(
                                                            "admin"
                                                        )
                                                            ? "1fr 1fr 1fr"
                                                            : "1fr"
                                                    }}
                                                >
                                                    {this.props.user.roles.includes(
                                                        "admin"
                                                    ) &&
                                                        currentMonth !==
                                                            "February" &&
                                                        currentMonth !==
                                                            "January" && (
                                                            <div className="section">
                                                                {team.data.goal[
                                                                    currentMonth
                                                                ].toLocaleString() ||
                                                                    0}
                                                                <p>Goal</p>
                                                            </div>
                                                        )}
                                                    <div className="section">
                                                        {team.totalPageviews.toLocaleString() ||
                                                            0}
                                                        <p>Unique Pageviews</p>
                                                    </div>
                                                    {this.props.user.roles.includes(
                                                        "admin"
                                                    ) && (
                                                        <div className="section">
                                                            {team.data.goal[
                                                                currentMonth
                                                            ] -
                                                                team.totalPageviews <
                                                            0
                                                                ? 0
                                                                : (
                                                                      team.data
                                                                          .goal[
                                                                          currentMonth
                                                                      ] -
                                                                      team.totalPageviews
                                                                  ).toLocaleString()}
                                                            <p>
                                                                Remaining Unique
                                                                Pageviews
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="bottomSection">
                                                    <TeamGraphTable
                                                        data={team.memberStats}
                                                    />
                                                </div>
                                            </LeaderboardItemDetails>
                                        )}
                                    </LeaderboardItem>
                                );
                            })}
                    </Leaderboard>
                    <ArticlePerSection>
                        <div className="buttons">
                            <ArticlePerButton
                                onClick={() =>
                                    this.setState({
                                        selectedOption: "YESTERDAY"
                                    })
                                }
                                selected={selectedOption === "YESTERDAY"}
                            >
                                TOP YESTERDAY
                            </ArticlePerButton>
                            <ArticlePerButton
                                onClick={() =>
                                    this.setState({
                                        selectedOption: "THISWEEK"
                                    })
                                }
                                selected={selectedOption === "THISWEEK"}
                            >
                                TOP THIS WEEK
                            </ArticlePerButton>
                            <ArticlePerButton
                                onClick={() =>
                                    this.setState({
                                        selectedOption: "LASTWEEK"
                                    })
                                }
                                selected={selectedOption === "LASTWEEK"}
                            >
                                TOP LAST WEEK
                            </ArticlePerButton>
                            <ArticlePerButton
                                onClick={() =>
                                    this.setState({
                                        selectedOption: "THISMONTH"
                                    })
                                }
                                selected={selectedOption === "THISMONTH"}
                            >
                                TOP THIS MONTH
                            </ArticlePerButton>
                        </div>
                        {currentMonth === moment().format("MMMM") && (
                            <ArticlePerTable
                                selectedOption={selectedOption}
                                data={Object.values(
                                    this.props.authorData
                                ).reduce((acc, val) => {
                                    acc = [
                                        ...acc,
                                        ...Object.values(val.articles)
                                    ];
                                    return acc;
                                }, [])}
                            />
                        )}
                    </ArticlePerSection>
                </Wrapper>
            </Container>
        );
    }
}

export default TeamLeaderboard;
