import React from "react";
import { Consumer } from "../Context";
import firestore, { firebase } from "../firebase";
import { Link } from "react-router-dom";
import { Button, FormControl, TextField } from "@material-ui/core";

const styles = {
    page: {
        zIndex: 1,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "scroll",
        backgroundColor: "rgba(0,0,0,0)"
    },
    modal: {
        backgroundColor: "#fefefe",
        margin: "3% auto",
        padding: 20,
        width: "80%",
        textAlign: "left"
    },
    form: {
        display: "flex",
        flexFlow: "column nowrap"
    },
    pendingform: {
        display: "flex",
        flexFlow: "column"
    }
};

export default class ReportRequest extends React.Component {
    state = {
        company: "",
        phone: ""
    };
    submitRequest = (e, context) => {
        let { id, currentOwnerRequests } = this.props.data;
        e.preventDefault();
        let data = { ...this.state, ...this.props.data, date: Date.now() };

        firestore
            .collection("campaign-reports")
            .doc(id)
            .update({
                ownerRequests: currentOwnerRequests
                    ? firebase.firestore.FieldValue.arrayUnion(data)
                    : [data]
            })
            .then(success => {
                const msg = {
                    to: this.props.data.reportAdministrators,
                    bcc: "prod@narcitymedia.com",
                    from: "CampaignInsights@narcitymedia.com",
                    subject: "Campaign Report access request from " + data.name,
                    text: `Dear Report Administrator, 
                    You have a pending request for access to report: https://dashboard.narcitymedia.com/reports/${
                        data.id
                    } from ${data.name} at ${data.company} (${
                        data.email
                    }). Please resolve the report access request under the 'Share' option button or contact your Narcity account administrator.
                    Thank you,
                    Narcity Media
                    `
                };
                fetch(`/sendmail`, {
                    credentials: "include",
                    method: "POST",
                    body: JSON.stringify({ msg: msg, multiple: true }),
                    headers: {
                        "Content-Type": "application/json; charset=utf-8"
                    }
                });
                this.setState({
                    pending: true
                });
            })
            .catch(err => {
                console.log(err);
                context.setState({
                    dialog: {
                        title: "Error",
                        msg:
                            "An error occured, please refresh the page or contact Narcity Media."
                    }
                });
            });
    };
    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        });
    };
    render() {
        return (
            <Consumer>
                {context => {
                    let {
                        name,
                        email,
                        reportNotFound,
                        pending
                    } = this.props.data;
                    return (
                        <div style={styles.page}>
                            <div style={styles.modal}>
                                {reportNotFound ? (
                                    <p>
                                        This report does not exist. Please check
                                        your provided link or contact your
                                        Narcity account manager.
                                    </p>
                                ) : this.state.pending || pending ? (
                                    <div style={styles.pendingform}>
                                        <div
                                            style={{
                                                fontSize: "1.2rem",
                                                marginBottom: "1rem"
                                            }}
                                        >
                                            {name}, your request is currently
                                            under review. Upon being resolved by
                                            the report administrator you will
                                            receive an email at {email}.{" "}
                                        </div>
                                        <Button
                                            style={{ padding: "1rem" }}
                                            variant="contained"
                                            color="primary"
                                            component={Link}
                                            to="/reports"
                                        >
                                            OK
                                        </Button>
                                    </div>
                                ) : (
                                    <div>
                                        <div
                                            style={{
                                                fontSize: "2.5rem",
                                                color: "#3f19b5",
                                                fontFamily: "Oswald",
                                                marginBottom: ".7rem"
                                            }}
                                            className="requestPerm"
                                        >
                                            Authorized Access Required
                                        </div>
                                        <div style={{ fontSize: "1.3rem" }}>
                                            This report requires secure access
                                            and a one time permission request.
                                        </div>
                                        <div style={{ fontSize: "1.1rem" }}>
                                            Please fill and submit the following
                                            form. The report administrator will
                                            be notified to resolve your
                                            authorization request.
                                        </div>
                                        <FormControl style={styles.form}>
                                            <TextField
                                                disabled
                                                id={"name"}
                                                label={"Name"}
                                                defaultValue={name}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                            <TextField
                                                disabled
                                                id={"email"}
                                                label="Email"
                                                defaultValue={email}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                            <TextField
                                                required
                                                id={"company"}
                                                label="Company name"
                                                value={this.state.company}
                                                onChange={this.handleChange(
                                                    "company"
                                                )}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                            <TextField
                                                required
                                                id={"phone"}
                                                label="Phone Number"
                                                onChange={this.handleChange(
                                                    "phone"
                                                )}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                            <Button
                                                disabled={
                                                    this.state.company.length >
                                                        2 &&
                                                    this.state.phone.length > 9
                                                        ? false
                                                        : true
                                                }
                                                type="submit"
                                                onClick={e =>
                                                    this.submitRequest(
                                                        e,
                                                        context
                                                    )
                                                }
                                                variant="contained"
                                                color="primary"
                                            >
                                                Request Access
                                            </Button>
                                        </FormControl>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                }}
            </Consumer>
        );
    }
}
