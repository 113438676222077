import React from "react";
import HeaderComponent from "./components/Header/HeaderComponent";

export default class ReportHeader extends React.Component {
    render() {
        return (
            <div>
                <HeaderComponent
                    user={this.props.user}
                    refreshData={this.props.refreshData}
                    report={this.props.report}
                />
            </div>
        );
    }
}
