import React from "react";
import { Link } from "react-router-dom";
import {
    Collapse,
    Paper,
    Menu,
    MenuItem,
    MenuList,
    Button,
    Avatar,
    ClickAwayListener,
    Typography
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import firestore, { firebase, firebaseAuth } from "../firebase";

import nmLogo from "../assets/nm.png";

let styles = {
    root: {
        position: "absolute",
        zIndex: "99",
        width: "100%"
    },
    navbar: {
        display: "flex",
        flexDirection: "column",
        padding: "0 1rem",
        borderBottom: "0.5 solid grey",
        height: 60,
        backgroundColor: "#3F18B5"
    },
    subNav: {
        height: 50,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "1.8rem",
        color: "rgb(172, 68, 183)",
        fontWeight: 400,
        fontFamily: "Roboto Condensed, sans-serif",
        position: "relative"
    },
    appbar: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    navbutton: {
        margin: "1rem"
    },
    appName: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        fontSize: "2rem",
        color: "white",
        fontWeight: 300,
        fontFamily: "Roboto Condensed, sans-serif"
    },
    ability: {
        fontWeight: 400,
        fontFamily: "Roboto Condensed, sans-serif",
        textDecoration: "none",
        marginRight: 20,
        color: "#4023b0"
    }
};

let portalNames = {
    CI: "Campaign Insights",
    RI: "Revenue Insights",
    PI: "Publisher Insights",
    AD: "Admin",
    TI: "Traffic Insights"
};

let portalUrls = {
    CI: "/reports",
    RI: "/revenueinsights",
    PI: "/publisherinsights",
    AD: "/admin/usermanagement",
    TI: "/trafficinsights"
};

let realUrls = {
    reports: "Campaign Insights",
    revenueinsights: "Revenue Insights",
    trafficinsights: "Traffic Insights",
    publisherinsights: "Publisher Insights",
    admin: "Admin Dashboard",
    home: "Home",
    createreport: "Create Report"
};

let adminAccess = ["CI", "RI", "TI", "PI"];

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            height: "60px",
            open: false,
            anchorElPortal: false,
            hasAccessTo: [],
            admin: false
        };
    }

    handleClickPortal = event => {
        this.setState({ anchorElPortal: !this.state.anchorElPortal });
    };

    handleClosePortal = () => {
        this.setState({ anchorElPortal: false });
    };

    handleClick = () => {
        this.setState({
            open: !this.state.open
        });
    };
    menu = (
        <Paper
            square={true}
            style={{
                background: styles.navbar.backgroundColor,
                color: "white",
                borderRadius: 2
            }}
        >
            <ClickAwayListener
                onClickAway={e => {
                    this.setState({ open: false });
                }}
            >
                <MenuList role="menu">
                    <MenuItem
                        style={{
                            color: "white",
                            fontSize: "1.2rem"
                        }}
                        component={Link}
                        to="/home"
                        onClick={this.handleClick}
                    >
                        Dashboard
                    </MenuItem>
                    <MenuItem
                        style={{
                            color: "white",
                            fontSize: "1.2rem"
                        }}
                        component={Link}
                        to="/logout"
                        onClick={this.handleClick}
                    >
                        Logout
                    </MenuItem>
                </MenuList>
            </ClickAwayListener>
        </Paper>
    );
    navMenu = (
        <Paper
            square={true}
            style={{
                background: styles.navbar.backgroundColor,
                color: "white",
                borderRadius: 2
            }}
        >
            <ClickAwayListener
                onClickAway={e => {
                    this.setState({ anchorElPortal: false });
                }}
            >
                <MenuList role="menu">
                    <MenuItem
                        onClick={this.handleClosePortal}
                        component={Link}
                        style={{ color: "white", fontSize: "1.3rem" }}
                        to="/home"
                    >
                        Home
                    </MenuItem>
                    {!!this.props.user &&
                        this.props.user.appAccess.map((val, i) => {
                            return (
                                <MenuItem
                                    onClick={this.handleClosePortal}
                                    key={i}
                                    style={{
                                        color: "white",
                                        fontSize: "1.3rem"
                                    }}
                                    component={Link}
                                    to={portalUrls[val]}
                                >
                                    {portalNames[val]}
                                </MenuItem>
                            );
                        })}
                    {!!this.props.user &&
                    this.props.user.roles &&
                    this.props.user.roles.includes("admin") ? (
                        <MenuItem
                            onClick={this.handleClosePortal}
                            key={Math.random(1)}
                            style={{
                                color: "white",
                                fontSize: "1.3rem"
                            }}
                            component={Link}
                            to={"/admin/usermanagement"}
                        >
                            Admin
                        </MenuItem>
                    ) : null}
                    )}
                </MenuList>
            </ClickAwayListener>
        </Paper>
    );

    render() {
        const { open, anchorElPortal } = this.state;

        return (
            <div
                style={{
                    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.2)",
                    position: "relative",
                    backgroundColor: "white"
                }}
            >
                <Paper
                    square={true}
                    color={this.state.appBarColor}
                    style={styles.navbar}
                >
                    <div style={styles.appbar}>
                        <Link to="/home">
                            <img
                                alt="logo"
                                src={nmLogo}
                                style={{
                                    width: "60px",
                                    height: "auto",
                                    fill: "white"
                                }}
                            />
                        </Link>
                        {this.props.authenticated && (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                    aria-owns={open ? "menu-list-2" : null}
                                    aria-haspopup="true"
                                    onClick={this.handleClickPortal}
                                    style={styles.appName}
                                >
                                    {this.props.authenticated
                                        ? realUrls[
                                              window.location.href.split("/")[3]
                                          ]
                                        : ""}
                                    <ArrowDropDown style={{ color: "#fff" }} />
                                </div>
                                <Collapse
                                    id="menu-list-2"
                                    timeout={5}
                                    style={{
                                        position: "absolute",
                                        left: 0,
                                        zIndex: 2,
                                        top: 58,
                                        backgroundColor: "#3F18B5"
                                    }}
                                    in={Boolean(anchorElPortal)}
                                    collapsedHeight={"0px"}
                                >
                                    {this.navMenu}
                                </Collapse>
                            </>
                        )}

                        <div style={{ marginLeft: "auto" }}>
                            {!!this.props.user ? (
                                <>
                                    <div
                                        className="header-user-avatar"
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                        aria-owns={open ? "menu-list" : null}
                                        aria-haspopup="true"
                                        onClick={this.handleClick}
                                    >
                                        {this.props.user.photoURL ? (
                                            <Avatar
                                                src={this.props.user.photoURL}
                                            />
                                        ) : (
                                            <Avatar>
                                                {this.props.user.displayName
                                                    ? this.props.user.displayName
                                                          .split(" ")
                                                          .map(x => x[0])
                                                          .join("")
                                                          .toUpperCase()
                                                    : ""}
                                            </Avatar>
                                        )}
                                    </div>
                                    <Collapse
                                        id="menu-list"
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            zIndex: 2
                                        }}
                                        in={this.state.open}
                                        collapsedHeight={"0px"}
                                    >
                                        {this.menu}
                                    </Collapse>
                                </>
                            ) : (
                                <Button
                                    component={Link}
                                    to="/login"
                                    variant="outlined"
                                    color="default"
                                    style={{
                                        color: "white",
                                        border: "0.8px solid white",
                                        fontSize: 12
                                    }}
                                >
                                    Sign in
                                </Button>
                            )}
                        </div>
                    </div>
                </Paper>
                <div className="subNav">
                    {this.props.authenticated &&
                        (window.location.href.indexOf("/report") > -1 ||
                            window.location.href.indexOf("/createreport") >
                                -1) && (
                            <div style={styles.subNav}>
                                <div style={{ margin: "3px 20px" }}>
                                    {this.props.authenticated &&
                                        (this.props.user.roles.includes(
                                            "admin"
                                        ) ||
                                            this.props.user.roles.includes(
                                                "prod"
                                            )) && (
                                            <Link
                                                style={styles.ability}
                                                to="/createreport"
                                            >
                                                Create Report
                                            </Link>
                                        )}
                                    <Link style={styles.ability} to="/reports">
                                        Browse Reports
                                    </Link>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        );
    }
}
