import React from "react";
import { EditorialInsights, AgeChart } from "../../../EditorialInsights";

export default class AgeGenderComponent extends React.Component {
    render() {
        return (
            <div className="reports-container">
                <div className="reports">
                    <div className="reports__components">
                        <div className="reports__header-bar">
                            <h1 className="reports__heading">Demographics</h1>
                            <a
                                href={"permalink"}
                                className="reports__header-url"
                            >
                                &nbsp;
                            </a>
                        </div>
                        <div className="graph-component">
                            <div className="reports__content">
                                <div
                                    className="reports__flex-area reports__flex-area--2graphs"
                                    style={{ backgroundColor: "#62d9ff12" }}
                                >
                                    {this.props.report.content[this.props.index]
                                        .ageData ? (
                                        <div className="reports__details">
                                            <div className="details__title-center">
                                                Age Chart
                                            </div>
                                            <div className="details__graph-box">
                                                <AgeChart
                                                    report={this.props.report}
                                                    index={this.props.index}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    {this.props.report.content[this.props.index]
                                        .genderData ? (
                                        <div className="reports__details">
                                            <div className="details__title-center">
                                                Gender Chart
                                            </div>
                                            <div className="details__graph-box">
                                                <EditorialInsights
                                                    report={this.props.report}
                                                    index={this.props.index}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
