import React from "react";
import AgeGenderComponent from "./components/Content/AgeGenderComponent";
import TrafficComponent from "./components/Content/TrafficComponent";
import MapComponent from "./components/Content/MapComponent";
import Overviewv2 from "./components/Content/Overviewv2";

export default class ReportContent extends React.Component {
    render() {
        return (
            <div>
                <Overviewv2
                    report={this.props.report}
                    index={this.props.index}
                />
                {this.props.report.content[this.props.index].genderData ||
                this.props.report.content[this.props.index].ageData ? (
                    <AgeGenderComponent
                        report={this.props.report}
                        index={this.props.index}
                    />
                ) : null}
                <TrafficComponent
                    report={this.props.report}
                    index={this.props.index}
                />
                <MapComponent
                    report={this.props.report}
                    index={this.props.index}
                />
                <div className="component-seperator" />
            </div>
        );
    }
}
