import React from "react";
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    Brush,
    ReferenceLine,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Cell
} from "recharts";

const convertNanoDollars = nano => {
    return nano / 1000000;
};

const prepareData = data => {
    if (data) {
        let x = Object.values(data).reduce((acc, val) => {
            Object.entries(val.articles).map(([k, v]) => {
                v.revenue = convertNanoDollars(v.revenue);
                v.headlineShort = `${v.headline.substring(0, 25)}...`;
                return (acc = [...acc, v]);
            });
            return acc;
        }, []);
        return x.sort((a, b) => b.uniquePageviews - a.uniquePageviews);
    } else {
        return console.warn("no data passed");
    }
};

const TeamGraphArticle = props => {
    let preparedData = prepareData(props.data);
    return (
        <ResponsiveContainer width="99%" height="100%">
            <BarChart
                barGap="0%"
                barCategoryGap="1%"
                data={preparedData}
                margin={{ right: 20, bottom: 10 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={false} name="Headline" dataKey="headline" />
                <YAxis scale="log" domain={["dataMin", "dataMax"]} />
                <Tooltip dataKey={"headline"} />
                <Legend
                    verticalAlign="top"
                    wrapperStyle={{ lineHeight: "40px" }}
                />
                <ReferenceLine y={0} stroke="#000" />
                <Brush height={30} stroke="#8884d8" />
                <Bar
                    isAnimationActive={false}
                    name="Unique Pageviews"
                    dataKey="uniquePageviews"
                    fill="#8884d8"
                >
                    {preparedData.map((entry, i) => {
                        return (
                            <Cell
                                key={`${i}-cell`}
                                onClick={() =>
                                    console.log(entry) ||
                                    window.open(`https://${entry.pagePath}`)
                                }
                            />
                        );
                    })}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default TeamGraphArticle;
