import styled from "styled-components";

const Button = styled.button`
    margin-left: ${props => (!props.marginLeft ? ".5rem" : "1rem")};
    width: ${props => (props.width ? props.width : "10rem")};
    height: ${props => (props.height ? props.height : "3rem")};
    border-radius: 7px;
    font-size: 1.4rem;
    font-weight: 400;
    box-shadow: var(--shadow-light);
    display: flex;
    justify-content: center;
    border: 1px solid var(--grey-light-4);
    align-items: center;
    cursor: pointer;
    transition: all 0.1s;
    user-select: none;
    .button-text {
        margin-right: 0.5rem;
        color: var(--grey-dark-2);
    }
    &:hover {
        transform: scale(1.01) translateY(-2px);
    }
    &:active {
        transform: scale(1) translateY(1px);
    }
    &:focus {
        outline: none;
    }
`;

const FixedButton = styled.button`
    @media (min-width: 500px) {
        display: ${props => (props.mobile ? "none" : "")};
    }
    position: fixed;
    bottom: ${props => props.bottom || "4vh"};
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    right: 4vw;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: ${props => props.bgColor || "#ff8789"};
    border: none;
    user-select: none;
    color: white;
    box-shadow: var(--shadow-light);
    justify-content: center;
    transition: all 0.2s;

    &:hover {
        transform: scale(1.03) translateY(-2px);
    }

    &:active {
        transform: scale(1) translateY(1px);
    }

    &:focus {
        outline: none;
    }
`;

const CircleButton = styled.button`
    width: ${props => (props.width ? props.width : "4rem")};
    height: ${props => (props.width ? props.width : "4rem")};
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: ${props => (props.bgColor ? props.bgColor : "#ff8789")};
    border: none;
    user-select: none;
    color: ${props => (props.textColor ? props.textColor : "white")};
    box-shadow: var(--shadow-light);
    justify-content: center;
    transition: all 0.2s;

    &:hover {
        transform: scale(1.03) translateY(-2px);
    }

    &:active {
        transform: scale(1) translateY(1px);
    }

    &:focus {
        outline: none;
    }
`;

export default Button;
export { FixedButton, CircleButton };
