import React from "react";
import { TrafficTimeline } from "../../../TrafficTimeline";

export default class TrafficComponent extends React.Component {
    render() {
        return (
            <div className="reports-container">
                <div className="reports">
                    <div className="reports__components reports__components--traffic">
                        <div className="reports__header-bar">
                            <h1 className="details__title-center">
                                Traffic Data
                            </h1>
                            <a
                                href={"permalink"}
                                className="reports__header-url"
                            >
                                &nbsp;
                            </a>
                        </div>
                        <div className="reports__content">
                            <div className="reports__details reports__details--full">
                                <div className="details__graph-box details__graph-box--traffic">
                                    <TrafficTimeline
                                        report={this.props.report}
                                        index={this.props.index}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
