import React from "react";
import PostOverview from "./components/FacebookReal/PostOverview";

export default class ReportContent extends React.Component {
    render() {
        return (
            <div>
                <PostOverview
                    report={this.props.report}
                    index={this.props.index}
                    fullReport={this.props.fullReport}
                />
                <div className="component-seperator" />
            </div>
        );
    }
}
