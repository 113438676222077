import React from "react";
import ReactDOM from "react-dom";
import "./css/style.css";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";
import { BrowserRouter } from "react-router-dom";
import Provider from "./Context";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
unregisterServiceWorker();

const ContextRouter = () => {
    return (
        <Provider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    );
};

ReactDOM.render(<ContextRouter />, document.getElementById("root"));
// registerServiceWorker();
