import styled from "styled-components";

const DialogInside = styled.div`
    width: 40rem;
    display: grid;
    grid-template-rows: 60px 50px auto 33px 50px 1fr 150px;
    padding: 3rem;
    grid-gap: 10px;
    color: var(--purple);
    background-color: var(--grey-light-3);

    .goals {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-gap: 10px;
    }

    .input {
        display: flex;
        flex-direction: column;
    }
`;

const DialogButton = styled.button`
    width: 50%;
    height: 4rem;
    background-color: rgb(1, 156, 42);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: end;
    border-radius: 5px;
    font-size: 1.5rem;
    color: var(--grey-light-3);
    font-weight: 300;
    box-shadow: var(--shadow-light);
    border: none;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s;

    &:focus {
        outline: none;
    }

    &:hover {
        transform: translateY(-2px) scale(1.02);
        box-shadow: var(--shadow-dark);
    }

    &:active {
        transform: translateY(1px) scale(1);
    }
`;

export { DialogInside, DialogButton };
