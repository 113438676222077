import styled from "styled-components";

const StylishLabel = styled.div`
    font-size: 1rem;
    color: var(--grey-dark-2);
    margin-left: 0.5rem;
`;

const StylishInput = styled.input`
    min-width: ${props => props.minWidth || "3rem"};
    width: ${props => (props.width ? props.width : null)};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    height: ${props => (props.height ? props.height : "3rem")};
    outline: none;
    background-color: #fff;
    border: none;
    padding-left: 1rem;
    font-size: 1.4rem;
    border-radius: 4px;
`;

export { StylishInput, StylishLabel };
