import React from "react";
import { Consumer } from "../Context";

import ReportRequest from "./ReportRequest";
import ReportOptions from "./ReportOptions";

import firestore, { firebaseAuth } from "../firebase";
import ReportHeader from "../features/reports/ReportHeader";
import TabContent from "./TabContent";
import TabFbOrganic from "./TabFbOrganic";
import TabFbPaid from "./TabFbPaid";
import TabDFP from "./TabDFP";
import ReportError from "../features/reports/ReportError";
import ReportContent from "../features/reports/ReportContent";
import ReportFacebookReal from "../features/reports/ReportFacebookReal";
import ReportFacebookPaid from "../features/reports/ReportFacebookPaid";
import ReportCustom from "../features/reports/ReportCustom";

export default class Report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            report: false,
            reportRequest: false,
            controls: false
        };
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    componentWillMount() {
        this.getData();
    }
    getData() {
        if (!this.props.report) {
            let id = this.props.match ? this.props.match.params.id : "";
            let { email, displayName, uid } = firebaseAuth().currentUser;
            let admin = false;
            if (
                this.props.user.roles.includes("admin") ||
                this.props.user.roles.includes("prod")
            ) {
                this.setState({ controls: true });
                admin = true;
            }
            let ref = firestore.collection("campaign-reports").doc(id);
            ref.get().then(doc => {
                let document = doc.data();
                if (doc.exists) {
                    (document.form.owners &&
                        document.form.owners.includes(email)) ||
                    (document.form.viewers &&
                        document.form.viewers.includes(email)) ||
                    admin
                        ? this.setState({
                              report: document
                          })
                        : document.ownerRequests &&
                          document.ownerRequests
                              .reduce((acc, val) => {
                                  acc.push(val.email);
                                  return acc;
                              }, [])
                              .includes(email)
                        ? this.setState({
                              reportRequest: {
                                  pending: true,
                                  name: displayName,
                                  email: email
                              }
                          })
                        : this.setState({
                              reportRequest: {
                                  id: id,
                                  name: displayName,
                                  email: email,
                                  uid: uid,
                                  reportAdministrators: document.form.owners,
                                  currentOwnerRequests: document.ownerRequests
                                      ? true
                                      : false
                              }
                          });
                } else {
                    this.setState({
                        reportRequest: {
                            reportNotFound: true
                        }
                    });
                }
            });
        } else {
            this.setState({ report: this.props.report });
        }
    }

    render() {
        return (
            <Consumer>
                {context => {
                    let { report, reportRequest } = this.state;
                    return (
                        <div className="container">
                            {reportRequest && (
                                <ReportRequest data={reportRequest} />
                            )}
                            {report && (
                                <div className="content">
                                    <main className="component-view">
                                        <ReportHeader
                                            user={this.props.user}
                                            refreshData={this.getData}
                                            report={report}
                                        />
                                        {report.content ? (
                                            report.content.hasOwnProperty(
                                                "err"
                                            ) ? (
                                                <ReportError
                                                    title={"Page path"}
                                                    type={"content"}
                                                />
                                            ) : (
                                                <TabContent report={report} />
                                            )
                                        ) : null}
                                        {report.content
                                            ? report.content.length > 1
                                                ? Object.values(
                                                      report.content
                                                  ).map((val, i) => {
                                                      return i > 0 ? (
                                                          <div
                                                              key={i}
                                                              className="reports-container__print"
                                                          >
                                                              <ReportContent
                                                                  report={
                                                                      report
                                                                  }
                                                                  key={i}
                                                                  index={i}
                                                              />
                                                          </div>
                                                      ) : null;
                                                  })
                                                : null
                                            : null}
                                        {report.fb ? (
                                            report.fb.hasOwnProperty("err") ? (
                                                <ReportError
                                                    title={"FB Organic"}
                                                    type={"fborganic"}
                                                />
                                            ) : (
                                                <TabFbOrganic report={report} />
                                            )
                                        ) : null}
                                        {report.fb
                                            ? Object.values(report.fb).length >
                                              1
                                                ? Object.values(report.fb).map(
                                                      (val, i) => {
                                                          return i > 0 ? (
                                                              <div
                                                                  key={i}
                                                                  className="reports-container__print"
                                                              >
                                                                  <ReportFacebookReal
                                                                      fullReport={
                                                                          report
                                                                      }
                                                                      report={
                                                                          val
                                                                      }
                                                                      key={i}
                                                                      index={i}
                                                                  />
                                                              </div>
                                                          ) : null;
                                                      }
                                                  )
                                                : null
                                            : null}
                                        {report.fbads ? (
                                            report.fbads.hasOwnProperty(
                                                "err"
                                            ) ? (
                                                <ReportError
                                                    title={"FB Paid"}
                                                    type={"fbads"}
                                                />
                                            ) : (
                                                <TabFbPaid report={report} />
                                            )
                                        ) : null}
                                        {report.fbads
                                            ? report.fbads.length > 1
                                                ? report.fbads.map((val, i) => {
                                                      return i > 0 ? (
                                                          <div
                                                              key={i}
                                                              className="reports-container__print"
                                                          >
                                                              <ReportFacebookPaid
                                                                  report={Object.values(
                                                                      val
                                                                  )}
                                                                  key={i}
                                                                  index={i}
                                                                  fullReport={
                                                                      report
                                                                  }
                                                              />
                                                          </div>
                                                      ) : null;
                                                  })
                                                : null
                                            : null}
                                        {report.dfp ? (
                                            report.dfp.hasOwnProperty("err") ? (
                                                <ReportError
                                                    title={"DFP"}
                                                    type={"dfp"}
                                                />
                                            ) : (
                                                <TabDFP report={report} />
                                            )
                                        ) : null}
                                        {report.form && report.form.custom
                                            ? Object.values(
                                                  report.form.custom
                                              ).map((val, i) => {
                                                  return (
                                                      <ReportCustom
                                                          key={i}
                                                          report={val}
                                                      />
                                                  );
                                              })
                                            : null}
                                    </main>
                                </div>
                            )}
                            {this.state.controls && (
                                <ReportOptions
                                    reportId={this.props.match.params.id}
                                />
                            )}
                        </div>
                    );
                }}
            </Consumer>
        );
    }
}
