import React from "react";
import { Consumer } from "../Context";

import { DateUtils } from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { CalendarSearch } from "mdi-material-ui";
import TrafficInsightsGraph from "../features/TrafficInsightsGraph";
import Button from "../components/styled/Button";
import moment from "moment";
let dev = false;

export default class TrafficInsights extends React.Component {
    static contextType = Consumer;

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            from: null,
            to: null,
            enteredTo: null,
            getDates: false
        };
    }

    handleDayClick = (day, modifiers) => {
        if (modifiers.disabled) {
            return;
        }
        const { from, to } = this.state;
        if (from && to && day >= from && day <= to) {
            this.handleResetClick();
            return;
        }
        if (this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                from: day,
                to: null,
                enteredTo: null
            });
        } else {
            this.setState({
                to: day,
                enteredTo: day
            });
        }
    };

    handleDayMouseEnter = day => {
        const { from, to } = this.state;
        if (!this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                enteredTo: day
            });
        }
    };

    isSelectingFirstDay = (from, to, day) => {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    };

    formatter = d => {
        //YYYY-MM-DD
        let date = new Date(d);
        return (
            date.getFullYear() +
            `-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
                "0" + date.getDate()
            ).slice(-2)}`
        );
    };

    componentWillMount() {
        this.fetchData(null, null);
    }

    isWithinDay = date => {
        let date1 = moment(date);
        let yesterday = moment(date).subtract(1, "days");
        //Check if same day
        return !date1.isSame(yesterday, "d");
    };

    fetchData = (start, end, dev) => {
        console.warn(start, end);
        this.context.setState({ loading: true });
        fetch(
            start === null || end === null
                ? `/microtraffic`
                : `/microtraffic?start=${start}&end=${end}`
        )
            .then(res => res.json())
            .then(res => {
                // console.log(start, end);
                // console.warn(res);
                let x = res.filter(val => {
                    if (start === null || end === null) {
                        let newStart = moment(new Date())
                            .startOf("day")
                            .toDate();
                        let newEnd = moment(new Date())
                            .add(1, "days")
                            .startOf("day")
                            .toDate();
                        // console.log(newStart);
                        // console.log(newEnd);
                        return moment(new Date(val.name * 1000)).isBetween(
                            newStart,
                            newEnd
                        );
                    } else {
                        let fixedStart = moment(start)
                            .startOf("day")
                            .toDate();
                        let fixedEnd = moment(end)
                            .endOf("day")
                            .toDate();
                        // console.log(fixedStart);
                        // console.log(fixedEnd);
                        // console.log(val.name * 1000);
                        // console.log(
                        //     moment(new Date(val.name * 1000)).isBetween(
                        //         fixedStart,
                        //         fixedEnd
                        //     )
                        // );
                        return moment(new Date(val.name * 1000)).isBetween(
                            fixedStart,
                            fixedEnd
                        );
                    }
                });
                this.shapeData(res);
                this.context.setState({ loading: false });
            })
            .catch(err => {
                console.log(err);
            });
    };

    handleResetClick = () => {
        this.setState({ from: null, to: null, enteredTo: null });
    };

    shapeData = res => {
        let shapedData = res.map(x => {
            return {
                ...x,
                prettyDate: moment(new Date(parseInt(x.name) * 1000)).format(
                    "DD, hh:mm a"
                )
            };
        });
        console.log(shapedData);
        this.setState({ data: shapedData });
        // console.log(res);
        // let obj = {};

        // for (const item of res) {
        //     const time = item.name;
        //     for (const [key, value] of Object.entries(item)) {
        //         if (key !== "name") {
        //             if (!obj[key]) obj[key] = {};
        //             obj[key] = { name: time, pageviews: value };
        //         }
        //     }
        //     console.log("doing");
        // }
        // console.log("called");
        // console.log(obj);
        // this.setShaped(obj);
    };

    render() {
        let dayPickerInputRef = null;
        const { from, to, enteredTo } = this.state;
        const modifiers = { start: from, end: enteredTo };
        const disabledDays = {
            before: new Date("2018-12-25"),
            after: new Date(new Date().setDate(new Date().getDate()))
        };
        const selectedDays = [from, { from, to: enteredTo }];
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%"
                }}
            >
                <div
                    className="revenue-insights-calendar"
                    style={{ margin: "3rem 0 1rem 2rem" }}
                >
                    <div className="revenue-insights-datePicker">
                        {
                            <DayPickerInput
                                ref={ref => (dayPickerInputRef = ref)}
                                hideOnDayClick={
                                    this.state.to === null ? false : true
                                }
                                keepFocus={false}
                                dayPickerProps={{
                                    selectedDays: selectedDays,
                                    numberOfMonths: 1,
                                    className: "Range",
                                    disabledDays: disabledDays,
                                    onDayClick: this.handleDayClick,
                                    onDayMouseEnter: this.handleDayMouseEnter,
                                    modifiers: modifiers,
                                    onBlur: () => {
                                        setTimeout(() => {
                                            const elClicked =
                                                document.activeElement;
                                            const container = document.getElementsByClassName(
                                                `revenue-insights-datePicker`
                                            );
                                            if (
                                                container &&
                                                !container[0].contains(
                                                    elClicked
                                                )
                                            ) {
                                                dayPickerInputRef.hideDayPicker();
                                            }
                                        }, 1);
                                    }
                                }}
                                component={props => (
                                    <>
                                        <input
                                            className="revenue-insights-datePicker__picker"
                                            {...props}
                                            readOnly={true}
                                        />
                                    </>
                                )}
                                format="DD/MM/YYYY"
                                value={
                                    from && to !== null
                                        ? `${from.toLocaleDateString()} - ${to.toLocaleDateString()}`
                                        : from !== null
                                        ? `${from.toLocaleDateString()}`
                                        : moment(new Date()).format("M/D/YYYY")
                                }
                            />
                        }
                    </div>
                    <Button
                        onClick={e => {
                            this.state.from && this.state.to
                                ? this.fetchData(
                                      moment(this.state.from).format(
                                          "YYYY-MM-DD"
                                      ),
                                      !!this.state.to
                                          ? moment(this.state.to).format(
                                                "YYYY-MM-DD"
                                            )
                                          : moment(this.state.from).format(
                                                "YYYY-MM-DD"
                                            )
                                  )
                                : this.state.from
                                ? this.fetchData(
                                      moment(this.state.from).format(
                                          "YYYY-MM-DD"
                                      ),
                                      moment(this.state.from).format(
                                          "YYYY-MM-DD"
                                      )
                                  )
                                : this.fetchData(null, null);
                        }}
                    >
                        <span className="button-text">Go</span>{" "}
                        <CalendarSearch color="primary" />
                    </Button>
                </div>
                <div style={{ width: "100%", height: "73vh" }}>
                    <TrafficInsightsGraph data={this.state.data} />
                </div>
            </div>
        );
    }
}
