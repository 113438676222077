import React from "react";

import DialogPrompt from "./components/Dialog";
import { CircularProgress, Snackbar } from "@material-ui/core/";

const styles = {
    loading: {
        position: "absolute",
        zIndex: 99999,
        top: "50%",
        left: "50%"
    }
};

const GeneralContext = React.createContext();

export default class Provider extends React.Component {
    state = {
        loading: false,
        dialog: null,
        snackbar: null
    };

    render() {
        return (
            <GeneralContext.Provider
                value={{
                    state: this.state,
                    setState: state => {
                        this.setState(state);
                    }
                }}
            >
                {this.props.children}
                {this.state.loading && (
                    <CircularProgress style={styles.loading} />
                )}
                {this.state.dialog && <DialogPrompt />}
                {this.state.snackbar && (
                    <SnackBarWarning
                        setState={this.setState.bind(this)}
                        data={this.state.snackbar}
                    />
                )}
            </GeneralContext.Provider>
        );
    }
}

export const Consumer = GeneralContext.Consumer;

let SnackBarWarning = props => {
    let { data, setState } = props;
    let handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setState({ snackbar: { open: false } });
    };

    let msg = data.msg;
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            open={data.open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={<span>{msg}</span>}
            variant="error"
        />
    );
};
