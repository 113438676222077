import React from "react";
import { Briefcase } from "mdi-material-ui";
import LinearProgress from "@material-ui/core/LinearProgress";

export default class FBPaidOverview extends React.Component {
    render() {
        return (
            <div className="reports-container">
                <div className="details__title-center">
                    Facebook Paid Overview
                </div>
                <div className="reports">
                    <div className="reports__components reports__components--fbpaid">
                        <div className="reports__header-bar reports__header-bar--center">
                            <div className="reports__heading heading-fb-component">
                                <Briefcase className="facebook-icon" />
                                {this.props.report[0].adset_name}
                            </div>
                        </div>
                        {this.props.fullReport.form.goal ? (
                            this.props.fullReport.form.goal[
                                `booked_impressions_${this.props.index}`
                            ] ? (
                                <div className="article-progress">
                                    <div className="article-progress-box">
                                        <div className="article-progress-box__text">
                                            <span className="article-progress-box__booked">
                                                {
                                                    this.props.report[0]
                                                        .impressions
                                                }{" "}
                                                /{" "}
                                                {
                                                    this.props.fullReport.form
                                                        .goal[
                                                        `booked_impressions_${
                                                            this.props.index
                                                        }`
                                                    ]
                                                }
                                            </span>{" "}
                                            Impressions Delivered [
                                            {(
                                                (this.props.report[0]
                                                    .impressions /
                                                    this.props.fullReport.form.goal[
                                                        `booked_impressions_${
                                                            this.props.index
                                                        }`
                                                    ].replace(/\D/g, "")) *
                                                100
                                            ).toFixed()}
                                            %]
                                        </div>
                                        <div className="article-progress-box__bar">
                                            <LinearProgress
                                                color={
                                                    (this.props.report[0]
                                                        .impressions /
                                                        this.props.fullReport.form.goal[
                                                            `booked_impressions_${
                                                                this.props.index
                                                            }`
                                                        ].replace(/\D/g, "")) *
                                                        100 >
                                                    50
                                                        ? "primary"
                                                        : "secondary"
                                                }
                                                variant="determinate"
                                                value={
                                                    (this.props.report[0]
                                                        .impressions /
                                                        this.props.fullReport.form.goal[
                                                            `booked_impressions_${
                                                                this.props.index
                                                            }`
                                                        ].replace(/\D/g, "")) *
                                                    100
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        ) : null}
                        <div className="reports__content">
                            <div className="reports__flex-area reports__flex-area--fbpaid">
                                <div className="reports__details">
                                    <ul className="breakdown__list">
                                        <li className="breakdown__item breakdown__item--facebook">
                                            <div className="breakdown__item--title">
                                                Actions
                                            </div>
                                            <div className="breakdown__item--title">
                                                Amount
                                            </div>
                                        </li>
                                        <li className="breakdown__item breakdown__item--facebook">
                                            <div className="breakdown__item--name tooltip-bottom">
                                                Comments
                                            </div>
                                            <div className="breakdown__item--value">
                                                {this.props.report[0].actions.comment.toLocaleString()}
                                            </div>
                                        </li>
                                        <li className="breakdown__item breakdown__item--facebook">
                                            <div className="breakdown__item--name tooltip-bottom">
                                                Shares
                                            </div>
                                            <div className="breakdown__item--value">
                                                {this.props.report[0].actions.post.toLocaleString()}
                                            </div>
                                        </li>
                                        <li className="breakdown__item breakdown__item--facebook">
                                            <div
                                                className="breakdown__item--name tooltip-bottom"
                                                data-tooltip="The number of unique people who engaged with your post."
                                            >
                                                Post Engagement
                                            </div>
                                            <div className="breakdown__item--value">
                                                {this.props.report[0].actions.post_engagement.toLocaleString()}
                                            </div>
                                        </li>
                                        <li className="breakdown__item breakdown__item--facebook">
                                            <div
                                                className="breakdown__item--name tooltip-bottom"
                                                data-tooltip="The number of people who reacted to your post. (Likes, Love, Wow..)"
                                            >
                                                Post Reactions
                                            </div>
                                            <div className="breakdown__item--value">
                                                {this.props.report[0].actions.post_reaction.toLocaleString()}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="reports__details">
                                    <ul className="breakdown__list">
                                        <li className="breakdown__item breakdown__item--facebook">
                                            <div className="breakdown__item--title">
                                                Statistic
                                            </div>
                                            <div className="breakdown__item--title">
                                                Amount
                                            </div>
                                        </li>
                                        <li className="breakdown__item breakdown__item--facebook">
                                            <div
                                                className="breakdown__item--name tooltip-bottom"
                                                data-tooltip="The percentage of times people saw your ad and performed a link click."
                                            >
                                                CTR
                                            </div>
                                            <div className="breakdown__item--value">
                                                {(
                                                    (Number(
                                                        this.props.report[0]
                                                            .actions.link_click
                                                    ) /
                                                        Number(
                                                            this.props.report[0]
                                                                .impressions
                                                        )) *
                                                    100
                                                ).toFixed(2)}
                                                %
                                            </div>
                                        </li>
                                        <li className="breakdown__item breakdown__item--facebook">
                                            <div
                                                className="breakdown__item--name tooltip-bottom"
                                                data-tooltip="The number of clicks on links within the ad that led to destinations or experiences, on or off Facebook."
                                            >
                                                Link Clicks
                                            </div>
                                            <div className="breakdown__item--value">
                                                {this.props.report[0].actions.link_click.toLocaleString()}
                                            </div>
                                        </li>
                                        <li className="breakdown__item breakdown__item--facebook">
                                            <div
                                                className="breakdown__item--name tooltip-bottom"
                                                data-tooltip="The total number of times your post was seen by people on Facebook."
                                            >
                                                Impressions
                                            </div>
                                            <div className="breakdown__item--value">
                                                {parseInt(
                                                    this.props.report[0]
                                                        .impressions
                                                ).toLocaleString()}
                                            </div>
                                        </li>
                                        <li className="breakdown__item breakdown__item--facebook">
                                            <div
                                                className="breakdown__item--name tooltip-bottom"
                                                data-tooltip="The number of unique people who saw your Facebook Post."
                                            >
                                                Reach
                                            </div>
                                            <div className="breakdown__item--value">
                                                {parseInt(
                                                    this.props.report[0].reach
                                                ).toLocaleString()}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
