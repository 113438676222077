/* global window,document */
import React, { Component } from "react";
import { Map, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";

export default class MapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: 51.505,
            lng: -0.09,
            currentZoom: 0
        };
    }

    componentDidMount() {
        let currentZoom = this.refs.map.leafletElement.getZoom();
        this.setState({ currentZoom });
        let that = this;
        this.refs.map.leafletElement.on("zoomend", function() {
            let currentZoom = this.getZoom();
            that.setState({ currentZoom });
        });
    }

    render() {
        let total = 0;
        let currentCount = 0;
        let sortedPos = !!this.props.markers
            ? Object.values(this.props.markers).length > 1
                ? Object.values(this.props.markers)
                      .map(x => {
                          total += parseInt(x[2]);
                          return x;
                      })
                      .sort((a, b) => {
                          return parseInt(b[2]) - parseInt(a[2]);
                      })
                      .filter((x, i) => {
                          currentCount += parseInt(x[2]);
                          return !!(
                              i < 3 || currentCount < parseInt(total * 0.4)
                          );
                      })
                      .map(x => {
                          return [x[0], x[1]];
                      })
                : [[40.712, -74.227], [40.774, -74.125]]
            : [[40.712, -74.227], [40.774, -74.125]];
        let myBounds = new L.LatLngBounds(sortedPos).pad(0.5);
        let markerPercent = val => {
            return ((val / total) * 100).toFixed();
        };

        return (
            <Map ref="map" bounds={myBounds}>
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png "
                />
                {!!this.props.markers
                    ? Object.values(this.props.markers).map((val, i) => {
                          return (
                              <Marker
                                  zIndexOffset={1}
                                  key={i}
                                  position={val}
                                  zIndexOffset={markerPercent(val[2]) * 100}
                                  icon={
                                      new L.DivIcon({
                                          iconSize: new L.Point(
                                              markerPercent(val[2]) < 1
                                                  ? 5
                                                  : 0.5 *
                                                    markerPercent(
                                                        val[2] *
                                                            (this.state
                                                                .currentZoom /
                                                                1.2)
                                                    ),
                                              markerPercent(val[2]) < 1
                                                  ? 5
                                                  : 0.5 *
                                                    markerPercent(
                                                        val[2] *
                                                            (this.state
                                                                .currentZoom /
                                                                1.2)
                                                    )
                                          ),
                                          className: "leaflet-div-icon",
                                          html:
                                              markerPercent(val[2]) >= 1
                                                  ? `<span style="font-size:${0.15 *
                                                        (markerPercent(val[2]) *
                                                            this.state
                                                                .currentZoom)}px;" class="leaflet-div-icon__text">${markerPercent(
                                                        val[2]
                                                    )}%</span>`
                                                  : `<div style='background-color:rgba(255,255,255,0.5);' class=leaflet-div-icon__dot"></div>`
                                      })
                                  }
                              />
                          );
                      })
                    : null}
            </Map>
        );
    }
}
