import React from "react";
//import { Paper, Typography } from '@material-ui/core'

let styles = {
    container: {
        display: "flex"
    }
};

export default class Ai extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataReady: false
        };
    }
    loadTrainingData = () => {
        fetch(`/getrevenue?start=${"2019-01-01"}&end=${"2019-01-30"}`)
            .then(res => res.json())
            .then(res => {
                console.log(res);
                let test = shapeData(res);
                console.log(test);
            });
        let shapeData = res => {
            let data = Object.values(
                Object.keys(res).reduce((acc, k) => {
                    let val = res[k];
                    Object.values(val).map(o => {
                        let popRev = u => {
                            let revenue = 0;
                            let impressions = 0;
                            if (u.hasOwnProperty("AD_EXCHANGE")) {
                                revenue += parseInt(u["AD_EXCHANGE"].revenue);
                                impressions += parseInt(
                                    u["AD_EXCHANGE"].impressions
                                );
                            }
                            if (u.hasOwnProperty("PRICE_PRIORITY")) {
                                revenue += parseInt(
                                    u["PRICE_PRIORITY"].revenue
                                );
                                impressions += parseInt(
                                    u["PRICE_PRIORITY"].impressions
                                );
                            }
                            const {
                                AD_EXCHANGE,
                                PRICE_PRIORITY,
                                ...noBreakdown
                            } = u;
                            const data = {
                                ...noBreakdown,
                                revenue: revenue,
                                impressions: impressions
                            };
                            data.history = data.history || {};
                            data.history[k] = {
                                revenue: revenue,
                                impressions: impressions,
                                pageviews: u.pageviews,
                                sessions: u.sessions,
                                uniquePageviews: u.uniquePageviews
                            };
                            return data;
                        };
                        let x = popRev(o);
                        let add = () => {
                            if (!!x.sessions) {
                                !!acc[x.key].sessions
                                    ? (acc[x.key].sessions += x.sessions)
                                    : (acc[x.key].sessions = x.sessions);
                            }
                            if (!!x.uniquePageviews) {
                                !!acc[x.key].uniquePageviews
                                    ? (acc[x.key].uniquePageviews +=
                                          x.uniquePageviews)
                                    : (acc[x.key].uniquePageviews =
                                          x.uniquePageviews);
                            }
                            if (!!x.pageviews) {
                                !!acc[x.key].pageviews
                                    ? (acc[x.key].pageviews += x.pageviews)
                                    : (acc[x.key].pageviews = x.pageviews);
                            }
                            acc[x.key].revenue = acc[x.key].revenue || 0;
                            acc[x.key].revenue += parseInt(x.revenue);
                            acc[x.key].impressions =
                                acc[x.key].impressions || 0;
                            acc[x.key].impressions += parseInt(x.impressions);
                            acc[x.key].history[k] = {
                                revenue: parseInt(x.revenue),
                                impressions: parseInt(x.impressions),
                                pageviews: x.pageviews,
                                sessions: x.sessions,
                                uniquePageviews: x.uniquePageviews
                            };
                        };
                        acc.hasOwnProperty(x.key) ? add() : (acc[x.key] = x);
                    });
                    return acc;
                }, {})
            );
            let sortedData = data.sort((a, b) => {
                return a.revenue < b.revenue ? 1 : -1;
            });
            console.log(sortedData);
            this.setState({
                data: sortedData,
                dataReady: !this.state.dataReady
            });
        };
    };
    render() {
        let { dataReady, data } = this.state;
        // console.log()
        return (
            <div style={styles.container}>
                <div onClick={this.loadTrainingData}>
                    load/unload training data
                </div>
                {dataReady && <h1>data is ready</h1>}
            </div>
        );
    }
}
