import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import ReportFacebookPaid from "../features/reports/ReportFacebookPaid";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: "1rem" }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper
    }
});

class ScrollableTabsButtonAuto extends React.Component {
    state = {
        value: 0
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        const { value } = this.state;

        return (
            <div className={classes.root}>
                <AppBar position="static" color="default" elevation={0}>
                    <Tabs
                        className="tabHeader"
                        value={value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollable
                        scrollButtons="auto"
                    >
                        {this.props.report.fbads
                            ? this.props.report.fbads.map((val, i) => {
                                  return (
                                      <Tab
                                          key={"fbpaid-" + i}
                                          label={
                                              <span
                                                  className={"tabHeaderLabel"}
                                              >
                                                  {"Facebook Adset " + (i + 1)}
                                              </span>
                                          }
                                      />
                                  );
                              })
                            : null}
                    </Tabs>
                </AppBar>
                {this.props.report.fbads
                    ? this.props.report.fbads.map((val, i) => {
                          if (val) {
                              return (
                                  value === i && (
                                      <ReportFacebookPaid
                                          key={i}
                                          index={i}
                                          report={Object.values(val)}
                                          fullReport={this.props.report}
                                      />
                                  )
                              );
                          } else {
                              return null;
                          }
                      })
                    : null}
            </div>
        );
    }
}

ScrollableTabsButtonAuto.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ScrollableTabsButtonAuto);
