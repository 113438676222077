import React from "react";
import {
    Map as LeafletMap,
    FeatureGroup,
    LayersControl,
    TileLayer,
    Marker,
    Popup,
    Icon,
    Tooltip
} from "react-leaflet";
import L from "leaflet";
import HeatmapLayer from "react-leaflet-heatmap-layer";
// import narcity6m from "../assets/90686041_visitorValue_2018-08-01_2019-03-01.json";
// import mtlbog6m from "../assets/56108990_visitorValue_2018-08-01_2019-03-01.json";
import ReactTable from "react-table";
import { Consumer } from "../Context";

let narlogo = require("../assets/narlogo.jpg");
let mtllogo = require("../assets/mtlblog.png");

// require("@tensorflow/tfjs-node"); // -gpu or cpu (default)
// const tf = require("@tensorflow/tfjs");

export default class LTV extends React.PureComponent {
    static contextType = Consumer;
    constructor(props) {
        super(props);
        this.scaleRatioForYear = 2;
        this.state = {
            userLocation: [45, -75],
            zoom: 5,
            narcityData: null,
            mtlblogData: null,
            loading: true,
            switch: true,
            popup: null,
            bounds: null,
            filterResults: null
        };
    }

    componentWillMount() {
        this.context.setState({ loading: true });
        let filter = x => {
            let test1 = x.city_totalImpressions > 100;
            let test2 = x.city_totalImpressions / x.city_totalCodeServed <= 1;
            let test3 = x.ga_cityUsers > 100;
            let test4 = x.gam_cityName != "N/A";
            let test5 = x.city_revenuePerUser / 1000 < 1000;
            let test6 = parseFloat(x.city_ctr) * 100 < 1;
            return test1 && test2 && test3 && test4 && test5 && test6;
        };
        let normalize = d => {
            let numberedHeaders = Object.entries(d[0]).reduce((a, x) => {
                typeof x[1] === "number" ? a.push(x[0]) : void 0;
                return a;
            }, []);
            let maxmins = numberedHeaders.reduce((a, v) => {
                let numbers = d.map(x => x[v]);
                a[v] = [Math.min(...numbers), Math.max(...numbers)];
                return a;
            }, {});
            return d.map(x => {
                return {
                    ...x,
                    ...numberedHeaders.reduce((a, v) => {
                        a[v] =
                            (x[v] - maxmins[v][0]) /
                            (maxmins[v][1] - maxmins[v][0]);
                        return a;
                    }, {})
                };
            });
        };

        let csvToJSON = d => {
            let t = d.split("\n").reduce(
                (a, v, i) => {
                    let line = v.split(",");
                    if (i === 0) {
                        a.header = line;
                    } else {
                        let y = a.header.reduce((aa, vv, ii) => {
                            aa[vv] = line[ii];
                            return aa;
                        }, {});
                        a.data.push(y);
                    }
                    return a;
                },
                { data: [] }
            );
            return t.data;
        };

        fetch("/ltvdata?id=90686041", { "Content-Type": "text/csv" })
            .then(res => res.text())
            .then(x => {
                let res = csvToJSON(x).filter(filter);
                this.setState({
                    narcityData: res,
                    loading: false
                });
                this.context.setState({ loading: false });
                fetch("/ltvdata?id=56108990", { "Content-Type": "text/csv" })
                    .then(res => res.text())
                    .then(y => {
                        let resy = csvToJSON(y).filter(filter);
                        this.setState({
                            mtlblogData: resy
                        });
                    });
            });

        navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords;
                this.setState({
                    userLocation: [latitude, longitude],
                    zoom: 8
                });
            },
            err => {
                console.log(err);
            }
        );
    }

    createPopup = e => {
        // console.log(e.latlng)
        this.setState({
            popup: {
                ...e.latlng
            }
        });
    };

    frameData(coords) {
        let lat = coords.map(x => x[0]);
        let lon = coords.map(x => x[1]);
        // console.log(lat, lon);
        // console.log([Math.min(...lat), Math.max(...lat)]);
        // console.log([Math.min(...lon), Math.max(...lon)]);
        lat.length > 0 &&
            lon.length > 0 &&
            this.setState({
                bounds: [
                    [Math.min(...lat), Math.min(...lon)],
                    [Math.max(...lat), Math.max(...lon)]
                ]
            });
    }

    render() {
        return this.state.loading ? (
            <div style={{ display: "none" }}>loading</div>
        ) : (
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ flex: 1, overflow: "scroll" }}>
                    <ReactTable
                        filterable
                        ref={r => {
                            this.selectTable = r;
                        }}
                        onFilteredChange={filter => {
                            if (
                                this.selectTable.getResolvedState().sortedData
                                    .length < 20
                            ) {
                                let d = this.selectTable
                                    .getResolvedState()
                                    .sortedData.map(row => {
                                        return [
                                            parseFloat(
                                                row["_original"]["coord_lat"]
                                            ),
                                            parseFloat(
                                                row["_original"]["coord_long"]
                                            )
                                        ];
                                    });
                                this.frameData(d);
                                this.setState({ filterResults: d });
                            } else {
                                this.state.bounds === null
                                    ? void 0
                                    : this.setState({
                                          bounds: null,
                                          filterResults: null
                                      });
                            }
                        }}
                        defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id;

                            if (row[id] !== undefined) {
                                let s = String(row[id])
                                    .toLowerCase()
                                    .startsWith(filter.value.toLowerCase());
                                return s;
                            } else {
                                return true;
                            }
                        }}
                        defaultSortMethod={(a, b, desc) => {
                            // force null and undefined to the bottom
                            a = a === null || a === undefined ? "" : a;
                            b = b === null || b === undefined ? "" : b;
                            // force any string values to lowercase
                            a =
                                typeof a === "string"
                                    ? a.toLowerCase()
                                    : parseInt(a);
                            b =
                                typeof b === "string"
                                    ? b.toLowerCase()
                                    : parseInt(b);
                            // Return either 1 or -1 to indicate a sort priority
                            return a - b;
                            // returning 0, undefined or any falsey value will use subsequent sorts or
                            // the index as a tiebreaker
                            return 0;
                        }}
                        style={{ width: "100%" }}
                        data={
                            this.state.switch
                                ? this.state.narcityData
                                : this.state.mtlblogData
                        }
                        columns={[
                            {
                                Header: "Geography",
                                columns: [
                                    {
                                        Header: "City",
                                        id: "gamCity",
                                        accessor: d => {
                                            return d["gam_cityName"];
                                        }
                                    },
                                    {
                                        Header: "Region",
                                        id: "region",
                                        accessor: d => {
                                            return d["gam_regionName"];
                                        }
                                    }
                                    // {
                                    //     Header: "cityid",
                                    //     id: "cityid",
                                    //     accessor: d => {
                                    //         return d["ga:cityId"];
                                    //     }
                                    // }
                                ]
                            },
                            {
                                Header: "Metrics",
                                columns: [
                                    {
                                        Header: "LTV (CA$/Mille/year)",
                                        id: "visitorValue",
                                        accessor: d => {
                                            return (
                                                (d.city_revenuePerUser *
                                                    this.scaleRatioForYear) /
                                                1000
                                            ).toFixed(2);
                                        }
                                    },
                                    {
                                        Header: "Est. Annual Revenue (CA$)",
                                        id: "rev",
                                        accessor: d => {
                                            let t =
                                                (d.city_totalRevenue /
                                                    1000000) *
                                                this.scaleRatioForYear;
                                            return t.toFixed();
                                        }
                                    },
                                    {
                                        Header: "Filled %",
                                        id: "fillRate",
                                        accessor: d => {
                                            let t = (
                                                (d.city_totalImpressions /
                                                    d.city_totalCodeServed) *
                                                100
                                            ).toFixed(2);
                                            return t;
                                        }
                                    },
                                    {
                                        Header: "Total Impressions Served",
                                        id: "impServed",
                                        accessor: d => {
                                            return d.city_totalImpressions;
                                        }
                                    },
                                    {
                                        Header: "impression RPM",
                                        id: "rpm",
                                        accessor: d => {
                                            return (
                                                parseFloat(d.city_rpm) / 1000000
                                            ).toFixed(2);
                                        }
                                    },
                                    {
                                        Header: "Pageview frequency",
                                        id: "ppu",
                                        accessor: d =>
                                            (
                                                d.ga_cityPageviews /
                                                d.ga_cityUsers
                                            ).toFixed(2)
                                    },
                                    {
                                        Header: "Users",
                                        id: "users",
                                        accessor: d => d.ga_cityUsers
                                    },
                                    {
                                        Header: "Ad CTR %",
                                        id: "ctr",
                                        accessor: d =>
                                            (
                                                parseFloat(d.city_ctr) * 100
                                            ).toFixed(2)
                                    }
                                ]
                            }
                        ]}
                        defaultSorted={[
                            {
                                id: "rev",
                                desc: true
                            }
                        ]}
                        defaultPageSize={20}
                        className="-striped -highlight"
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <div
                        style={{
                            position: "absolute",
                            zIndex: 999,
                            width: 60,
                            height: 60,
                            margin: 5
                        }}
                        onClick={e =>
                            this.setState({ switch: !this.state.switch })
                        }
                    >
                        <img
                            style={{ objectFit: "contain", width: "100%" }}
                            src={this.state.switch ? narlogo : mtllogo}
                        />
                    </div>
                    <LeafletMap
                        bounds={
                            this.state.bounds
                                ? new L.LatLngBounds(this.state.bounds).pad(0.5)
                                : null
                        }
                        style={{ width: "100%", height: "800px" }}
                        center={this.state.userLocation}
                        zoom={this.state.zoom}
                        maxZoom={10}
                        attributionControl={true}
                        zoomControl={true}
                        doubleClickZoom={true}
                        scrollWheelZoom={true}
                        dragging={true}
                        animate={true}
                        easeLinearity={0.35}
                        preferCanvas={true}
                        onmousemove={e => this.setState({ popup: null })}
                        onclick={this.createPopup}
                        // onmouseup={e=>this.setState({popup:null})}
                    >
                        {this.state.filterResults &&
                            this.state.filterResults.map((marker, i) => {
                                return (
                                    <Marker
                                        key={"mark" + i}
                                        position={marker}
                                        icon={
                                            new L.DivIcon({
                                                className: "leaflet-div-icon",
                                                iconSize: 10
                                            })
                                        }
                                    />
                                );
                            })}
                        {/* {popup && (
                        <Popup
                            // key={`popup-${popup.key}`}
                            position={popup}
                        >
                            <p>
                                {"User visitor value: HIGH (3.24/year)\n" +
                                    "Revenue per Pageviews: 30 RPM" +
                                    "\n" +
                                    "Revenue per filled impressions: x" +
                                    "\n" +
                                    "Filled ratio: 90%"}
                            </p>
                        </Popup>
                    )} */}
                        <LayersControl>
                            <LayersControl.BaseLayer name="Base" checked>
                                {/* <Tooltip /> */}
                                <TileLayer
                                    url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
                                    attribution="&copy; <a href=http://osm.org/copyright>OpenStreetMap</a> contributors"
                                />
                            </LayersControl.BaseLayer>
                            {[
                                {
                                    name: "Visitor Value (LTV)",
                                    checked: true,
                                    intensityExtractor: d => {
                                        return (
                                            (d.city_revenuePerUser *
                                                this.scaleRatioForYear) /
                                            1000
                                        ).toFixed(2);
                                    }
                                },
                                {
                                    name: "User frequency",
                                    intensityExtractor: m =>
                                        m.coord_pageviews / m.coord_users
                                }
                            ].map((layer, i) => (
                                <LayersControl.Overlay
                                    key={"map-" + i}
                                    name={layer.name}
                                    checked={layer.checked || false}
                                >
                                    <FeatureGroup color="black">
                                        <HeatmapLayer
                                            points={
                                                !this.state.switch
                                                    ? this.state.narcityData
                                                    : this.state.mtlblogData
                                            }
                                            longitudeExtractor={m =>
                                                m.coord_long
                                            }
                                            latitudeExtractor={m => m.coord_lat}
                                            intensityExtractor={
                                                layer.intensityExtractor
                                            }
                                        />
                                        {/* <Popup position={this.state.popup}>
                                            User visitor value: HIGH (3.24/year)
                                            \n Revenue per Pageviews: 30 RPM \n
                                            Revenue per filled impressions: x \n
                                            Filled ratio: 90%{" "}
                                        </Popup> */}
                                        <Tooltip />
                                    </FeatureGroup>
                                </LayersControl.Overlay>
                            ))}
                        </LayersControl>

                        {/* <HeatmapLayer
            fitBoundsOnLoad
            fitBoundsOnUpdate
            points={UsersByRegion}
            longitudeExtractor={m => m["ga:longitude"]}
            latitudeExtractor={m => m["ga:latitude"]}
            intensityExtractor={m => Math.log(parseFloat(m["ga:pageviews"]))}
          />
          <TileLayer url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png" /> */}
                    </LeafletMap>
                </div>
            </div>
        );
    }
}

// {
//     "coord_totalrevenue": 5742,
//     "coord_totalimpressions": 8,
//     "coord_lat": "9.9634",
//     "coord_long": "-84.0458",
//     "city_totalRevenue": 5742,
//     "city_totalImpressions": 8,
//     "city_totalUnfilled": 0,
//     "city_totalCodeServed": 8,
//     "city_rpm": "717794",
//     "city_ctr": "0.0000",
//     "city_revenuePerUser": 5742,
//     "city_revenuePerPageview": 957,
//     "city_pageviewsPerUser": 6,
//     "city_impressionsPerUser": 8,
//     "gam_cityName": "San Vicente",
//     "gam_cityId": "9068562",
//     "gam_regionName": "San Jose Province",
//     "ga_cityName": "San Vicente",
//     "ga_cityId": "9068562",
//     "ga_cityUsers": 1,
//     "ga_cityPageviews": 6
//   }
