import React from "react";
import { FacebookBox } from "mdi-material-ui";
import { Reactions } from "../../../Reactions";
import { Consumer } from "../../../../Context";

export default class PostOverview extends React.Component {
    render() {
        return (
            <Consumer>
                {context => {
                    return (
                        <div className="reports-container reports-container--fborganic">
                            <div className="reports">
                                <div className="details__title-center">
                                    Facebook Organic Overview
                                </div>
                                <div className="reports__components">
                                    <div className="article-overview">
                                        <div className="article-overview-box">
                                            {this.props.report.picture && (
                                                <img
                                                    alt="report pic"
                                                    className="article-overview-box__img article-overview-box__img--fb"
                                                    src={
                                                        this.props.report
                                                            .picture
                                                    }
                                                />
                                            )}
                                            <div className="article-overview-box__textBox">
                                                <a
                                                    href={
                                                        this.props.report
                                                            .postURL
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="facebook-section"
                                                >
                                                    <div className="facebook-section__heading">
                                                        <FacebookBox className="facebook-icon" />
                                                        VIA{" "}
                                                        {
                                                            this.props.report
                                                                .caption
                                                        }
                                                    </div>
                                                    <div className="facebook-section__subtitle">
                                                        {
                                                            this.props.report
                                                                .message
                                                        }
                                                    </div>
                                                    <div className="facebook-section__title">
                                                        {this.props.report.name
                                                            .length > 120
                                                            ? this.props.report.name.substr(
                                                                  0,
                                                                  120
                                                              ) + "..."
                                                            : this.props.report
                                                                  .name}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        {/* <a
                                href={this.props.report.postURL}
                                className="reports__header-url"
                            >
                                <div className="reports__via">
                                    via {this.props.report.caption}
                                </div>
                                <div className="reports__subtitle">
                                    {this.props.report.message}
                                </div>
                                <div className="reports__heading heading-fb-component">
                                    <FacebookBox className="facebook-icon" />
                                    {this.props.report.name.substr(0, 100) +
                                        "..."}{" "}
                                    &rarr;
                                </div>
                            </a> */}
                                    </div>
                                    <div className="reports-container">
                                        <div className="reports">
                                            <div className="reports__components">
                                                <div className="graph-component">
                                                    <div className="reports__content">
                                                        <div className="reports__flex-area reports__flex-area--graph">
                                                            <div className="reports__details reports__details--reactions">
                                                                <div className="details__title-center details__title-center--reactions">
                                                                    Reactions
                                                                </div>
                                                                <div className="details__graph-box">
                                                                    <Reactions
                                                                        data={
                                                                            this
                                                                                .props
                                                                                .report
                                                                                .postReactions
                                                                                .value
                                                                        }
                                                                        index={
                                                                            this
                                                                                .props
                                                                                .index
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="reports__details">
                                                                <div className="details__title-center details__title-center--fb">
                                                                    Total Stats
                                                                </div>
                                                                <ul className="breakdown__list">
                                                                    <li className="breakdown__item breakdown__item--facebook">
                                                                        <div className="breakdown__item--title">
                                                                            Statistic
                                                                        </div>
                                                                        <div className="breakdown__item--title">
                                                                            Amount
                                                                        </div>
                                                                    </li>
                                                                    <li className="breakdown__item breakdown__item--facebook">
                                                                        <div
                                                                            className="breakdown__item--name tooltip-bottom"
                                                                            data-tooltip="The number of people who saw your post directly from our Page."
                                                                        >
                                                                            Organic
                                                                            Reach
                                                                        </div>
                                                                        <div className="breakdown__item--value">
                                                                            {this.props.report.reach.toLocaleString()}
                                                                        </div>
                                                                    </li>
                                                                    <li className="breakdown__item breakdown__item--facebook">
                                                                        <div
                                                                            className="breakdown__item--name tooltip-bottom"
                                                                            data-tooltip="The number of times people saw your post directly from our Page."
                                                                        >
                                                                            Impressions
                                                                        </div>
                                                                        <div className="breakdown__item--value">
                                                                            {this.props.report.lifetimePostImpressions.toLocaleString()}
                                                                        </div>
                                                                    </li>

                                                                    <li className="breakdown__item breakdown__item--facebook">
                                                                        <div className="breakdown__item--name tooltip-bottom">
                                                                            Likes
                                                                        </div>
                                                                        <div className="breakdown__item--value">
                                                                            {this
                                                                                .props
                                                                                .report
                                                                                .postReactions
                                                                                ? this.props.report.postReactions.value.like.toLocaleString()
                                                                                : "0"}
                                                                        </div>
                                                                    </li>
                                                                    <li className="breakdown__item breakdown__item--facebook">
                                                                        <div className="breakdown__item--name tooltip-bottom">
                                                                            Shares
                                                                        </div>
                                                                        <div className="breakdown__item--value">
                                                                            {this.props.report.shares.toLocaleString()}
                                                                        </div>
                                                                    </li>
                                                                    <li className="breakdown__item breakdown__item--facebook">
                                                                        <div className="breakdown__item--name tooltip-bottom">
                                                                            Comments
                                                                        </div>
                                                                        <div className="breakdown__item--value">
                                                                            {this
                                                                                .props
                                                                                .report
                                                                                .comments
                                                                                ? this.props.report.comments.toLocaleString()
                                                                                : 0}
                                                                        </div>
                                                                    </li>
                                                                    <li className="breakdown__item breakdown__item--facebook">
                                                                        <div className="breakdown__item--name tooltip-bottom">
                                                                            Link
                                                                            Clicks
                                                                        </div>
                                                                        <div className="breakdown__item--value">
                                                                            {this
                                                                                .props
                                                                                .report
                                                                                .clicks
                                                                                ? this.props.report.clicks[
                                                                                      "link clicks"
                                                                                  ].toLocaleString()
                                                                                : 0}
                                                                        </div>
                                                                    </li>

                                                                    {this.props
                                                                        .report
                                                                        .postReactions && (
                                                                        <li className="breakdown__item breakdown__item--facebook">
                                                                            <div
                                                                                className="breakdown__item--name tooltip-bottom"
                                                                                data-tooltip="Total amount of 'Likes, Love, Angry, Mad, Haha...' etc.."
                                                                            >
                                                                                Total
                                                                                Reactions
                                                                            </div>
                                                                            <div className="breakdown__item--value">
                                                                                {Object.values(
                                                                                    this
                                                                                        .props
                                                                                        .report
                                                                                        .postReactions
                                                                                        .value
                                                                                ).reduce(
                                                                                    (
                                                                                        acc,
                                                                                        x
                                                                                    ) =>
                                                                                        (acc += x),
                                                                                    0
                                                                                )}
                                                                            </div>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{" "}
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Consumer>
        );
    }
}
