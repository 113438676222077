import React from "react";
import FBPaidOverview from "./components/FacebookPaid/FBPaidOverview";

export default class ReportFacebookPaid extends React.Component {
    render() {
        return (
            <div>
                <FBPaidOverview
                    fullReport={this.props.fullReport}
                    report={this.props.report}
                    index={this.props.index}
                />
                <div className="component-seperator" />
            </div>
        );
    }
}
