import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import PongLinks from "./PongLinks";
import { OpenInNew } from "@material-ui/icons";

export default class Overviewv2 extends React.Component {
    state = {
        cursor: 0,
        open: false
    };

    handleTooltipClose = () => {
        this.setState({ open: false });
    };

    handleTooltipOpen = () => {
        this.setState({ open: true });
    };

    render() {
        let report = this.props.report.content[this.props.index];
        let calculate = secs => {
            return [Math.trunc(secs / 60) || 0, Math.round(secs % 60)];
        };
        return (
            <div className="reports-container">
                <div className="reports">
                    <div className="reports__components">
                        <div className="reports__header-bar-article">
                            <h1 className="reports__heading">
                                Article Preview
                            </h1>
                            <a
                                href={report.articleMeta.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="reports__header-url"
                            >
                                <OpenInNew className="reports__header-bar-icon" />
                            </a>
                        </div>
                        <div className="info-component">
                            <div className="reports__content">
                                <div className="article-overview">
                                    <div className="article-overview-box">
                                        <img
                                            src={report.articleMeta.image}
                                            alt="article main"
                                            className="article-overview-box__img"
                                        />
                                        <div className="article-overview-box__textBox">
                                            <div className="article-overview-box__text">
                                                {report.articleMeta.title > 120
                                                    ? report.articleMeta.title.substr(
                                                          0,
                                                          120
                                                      ) + "..."
                                                    : report.articleMeta.title}
                                            </div>
                                            <div className="article-overview-box__published">
                                                Published on{" "}
                                                {report.articleMeta.createdOn.substr(
                                                    0,
                                                    10
                                                )}{" "}
                                                <a
                                                    href={
                                                        report.articleMeta.url
                                                    }
                                                    className="article-overview-box__view"
                                                    target="_blank"
                                                >
                                                    view article
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="article-stats">
                                    <div className="article-stats-box">
                                        <div className="article-stats-box__item">
                                            <div className="article-stats-box__value">
                                                {!!report.generalData
                                                    ? report.generalData.total[
                                                          "ga:uniquePageviews"
                                                      ]
                                                        ? parseInt(
                                                              report.generalData
                                                                  .total[
                                                                  "ga:uniquePageviews"
                                                              ]
                                                          ).toLocaleString()
                                                        : parseInt(
                                                              report.generalData
                                                                  .total[
                                                                  "ga:pageviews"
                                                              ]
                                                          ).toLocaleString()
                                                    : 0}
                                            </div>

                                            <div
                                                className="article-stats-box__title tooltip-bottom"
                                                data-tooltip="Counts a page once even if it was viewed multiple times within a single session."
                                            >
                                                Unique Pageviews
                                            </div>
                                        </div>
                                        <div className="article-stats-box__item">
                                            <div className="article-stats-box__value">
                                                {!!report.generalData
                                                    ? `${
                                                          calculate(
                                                              report.generalData
                                                                  .total[
                                                                  "ga:avgTimeOnPage"
                                                              ]
                                                          )[0]
                                                      } mins ${
                                                          calculate(
                                                              report.generalData
                                                                  .total[
                                                                  "ga:avgTimeOnPage"
                                                              ]
                                                          )[1]
                                                      } s`
                                                    : 0}
                                            </div>
                                            <div
                                                className="article-stats-box__title tooltip-bottom"
                                                data-tooltip="Average of how long users spent on this page."
                                            >
                                                Avg. Time
                                            </div>
                                        </div>

                                        {/* <div className="article-stats-box__item">
                                            <div className="article-stats-box__value">
                                                {!!report.generalData
                                                    ? parseInt(
                                                          report.generalData
                                                              .total[
                                                              "ga:sessions"
                                                          ]
                                                      ).toLocaleString()
                                                    : 0}
                                            </div>
                                            <div
                                                className="article-stats-box__title tooltip-bottom"
                                                data-tooltip="A single visit to your website, consisting of one or more pageviews. The default session timeout is 30 minutes, which means that if someone is inactive on your website for over 30 minutes, then a new session will be reported if they perform another interaction, for example, viewing another page."
                                            >
                                                Sessions
                                            </div>
                                        </div> */}
                                        {/* <div className="article-stats-box__item">
                                            <div className="article-stats-box__value">
                                                {!!report.generalData
                                                    ? parseInt(
                                                          report.generalData
                                                              .total["ga:users"]
                                                      ).toLocaleString()
                                                    : 0}
                                            </div>
                                            <div
                                                className="article-stats-box__title tooltip-bottom"
                                                data-tooltip="An individual person browsing your page. Each user can visit your page multiple times."
                                            >
                                                Users
                                            </div>
                                        </div> */}
                                        {report.articleMeta.pongLinks && (
                                            <div className="article-stats-box__item">
                                                <div className="article-stats-box__value">
                                                    {parseInt(
                                                        report.articleMeta
                                                            .pongLinks
                                                            .totalClicks
                                                    ).toLocaleString()}
                                                </div>
                                                <div
                                                    className="article-stats-box__title tooltip-bottom"
                                                    data-tooltip="Total tracked link clicks since article was published."
                                                >
                                                    Clicks Out
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {this.props.report.form.goal ? (
                                    this.props.report.form.goal[
                                        `booked_reads_${this.props.index}`
                                    ] ? (
                                        <div className="article-progress">
                                            <div className="article-progress-box">
                                                <div className="article-progress-box__text">
                                                    <span className="article-progress-box__booked">
                                                        {!!report.generalData
                                                            ? report.generalData
                                                                  .total[
                                                                  "ga:uniquePageviews"
                                                              ]
                                                                ? parseInt(
                                                                      report
                                                                          .generalData
                                                                          .total[
                                                                          "ga:uniquePageviews"
                                                                      ]
                                                                  ).toLocaleString()
                                                                : !!report
                                                                      .generalData
                                                                      .total[
                                                                      "ga:pageviews"
                                                                  ]
                                                                ? report
                                                                      .generalData
                                                                      .total[
                                                                      "ga:pageviews"
                                                                  ]
                                                                : 0
                                                            : 0}{" "}
                                                        /{" "}
                                                        {parseInt(
                                                            this.props.report
                                                                .form.goal[
                                                                `booked_reads_${
                                                                    this.props
                                                                        .index
                                                                }`
                                                            ]
                                                        ).toLocaleString()}
                                                    </span>{" "}
                                                    Reads Delivered [
                                                    {!!report.generalData
                                                        ? (
                                                              ((report
                                                                  .generalData
                                                                  .total[
                                                                  "ga:uniquePageviews"
                                                              ]
                                                                  ? report
                                                                        .generalData
                                                                        .total[
                                                                        "ga:uniquePageviews"
                                                                    ]
                                                                  : report
                                                                        .generalData
                                                                        .total[
                                                                        "ga:pageviews"
                                                                    ]) /
                                                                  this.props.report.form.goal[
                                                                      `booked_reads_${
                                                                          this
                                                                              .props
                                                                              .index
                                                                      }`
                                                                  ].replace(
                                                                      /\D/g,
                                                                      ""
                                                                  )) *
                                                              100
                                                          ).toFixed()
                                                        : 0}
                                                    %]
                                                </div>
                                                <div className="article-progress-box__bar">
                                                    <LinearProgress
                                                        color={
                                                            !!report.generalData
                                                                ? ((report
                                                                      .generalData
                                                                      .total[
                                                                      "ga:uniquePageviews"
                                                                  ]
                                                                      ? report
                                                                            .generalData
                                                                            .total[
                                                                            "ga:uniquePageviews"
                                                                        ]
                                                                      : report
                                                                            .generalData
                                                                            .total[
                                                                            "ga:pageviews"
                                                                        ]) /
                                                                      this.props.report.form.goal[
                                                                          `booked_reads_${
                                                                              this
                                                                                  .props
                                                                                  .index
                                                                          }`
                                                                      ].replace(
                                                                          /\D/g,
                                                                          ""
                                                                      )) *
                                                                      100 >
                                                                  50
                                                                    ? "primary"
                                                                    : "secondary"
                                                                : "secondary"
                                                        }
                                                        variant="determinate"
                                                        value={
                                                            !!report.generalData
                                                                ? ((report
                                                                      .generalData
                                                                      .total[
                                                                      "ga:uniquePageviews"
                                                                  ]
                                                                      ? report
                                                                            .generalData
                                                                            .total[
                                                                            "ga:uniquePageviews"
                                                                        ]
                                                                      : report
                                                                            .generalData
                                                                            .total[
                                                                            "ga:pageviews"
                                                                        ]) /
                                                                      this.props.report.form.goal[
                                                                          `booked_reads_${
                                                                              this
                                                                                  .props
                                                                                  .index
                                                                          }`
                                                                      ].replace(
                                                                          /\D/g,
                                                                          ""
                                                                      )) *
                                                                  100
                                                                : 0
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.report.content[this.props.index].articleMeta
                    .pongLinks ? (
                    <PongLinks
                        report={this.props.report}
                        index={this.props.index}
                        key={this.props.index}
                    />
                ) : null}
            </div>
        );
    }
}
