import fire from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

let { config } = require("./config/firebaseApp");
fire.initializeApp(config.dev);

export const googleProvider = new fire.auth.GoogleAuthProvider();
export const functions = fire.functions();
export const firebaseAuth = fire.auth;
export const storage = fire.storage();
const firestore = fire.firestore();
firestore.settings({ timestampsInSnapshots: true });
export default firestore;
export const firebase = fire;
