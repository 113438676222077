import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

const TeamGraphTable = props => {
    const counts = Object.values(props.data).reduce(
        (acc, person) => {
            acc.pageviews += person.uniquePageviews;
            acc.articles += Object.values(person.articles).length;
            return acc;
        },
        { pageviews: 0, articles: 0 }
    );

    const averageUP = (counts.pageviews / counts.articles).toFixed();
    return (
        <ReactTable
            filterable
            defaultFilterMethod={(filter, row, column) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined
                    ? String(row[id])
                          .toLowerCase()
                          .startsWith(filter.value.toLowerCase())
                    : true;
            }}
            defaultSortMethod={(a, b, desc) => {
                // force null and undefined to the bottom
                a = a === null || a === undefined ? "" : a;
                b = b === null || b === undefined ? "" : b;
                // force any string values to lowercase
                a = typeof a === "string" ? a.toLowerCase() : parseInt(a);
                b = typeof b === "string" ? b.toLowerCase() : parseInt(b);
                // Return either 1 or -1 to indicate a sort priority
                return a - b;
                // returning 0, undefined or any falsey value will use subsequent sorts or
                // the index as a tiebreaker
                return 0;
            }}
            style={{ width: "100%" }}
            data={Object.values(props.data)}
            showPagination={false}
            columns={[
                {
                    Header: "Author",
                    columns: [
                        {
                            Header: "Name",
                            accessor: "author",
                            Footer: "Totals"
                        }
                    ]
                },
                {
                    Header: "Metrics",
                    columns: [
                        {
                            Header: "Articles Published",
                            id: "articleCount",
                            accessor: d => Object.keys(d.articles).length,
                            Footer: (
                                <span>
                                    {Object.values(props.data).reduce(
                                        (total, item) => {
                                            total += Object.values(
                                                item.articles
                                            ).length;

                                            return total;
                                        },
                                        0
                                    )}
                                </span>
                            )
                        },
                        {
                            Header: "Unique Pageviews",
                            accessor: "uniquePageviews",
                            Footer: (
                                <span>
                                    {Object.values(props.data).reduce(
                                        (total, { uniquePageviews }) =>
                                            (total += uniquePageviews),
                                        0
                                    )}
                                </span>
                            )
                        },
                        {
                            Header: "UP/article",
                            id: "reachperarticle",
                            accessor: d =>
                                (
                                    d.uniquePageviews /
                                    Object.keys(d.articles).length
                                ).toFixed() || 0,
                            Footer: <span>{averageUP} Average</span>
                        }
                    ]
                }
            ]}
            defaultSorted={[
                {
                    id: "uniquePageviews",
                    desc: true
                }
            ]}
            defaultPageSize={Object.keys(props.data).length}
            className="-striped -highlight"
        />
    );
};

export default TeamGraphTable;
