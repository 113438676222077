import React, { Component } from "react";
import firestore, { firebase, storage } from "../firebase";
import moment from "moment";
import { Consumer } from "../Context";
import { Progress } from "react-sweet-progress";
import Select from "react-select";
import "react-sweet-progress/lib/style.css";
import { Dialog } from "@material-ui/core";
import { DialogInside, DialogButton } from "../components/styled/Modal";
import { StylishInput, StylishLabel } from "../components/styled/Inputs";
import {
    Wrapper,
    Sidebar,
    Body,
    DashItem,
    ChartDash,
    TeamWrapper,
    TableDash,
    ProgressDash
} from "./styles/PublisherInsightsStyles";
import { FixedButton, CircleButton } from "../components/styled/Button";
import { AccountGroup, Plus } from "mdi-material-ui";
import TeamGraph from "../components/TeamGraph";
import TeamGraphTable from "../components/TeamGraphTable";
import TeamGraphArticles from "../components/TeamGraphArticles";
import TeamLeaderboard from "../components/TeamLeaderboard";
import TeamArticleTable from "../components/TeamArticleTable";
import LeaderboardGraph from "../components/Leaderboard/LeaderboardGraph";
import LeaderboardPie from "../components/Leaderboard/LeaderboardPie";

const dev = false;

class PublisherInsights extends Component {
    static contextType = Consumer;
    constructor(props) {
        super(props);
        this.state = {
            selectedTeam: "",
            showAll: false,
            data: {},
            showModal: false,
            currentMonth: "",
            teamName: "",
            goal: "",
            goalNext: "",
            goalLast: "",
            leader: "",
            members: [],
            tempfiles: {},
            customOption: ""
        };
        this.getData = this.getData.bind(this);
    }

    toggleModal = () => {
        this.setState({
            teamName: "",
            goal: "",
            editingModal: false,
            goalNext: "",
            goalLast: "",
            leader: "",
            members: [],
            tempfiles: {}
        });
        return this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    };

    convertNanoDollars = nano => {
        return nano / 1000000;
    };

    formatter = d => {
        //YYYY-MM-DD
        let date = new Date(d);
        return (
            date.getFullYear() +
            `-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
                "0" + date.getDate()
            ).slice(-2)}`
        );
    };

    getOptions() {
        let fixedData = Object.values(this.state.data).reduce((acc, author) => {
            acc.push({ value: author.author, label: author.author });
            return acc;
        }, []);
        return fixedData;
    }

    editTeam = teamName => {
        if (this.props.user.roles.includes("admin")) {
            firestore
                .collection("publisherInsights")
                .doc("teams")
                .get()
                .then(teamData => {
                    let teams = teamData.data();
                    let selectedTeam = teams[teamName];
                    this.setState({
                        showModal: true,
                        editingModal: true,
                        teamName,
                        goal: selectedTeam.goal[moment().format("MMMM")],
                        goalNext:
                            selectedTeam.goal[
                                moment()
                                    .add(1, "month")
                                    .format("MMMM")
                            ],
                        goalLast:
                            selectedTeam.goal[
                                moment()
                                    .subtract(1, "month")
                                    .format("MMMM")
                            ],
                        leader: selectedTeam.leader,
                        members: selectedTeam.members.reduce((acc, val) => {
                            acc = [...acc, { value: val, label: val }];
                            return acc;
                        }, [])
                    });
                });
        }
    };

    createTeam = ({ teamName, goal, goalNext, goalLast, leader, members }) => {
        if (this.props.user.roles.includes("admin")) {
            let uploads = Object.keys(this.state.tempfiles).reduce(
                (acc, key) => {
                    this.state.tempfiles[key].map(file => {
                        file.loc = key;
                        acc.push(file);
                    });
                    return acc;
                },
                []
            );
            if (uploads.length > 0) {
                Promise.all(
                    uploads.map(x => this.uploadFiles(x, teamName))
                ).then(uploadedUrls => {
                    firestore
                        .collection("publisherInsights")
                        .doc("teams")
                        .update({
                            [teamName]: {
                                image: uploadedUrls ? uploadedUrls[0] : null,
                                goal: {
                                    [moment().format("MMMM")]: parseFloat(goal),
                                    [moment()
                                        .add(1, "month")
                                        .format("MMMM")]: parseFloat(goalNext),
                                    [moment()
                                        .subtract(1, "month")
                                        .format("MMMM")]: parseFloat(goalLast)
                                },
                                leader,
                                members: members.map(val => val.value)
                            }
                        })
                        .then(e => {
                            if (this.state.editingModal) {
                                this.setState({
                                    teamStats: {
                                        ...this.state.teamStats,
                                        [teamName]: {
                                            ...this.state.teamStats[teamName],
                                            data: {
                                                goal: {
                                                    [moment().format(
                                                        "MMMM"
                                                    )]: parseFloat(goal),
                                                    [moment()
                                                        .add(1, "month")
                                                        .format(
                                                            "MMMM"
                                                        )]: parseFloat(
                                                        goalNext
                                                    ),
                                                    [moment()
                                                        .subtract(1, "month")
                                                        .format(
                                                            "MMMM"
                                                        )]: parseFloat(goalLast)
                                                },
                                                leader: leader,
                                                members: members.map(
                                                    val => val.value
                                                )
                                            }
                                        }
                                    }
                                });
                            } else {
                                this.getData();
                            }
                            this.setState({
                                selectedTeam: teamName,
                                showModal: false,
                                editingModal: false,
                                teamName: "",
                                goal: "",
                                goalNext: "",
                                goalLast: "",
                                tempfiles: {},
                                leader: "",
                                members: []
                            });
                        });
                });
            } else {
                return firestore
                    .collection("publisherInsights")
                    .doc("teams")
                    .update({
                        [teamName]: {
                            goal: {
                                [moment().format("MMMM")]: parseFloat(goal),
                                [moment()
                                    .add(1, "month")
                                    .format("MMMM")]: parseFloat(goalNext),
                                [moment()
                                    .subtract(1, "month")
                                    .format("MMMM")]: parseFloat(goalLast)
                            },
                            leader: leader,
                            members: members.map(val => val.value)
                        }
                    })
                    .then(e => {
                        if (this.state.editingModal) {
                            this.setState({
                                teamStats: {
                                    ...this.state.teamStats,
                                    [teamName]: {
                                        ...this.state.teamStats[teamName],
                                        data: {
                                            goal: {
                                                [moment().format(
                                                    "MMMM"
                                                )]: parseFloat(goal),
                                                [moment()
                                                    .add(1, "month")
                                                    .format(
                                                        "MMMM"
                                                    )]: parseFloat(goalNext),
                                                [moment()
                                                    .subtract(1, "month")
                                                    .format(
                                                        "MMMM"
                                                    )]: parseFloat(goalLast)
                                            },
                                            leader: leader,
                                            members: members.map(
                                                val => val.value
                                            )
                                        }
                                    }
                                }
                            });
                        } else {
                            this.getData();
                        }
                        this.setState({
                            editingModal: false,
                            selectedTeam: teamName,
                            showModal: false,
                            teamName: "",
                            goal: "",
                            tempfiles: {},
                            leader: "",
                            members: [],
                            goalNext: "",
                            goalLast: ""
                        });
                    });
            }
        } else {
            throw new Error("You do not have permission!");
        }
    };

    uploadFiles = (file, team) => {
        return new Promise((resolve, reject) => {
            var pathReference = storage.ref("pubInsights/_" + team);
            pathReference
                .put(file)
                .then(function(snapshot) {
                    pathReference.getDownloadURL().then(function(url) {
                        resolve(url);
                    });
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    deleteTeam = teamName => {
        if (this.props.user.roles.includes("admin")) {
            return firestore
                .collection("publisherInsights")
                .doc("teams")
                .update({
                    [teamName]: firebase.firestore.FieldValue.delete()
                })
                .then(e => {
                    this.getData();
                    this.setState({
                        selectedTeam: "",
                        showAll: true,
                        showModal: false,
                        teamName: "",
                        goal: "",
                        goalNext: "",
                        leader: "",
                        members: []
                    });
                });
        } else {
            throw new Error("You do not have permission!");
        }
    };

    getData(customOption) {
        if (customOption) {
            this.setState({ customOption });
        } else {
            this.setState({ customOption: "" });
        }
        firestore
            .collection("publisherInsights")
            .doc("teams")
            .get()
            .then(teamData => {
                console.log(teamData.data());
                this.context.setState({ loading: true });
                let teams = teamData.data();
                let sorted = authorData =>
                    Object.entries(teams).reduce((acc, [teamname, data]) => {
                        acc[teamname] = data.members.reduce(
                            (accii, member) => {
                                if (!authorData[member]) {
                                    accii.notFound = [
                                        ...accii.notFound,
                                        member
                                    ];
                                } else {
                                    let authorRef = authorData[member];

                                    accii.memberStats = [
                                        ...accii.memberStats,
                                        authorRef
                                    ];
                                    accii.totalPageviewsNoLimit +=
                                        authorRef.totalPageviews;
                                    accii.totalPageviews +=
                                        authorRef.uniquePageviews;
                                    accii.totalArticles += Object.keys(
                                        authorRef.articles
                                    ).length;
                                    accii.articles = [
                                        ...accii.articles,
                                        ...Object.values(authorRef.articles)
                                    ];
                                }
                                accii.teamName = teamname;
                                accii.data = data;
                                return accii;
                            },
                            {
                                teamName: "",
                                totalPageviews: 0,
                                totalArticles: 0,
                                totalPageviewsNoLimit: 0,
                                articles: [],
                                notFound: [],
                                memberStats: []
                            }
                        );
                        return acc;
                    }, {});

                // Object.keys(teams).reduce(
                //     (acc, teamname) => {
                //         let team = teams[teamname];
                //         acc.teamData = team;

                //         let teamdata = team.members.forEach(name => {
                //             acc.memberStats[name] = authorData[name];
                //             acc.stats.pageviews +=
                //                 authorData[name].pageviews;
                //             acc.stats.revenue += authorData[name].revenue;
                //             acc.totalPageviews +=
                //                 authorData[name].uniquePageviews;
                //             acc.stats.articleCount += Object.keys(
                //                 authorData[name].articles
                //             ).length;
                //         });
                //         return acc;
                //     },
                //     {
                //         memberStats: {},
                //         stats: {
                //             pageviews: 0,
                //             revenue: 0,
                //             uniquePageviews: 0
                //         }
                //     }
                // );

                let d = new Date();
                let fetchOption = {
                    lastWeek: `/publisherinsightsapi?start=${moment()
                        .subtract(1, "week")
                        .format("YYYY-MM-DD")}&end=${moment().format(
                        "YYYY-MM-DD"
                    )}`,
                    lastMonth: `/publisherinsightsapi?start=${moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD")}&end=${moment()
                        .subtract(1, "month")
                        .endOf("month")
                        .format("YYYY-MM-DD")}`,
                    April: `/publisherinsightsapi?start=${moment()
                        .subtract(2, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD")}&end=${moment()
                        .subtract(2, "month")
                        .endOf("month")
                        .format("YYYY-MM-DD")}`,
                    March: `/publisherinsightsapi?start=${moment()
                        .subtract(3, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD")}&end=${moment()
                        .subtract(3, "month")
                        .endOf("month")
                        .format("YYYY-MM-DD")}`,
                    February: `/publisherinsightsapi?start=${moment()
                        .subtract(4, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD")}&end=${moment()
                        .subtract(4, "month")
                        .endOf("month")
                        .format("YYYY-MM-DD")}`,
                    January: `/publisherinsightsapi?start=${moment()
                        .subtract(5, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD")}&end=${moment()
                        .subtract(5, "month")
                        .endOf("month")
                        .format("YYYY-MM-DD")}`
                };

                let months = {
                    lastMonth: "May",
                    April: "April",
                    March: "March",
                    February: "February",
                    January: "January"
                };

                fetch(
                    customOption
                        ? fetchOption[customOption]
                        : `/publisherinsightsapi?start=${moment()
                              .startOf("month")
                              .format("YYYY-MM-DD")}&end=${moment().format(
                              "YYYY-MM-DD"
                          )}`
                )
                    .then(res => res.json())
                    .then(res => {
                        let authorData = res;
                        this.setState({
                            data: authorData,
                            teamStats: sorted(authorData),
                            currentMonth: customOption
                                ? months[customOption]
                                : moment().format("MMMM")
                        });
                        this.context.setState({ loading: false });
                        if (this.props.user.roles.includes("admin")) {
                            this.setState({
                                showAll: true
                            });
                        } else {
                            let x = Object.values(sorted(authorData)).filter(
                                team =>
                                    team.data.leader === this.props.user.email
                            );
                            if (x) {
                                this.setState({ selectedTeam: x[0].teamName });
                            } else {
                                this.setState({ error: true });
                            }
                        }
                    })
                    .catch(err => {
                        if (dev) {
                            // let authorData = fakeData;
                            // if (this.props.user.roles.includes("admin")) {
                            //     this.setState({
                            //         showAll: true
                            //     });
                            // } else {
                            //     let x = Object.values(
                            //         sorted(authorData)
                            //     ).filter(
                            //         team =>
                            //             team.data.leader ===
                            //             this.props.user.email
                            //     );
                            //     if (x) {
                            //         this.setState({
                            //             selectedTeam: x[0].teamName
                            //         });
                            //     } else {
                            //         this.setState({ error: true });
                            //     }
                            // }
                            // this.setState({
                            //     data: authorData,
                            //     teamStats: sorted(authorData),
                            //     currentMonth: customOption
                            //         ? moment()
                            //               .subtract(1, "month")
                            //               .format("MMMM")
                            //         : moment().format("MMMM")
                            // });
                            // this.context.setState({ loading: false });
                        } else {
                            console.log(err);
                            this.context.setState({
                                dialog: {
                                    title: "Not assigned.",
                                    msg: "You are not assigned to any team!"
                                }
                            });
                        }
                    });
            });
    }

    componentWillMount() {
        this.context.setState({ loading: true });
        this.getData();
    }

    handleChange = (field, e) =>
        this.setState({
            [field]: e.target.value
        });

    handleFiles = (files, key) => {
        this.setState({
            tempfiles: { ...this.state.tempfiles, [key]: [...files] }
        });
    };

    setTeam = team => this.setState({ selectedTeam: team, showAll: false });

    handleLeaderChange = leader => this.setState({ leader });
    handleMembersChange = members => this.setState({ members });

    render() {
        const {
            teamName,
            goal,
            goalNext,
            goalLast,
            leader,
            members,
            currentMonth
        } = this.state;
        return (
            !!this.state.teamStats && (
                <Wrapper>
                    <Dialog
                        open={this.state.showModal}
                        onClose={e => this.toggleModal()}
                    >
                        <DialogInside>
                            <h1 onClick={() => console.log(this.state)}>
                                Custom Teams
                            </h1>
                            <div className="input">
                                <StylishLabel htmlFor="teamName">
                                    Team Name
                                </StylishLabel>
                                <StylishInput
                                    onChange={e =>
                                        this.handleChange("teamName", e)
                                    }
                                    value={teamName}
                                    id="teamName"
                                    placeholder="Team Name"
                                />
                            </div>

                            <div className="goals">
                                <div className="input">
                                    <StylishLabel htmlFor="goalLast">
                                        Last Month's Goal
                                    </StylishLabel>
                                    <StylishInput
                                        minWidth="1.5rem"
                                        id="goalLast"
                                        onChange={e =>
                                            this.handleChange("goalLast", e)
                                        }
                                        value={goalLast}
                                        placeholder={`${moment()
                                            .subtract(1, "month")
                                            .format("MMMM")}'s Goal`}
                                        type="number"
                                    />
                                </div>
                                <div className="input">
                                    <StylishLabel htmlFor="goal">
                                        This Month's Goal
                                    </StylishLabel>
                                    <StylishInput
                                        id="goal"
                                        minWidth="1.5rem"
                                        onChange={e =>
                                            this.handleChange("goal", e)
                                        }
                                        value={goal}
                                        placeholder={`${moment().format(
                                            "MMMM"
                                        )}'s Goal`}
                                        type="number"
                                    />
                                </div>
                                <div className="input">
                                    <StylishLabel htmlFor="nextMonth">
                                        Next Month's Goal
                                    </StylishLabel>
                                    <StylishInput
                                        id="nextMonth"
                                        minWidth="1.5rem"
                                        onChange={e =>
                                            this.handleChange("goalNext", e)
                                        }
                                        value={goalNext}
                                        placeholder={`${moment()
                                            .add(1, "month")
                                            .format("MMMM")}'s Goal`}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <input
                                onChange={e => this.handleFiles(e.target.files)}
                                type="file"
                                accept="image/*"
                            />
                            <div className="input">
                                <StylishLabel htmlFor="email">
                                    Email
                                </StylishLabel>
                                <StylishInput
                                    id="email"
                                    type="email"
                                    onChange={e =>
                                        this.handleChange("leader", e)
                                    }
                                    placeholder="Team Leader Email"
                                    value={leader}
                                />
                            </div>

                            <Select
                                value={members}
                                onChange={this.handleMembersChange}
                                placeholder="Members"
                                isMulti
                                maxMenuHeight="200"
                                options={this.getOptions()}
                            />
                            <DialogButton
                                style={{ alignSelf: "center" }}
                                onClick={e => this.createTeam(this.state)}
                            >
                                Submit
                            </DialogButton>
                        </DialogInside>
                    </Dialog>
                    {!this.state.error && (
                        <Sidebar>
                            <h2>
                                <AccountGroup />
                                Teams
                            </h2>
                            <button
                                style={{ backgroundColor: "#333" }}
                                onClick={() => this.setState({ showAll: true })}
                            >
                                <h1>Team Overview</h1>
                            </button>
                            {Object.entries(this.state.teamStats).map(
                                ([name, team]) => (
                                    <button
                                        img={team.data.image}
                                        key={name}
                                        onClick={() =>
                                            this.setState({
                                                showAll: false,
                                                selectedTeam: name
                                            })
                                        }
                                    >
                                        {team.data.image ? (
                                            <>
                                                <p>{name}</p>
                                                <img
                                                    src={team.data.image}
                                                    alt="image"
                                                />
                                            </>
                                        ) : (
                                            name
                                        )}
                                    </button>
                                )
                            )}
                        </Sidebar>
                    )}
                    {this.state.showAll ? (
                        <TeamLeaderboard
                            currentMonth={currentMonth}
                            customOption={this.state.customOption}
                            getData={this.getData}
                            setTeam={this.setTeam}
                            data={this.state.teamStats}
                            user={this.props.user}
                            authorData={this.state.data}
                        />
                    ) : (
                        Object.entries(this.state.teamStats).map(
                            team =>
                                team[0] === this.state.selectedTeam && (
                                    <Body key={team[0]}>
                                        <header>
                                            <h2>
                                                {team[0]}{" "}
                                                {this.props.user.roles.includes(
                                                    "admin"
                                                ) && (
                                                    <>
                                                        <CircleButton
                                                            onClick={e => {
                                                                this.editTeam(
                                                                    team[0]
                                                                );
                                                            }}
                                                            bgColor="#bad"
                                                            style={{
                                                                marginLeft:
                                                                    "1rem"
                                                            }}
                                                            width="2.5rem"
                                                        >
                                                            Edit
                                                        </CircleButton>
                                                        <CircleButton
                                                            onClick={e => {
                                                                window.confirm(
                                                                    `Are you sure you want to delete the team "${
                                                                        team[0]
                                                                    }"?`
                                                                ) &&
                                                                    this.deleteTeam(
                                                                        team[0]
                                                                    );
                                                            }}
                                                            style={{
                                                                marginLeft:
                                                                    "1rem"
                                                            }}
                                                            width="2.5rem"
                                                        >
                                                            &#10005;
                                                        </CircleButton>
                                                    </>
                                                )}
                                            </h2>

                                            <div className="date">
                                                <button
                                                    disabled={
                                                        currentMonth ===
                                                        "January"
                                                    }
                                                    style={{
                                                        marginRight: "0.5rem",
                                                        borderBottom:
                                                            currentMonth ===
                                                            "January"
                                                                ? "1.5px solid purple"
                                                                : "none"
                                                    }}
                                                    onClick={() => {
                                                        this.getData("January");
                                                    }}
                                                >
                                                    January
                                                </button>
                                                <button
                                                    disabled={
                                                        currentMonth ===
                                                        "February"
                                                    }
                                                    style={{
                                                        marginRight: "0.5rem",
                                                        borderBottom:
                                                            currentMonth ===
                                                            "February"
                                                                ? "1.5px solid purple"
                                                                : "none"
                                                    }}
                                                    onClick={() => {
                                                        this.getData(
                                                            "February"
                                                        );
                                                    }}
                                                >
                                                    February
                                                </button>
                                                <button
                                                    disabled={
                                                        currentMonth === "March"
                                                    }
                                                    style={{
                                                        marginRight: "0.5rem",
                                                        borderBottom:
                                                            currentMonth ===
                                                            "March"
                                                                ? "1.5px solid purple"
                                                                : "none"
                                                    }}
                                                    onClick={() => {
                                                        this.getData("March");
                                                    }}
                                                >
                                                    March
                                                </button>
                                                <button
                                                    disabled={
                                                        currentMonth === "April"
                                                    }
                                                    style={{
                                                        marginRight: "0.5rem",
                                                        borderBottom:
                                                            currentMonth ===
                                                            "April"
                                                                ? "1.5px solid purple"
                                                                : "none"
                                                    }}
                                                    onClick={() => {
                                                        this.getData("April");
                                                    }}
                                                >
                                                    April
                                                </button>
                                                <button
                                                    disabled={
                                                        currentMonth ===
                                                        moment()
                                                            .subtract(
                                                                1,
                                                                "month"
                                                            )
                                                            .format("MMMM")
                                                    }
                                                    style={{
                                                        marginRight: "0.5rem",
                                                        borderBottom:
                                                            currentMonth ===
                                                            moment()
                                                                .subtract(
                                                                    1,
                                                                    "month"
                                                                )
                                                                .format("MMMM")
                                                                ? "1.5px solid purple"
                                                                : "none"
                                                    }}
                                                    onClick={() => {
                                                        this.getData(
                                                            "lastMonth"
                                                        );
                                                    }}
                                                >
                                                    May
                                                </button>
                                                <button
                                                    style={{
                                                        borderBottom:
                                                            currentMonth ===
                                                            moment().format(
                                                                "MMMM"
                                                            )
                                                                ? "1.5px solid purple"
                                                                : "none"
                                                    }}
                                                    disabled={
                                                        currentMonth ===
                                                        moment().format("MMMM")
                                                    }
                                                    onClick={() => {
                                                        this.getData();
                                                    }}
                                                >
                                                    {moment().format("MMMM")}
                                                </button>
                                            </div>
                                        </header>
                                        <div className="dashItems">
                                            {this.props.user.roles.includes(
                                                "admin"
                                            ) &&
                                                currentMonth !== "January" &&
                                                currentMonth !== "February" &&
                                                currentMonth !== "March" &&
                                                currentMonth !== "April" && (
                                                    <DashItem bgColor="#00b297">
                                                        <div className="goal">
                                                            <p>
                                                                Unique Pageviews
                                                                Goal
                                                            </p>
                                                            <h1>
                                                                {team[1].data.goal[
                                                                    currentMonth
                                                                ].toLocaleString() ||
                                                                    0}
                                                            </h1>
                                                        </div>
                                                    </DashItem>
                                                )}
                                            <DashItem bgColor="#0866c6">
                                                <div className="pageviews">
                                                    <p>
                                                        Current Unique Pageviews
                                                    </p>
                                                    <h1>
                                                        {team[1].totalPageviews.toLocaleString() ||
                                                            0}
                                                    </h1>
                                                </div>
                                            </DashItem>

                                            <DashItem bgColor="#a283e1">
                                                <div className="pageviews">
                                                    <p>
                                                        Total Unique Pageviews
                                                    </p>
                                                    <h1>
                                                        {team[1].totalPageviewsNoLimit.toLocaleString() ||
                                                            0}
                                                    </h1>
                                                </div>
                                            </DashItem>
                                            {this.props.user.roles.includes(
                                                "admin"
                                            ) &&
                                                this.state.currentMonth ===
                                                    moment().format("MMMM") && (
                                                    <DashItem bgColor="#b95e5e">
                                                        <div className="pageviews">
                                                            <p>Aiming to Hit</p>
                                                            <h1>
                                                                {(
                                                                    (Math.round(
                                                                        (team[1]
                                                                            .totalPageviews /
                                                                            new Date().getDate()) *
                                                                            moment()
                                                                                .endOf(
                                                                                    "month"
                                                                                )
                                                                                .format(
                                                                                    "DD"
                                                                                )
                                                                    ) /
                                                                        team[1]
                                                                            .data
                                                                            .goal[
                                                                            currentMonth
                                                                        ]) *
                                                                    100
                                                                ).toFixed(2)}
                                                                % of Goal
                                                            </h1>
                                                        </div>
                                                    </DashItem>
                                                )}
                                            <DashItem bgColor="#1d2939">
                                                <div className="articles">
                                                    <p>Articles</p>
                                                    <h1>
                                                        {team[1].totalArticles}
                                                    </h1>
                                                </div>
                                            </DashItem>
                                        </div>
                                        {this.props.user.roles.includes(
                                            "admin"
                                        ) &&
                                            currentMonth !== "January" &&
                                            currentMonth !== "February" &&
                                            currentMonth !== "March" &&
                                            currentMonth !== "April" && (
                                                <ProgressDash>
                                                    <p>
                                                        {team[1].totalPageviews.toLocaleString() ||
                                                            0}{" "}
                                                        /{" "}
                                                        {team[1].data.goal[
                                                            currentMonth
                                                        ].toLocaleString() ||
                                                            0}{" "}
                                                        Pageviews
                                                    </p>
                                                    <Progress
                                                        theme={{
                                                            active: {
                                                                trailColor:
                                                                    "rgba(19, 140, 228, 0.17)",
                                                                color:
                                                                    "rgba(19, 140, 228, 0.9)"
                                                            }
                                                        }}
                                                        percent={
                                                            (team[1]
                                                                .totalPageviews /
                                                                team[1].data
                                                                    .goal[
                                                                    currentMonth
                                                                ]) *
                                                                100 >
                                                            100
                                                                ? 100
                                                                : (
                                                                      (team[1]
                                                                          .totalPageviews /
                                                                          team[1]
                                                                              .data
                                                                              .goal[
                                                                              currentMonth
                                                                          ]) *
                                                                      100
                                                                  ).toFixed(2)
                                                        }
                                                    />
                                                </ProgressDash>
                                            )}
                                        <ChartDash>
                                            <TeamWrapper>
                                                <h2>Author Stats</h2>
                                                <TeamGraph data={team[1]} />
                                            </TeamWrapper>
                                            <TeamWrapper>
                                                <h2>Team Articles</h2>
                                                <TeamGraphArticles
                                                    data={team[1].memberStats}
                                                />
                                            </TeamWrapper>
                                        </ChartDash>
                                        <ChartDash>
                                            <TeamWrapper
                                                style={{
                                                    paddingBottom: "1rem"
                                                }}
                                            >
                                                <h2>
                                                    Average Unique Pageviews /
                                                    Author
                                                </h2>
                                                <LeaderboardPie
                                                    data={Object.values(
                                                        team[1].memberStats
                                                    ).reduce((acc, member) => {
                                                        if (
                                                            Object.values(
                                                                member.articles
                                                            ).length > 0 &&
                                                            member.uniquePageviews >
                                                                0
                                                        ) {
                                                            return (acc = [
                                                                ...acc,
                                                                {
                                                                    name:
                                                                        member.author,
                                                                    value: Math.round(
                                                                        member.uniquePageviews /
                                                                            Object.values(
                                                                                member.articles
                                                                            )
                                                                                .length
                                                                    )
                                                                }
                                                            ]);
                                                        }
                                                        return acc;
                                                    }, [])}
                                                />
                                            </TeamWrapper>
                                            <TeamWrapper
                                                style={{
                                                    paddingBottom: "1rem"
                                                }}
                                            >
                                                <h2>Daily Article Traffic</h2>
                                                <LeaderboardGraph
                                                    data={team[1]}
                                                />
                                            </TeamWrapper>
                                        </ChartDash>
                                        <TableDash>
                                            <TeamWrapper
                                                style={{ overflow: "auto" }}
                                            >
                                                <h2>Team Member Stats</h2>
                                                <TeamGraphTable
                                                    data={Object.entries(
                                                        this.state.data
                                                    )
                                                        .filter(([key, val]) =>
                                                            team[1].data.members.includes(
                                                                key
                                                            )
                                                        )
                                                        .reduce(
                                                            (obj, [k, v]) => ({
                                                                ...obj,
                                                                [k]: v
                                                            }),
                                                            {}
                                                        )}
                                                />
                                            </TeamWrapper>
                                        </TableDash>
                                        <TableDash>
                                            <TeamWrapper
                                                style={{ overflow: "auto" }}
                                            >
                                                <h2>Team Article Stats</h2>
                                                <TeamArticleTable
                                                    data={team[1].articles}
                                                />
                                            </TeamWrapper>
                                        </TableDash>
                                    </Body>
                                )
                        )
                    )}
                    {this.props.user.roles.includes("admin") && (
                        <FixedButton onClick={e => this.toggleModal()}>
                            <Plus />
                        </FixedButton>
                    )}
                    {this.props.user.roles.includes("admin") && (
                        <FixedButton
                            mobile
                            bgColor="#9491ff"
                            bottom="12vh"
                            onClick={e => this.setState({ showAll: true })}
                        >
                            <AccountGroup />
                        </FixedButton>
                    )}
                </Wrapper>
            )
        );
    }
}

export default PublisherInsights;
