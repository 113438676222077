import React from "react";

export default class DFPComponent extends React.Component {
    render() {
        return (
            <div className="reports-container">
                <div className="reports">
                    <div className="reports__components">
                        <div className="reports__header-bar">
                            <h1 className="reports__heading">
                                {this.props.report.campaignName.substr(0, 54)}
                                ...
                            </h1>
                        </div>
                        <div className="info-component">
                            <div className="reports__content">
                                <div className="reports__flex-area">
                                    <div className="reports__details">
                                        <div className="details__title-center">
                                            Campaign Dimensions
                                        </div>
                                        <div className="campaign-dimensions">
                                            {this.props.report.type.map(
                                                (val, i) => {
                                                    return this.props.report
                                                        .type[i].height > 1 ? (
                                                        <div
                                                            style={{
                                                                height:
                                                                    this.props
                                                                        .report
                                                                        .type[i]
                                                                        .height /
                                                                    2,
                                                                width:
                                                                    this.props
                                                                        .report
                                                                        .type[i]
                                                                        .width /
                                                                    2
                                                            }}
                                                            key={"dfp-" + i}
                                                            className="campaign-dimensions__item"
                                                        >
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        "left"
                                                                }}
                                                            >
                                                                {
                                                                    this.props
                                                                        .report
                                                                        .type[i]
                                                                        .height
                                                                }
                                                                x
                                                                {
                                                                    this.props
                                                                        .report
                                                                        .type[i]
                                                                        .width
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : null;
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div className="reports__breakdown">
                                        <div className="details__title-center">
                                            Total Stats
                                        </div>
                                        <ul className="breakdown__list">
                                            <li className="breakdown__item">
                                                <div className="breakdown__item--title">
                                                    Statistic
                                                </div>
                                                <div className="breakdown__item--title">
                                                    Amount
                                                </div>
                                            </li>
                                            <li className="breakdown__item">
                                                <div className="breakdown__item--item">
                                                    Clicks
                                                </div>
                                                <div className="breakdown__item--value">
                                                    {
                                                        this.props.report
                                                            .clicksDelivered
                                                    }
                                                </div>
                                            </li>
                                            <li className="breakdown__item">
                                                <div className="breakdown__item--item">
                                                    CTR
                                                </div>
                                                <div className="breakdown__item--value">
                                                    {this.props.report.ctr}
                                                </div>
                                            </li>
                                            <li className="breakdown__item">
                                                <div className="breakdown__item--item">
                                                    Impressions Booked
                                                </div>
                                                <div className="breakdown__item--value">
                                                    {
                                                        this.props.report
                                                            .impressionsBooked
                                                    }
                                                </div>
                                            </li>
                                            <li className="breakdown__item">
                                                <div className="breakdown__item--item">
                                                    Impressions Delivered
                                                </div>
                                                <div className="breakdown__item--value">
                                                    {
                                                        this.props.report
                                                            .impressionsDelivered
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
