import React from "react";
import { Consumer } from "../Context";
import firestore, { firebaseAuth } from "../firebase";
import { Redirect } from "react-router-dom";
import Tile from "../components/Tile";
import RequestPanel from "../components/RequestPanel";
import PreviewData from "../components/PreviewData";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

let styles = {
    title: {
        // borderTop: "5px solid #ff2634",
        fontFamily: "Oswald",
        fontSize: "2rem",
        textAlign: "left",
        color: "dark grey",
        minWidth: "50%",
        marginLeft: "2rem"
    },
    page: {
        margin: "10px 0px",
        width: "100%",
        maxWidth: "130rem"
    },
    button: {
        bottom: 20,
        right: 20,
        position: "fixed",
        zIndex: 2
    }
};

export default class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: false,
            hasError: false,
            showForm: false,
            admin: false,
            preview: null,
            filteredItems: false
        };
        this.setPreview = this.setPreview.bind(this);
    }
    filterList = event => {
        var updatedList = Object.keys(this.state.reports).map(key => {
            let data = this.state.reports[key];
            data.key = key;
            return data;
        });
        updatedList = updatedList
            .filter(function(item) {
                if (
                    item.form.projectName
                        .toLowerCase()
                        .search(event.target.value.toLowerCase()) !== -1
                ) {
                    return item;
                }
            })
            .reduce((acc, val) => {
                acc[val.key] = val;
                return acc;
            }, {});
        this.setState({ filteredItems: updatedList });
    };
    componentWillMount() {
        this.refreshData();
    }
    setPreview(state) {
        this.setState(state);
    }
    refreshData() {
        let populateReport = ref => {
            ref.get()
                .then(function(querySnapshot) {
                    if (!querySnapshot.empty) {
                        let accumulator = {};
                        querySnapshot.forEach(doc => {
                            accumulator[doc.id] = doc.data();
                        });
                        that.setState({
                            reports: accumulator,
                            filteredItems: accumulator
                        });
                    } else {
                        that.setState({ reports: "no-reports" });
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        hasError: true
                    });
                });
        };

        let that = this;
        let user = this.props.user;
        if (this.props.admin) {
            that.setState({
                admin: true
            });
            populateReport(firestore.collection("campaign-reports"));
        } else {
            firestore
                .collection("campaign-reports")
                .where("form.owners", "array-contains", user.email)
                .get()
                .then(function(querySnapshot) {
                    firestore
                        .collection("campaign-reports")
                        .where("form.viewers", "array-contains", user.email)
                        .get()
                        .then(function(qs) {
                            let accumulator = {};

                            if (!qs.empty) {
                                qs.forEach(doc => {
                                    accumulator[doc.id] = doc.data();
                                });
                            }
                            if (!querySnapshot.empty) {
                                querySnapshot.forEach(doc => {
                                    accumulator[doc.id] = doc.data();
                                });

                                that.setState({
                                    reports: accumulator,
                                    filteredItems: accumulator
                                });
                            }
                            if (Object.keys(accumulator).length === 0) {
                                that.setState({
                                    reports: "no-reports"
                                });
                            } else {
                                that.setState({
                                    reports: accumulator,
                                    filteredItems: accumulator
                                });
                            }
                        });
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        hasError: true
                    });
                });
        }
    }

    render() {
        let from = this.props.location.state
            ? this.props.location.state.from.pathway
            : "/";
        return (
            <Consumer>
                {context => {
                    let { reports, filteredItems } = this.state;
                    return !this.hasError ? (
                        <div style={styles.page}>
                            {this.state.admin && this.props.authenticated && (
                                <RequestPanel />
                            )}
                            {this.state.preview && (
                                <PreviewData
                                    setPreview={this.setPreview}
                                    refreshData={this.refreshData}
                                    report={this.state.preview}
                                />
                            )}

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    padding: "2rem"
                                }}
                            >
                                <h2 style={styles.title}>
                                    {"Browse Reports".toUpperCase()}
                                </h2>
                                <form style={{ minWidth: "40%" }}>
                                    <fieldset
                                        style={{ border: "none", padding: 5 }}
                                    >
                                        <input
                                            type="text"
                                            style={{
                                                width: "100%",
                                                fontSize: "2rem",
                                                padding: 5,
                                                background:
                                                    "rgba(72, 0, 189, 0.02)"
                                            }}
                                            placeholder="Filter"
                                            onChange={this.filterList}
                                        />
                                    </fieldset>
                                </form>
                            </div>
                            {reports !== "no-reports" ? (
                                <div className="reports-page__report-container">
                                    {filteredItems &&
                                        Object.keys(filteredItems)
                                            .reverse()
                                            .map(report => (
                                                <Tile
                                                    id={report}
                                                    key={report}
                                                    report={reports[report]}
                                                />
                                            ))}
                                </div>
                            ) : (
                                <div className="no-reports">
                                    <div className="no-reports__container">
                                        <div className="no-reports__left-icon">
                                            <ErrorOutline
                                                style={{ fontSize: "34px" }}
                                            />
                                        </div>
                                        <div className="no-reports__text-content">
                                            <div className="no-reports__text">
                                                You currently have no reports
                                                available to view.
                                            </div>
                                            <div className="no-reports__sub-text">
                                                Please contact your Narcity
                                                Content Manager to gain access.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Redirect to={from} />
                    );
                }}
            </Consumer>
        );
    }
}
