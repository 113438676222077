import React from "react";
import { Chip, Modal, IconButton } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import RevenueMetricsGraph from "../features/RevenueMetricsGraph";
import { Consumer } from "../Context";
import Button from "../components/styled/Button";
import {
    CalendarSearch,
    ChevronUp,
    ChevronDown,
    ArrowCollapseVertical,
    ArrowExpandVertical,
    ArrowRight,
    ArrowLeft,
    Magnify,
    ChartAreaspline,
    History
} from "mdi-material-ui";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
let styles = {
    container: {
        height: "calc(100% + 100px)",
        display: "flex",
        width: "100%",
        flexDirection: "column"
    },
    tiles: {
        margin: 10
    }
};
let dev = false;

export default class RevenueInsights extends React.Component {
    static contextType = Consumer;

    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.state = {
            redirect: false,
            dateSearched: false,
            initialData: null,
            open: false,
            filterInput: "",
            name: [],
            from: null,
            to: null,
            enteredTo: null, // Keep track of the last day for mouseEnter.
            data: null,
            sortByHighest: true,
            sortField: "revenue",
            fieldsCollapsed: false,
            currentPage: 0,
            paginatedAmount: 20,
            filtering: false,
            graphModalOpen: false,
            ampFilter: false,
            metricFilterValues: {
                defaultInput: "",
                optionalInput: "",
                selectValue: "<",
                metricSelectValue: "revenue",
                activeFilters: []
            }
        };
    }

    //Date Formatter
    formatter = d => {
        //YYYY-MM-DD
        let date = new Date(d);
        return (
            date.getFullYear() +
            `-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
                "0" + date.getDate()
            ).slice(-2)}`
        );
    };

    componentWillMount() {
        // //UNCOMMENT FOR PROD
        let date = new Date();
        let yesterday = date.setDate(date.getDate() - 1);
        this.fetchData(this.formatter(yesterday), this.formatter(yesterday));
    }

    setInitialData = data => {
        this.setState({ initialData: data });
    };

    convertNanoDollars = nano => {
        return nano / 1000000;
    };

    filterList = event => {
        this.setState({ filterInput: event.target.value });
        if (event.target.value.length >= 1 || event.target.value.length === 0) {
            this.setState({
                currentPage: 0,
                filtering: true,
                numberFilterShow: false,
                metricFilterValues: {
                    defaultInput: "",
                    optionalInput: "",
                    selectValue: "<",
                    metricSelectValue: "revenue",
                    activeFilters: []
                }
            });
            let updatedList = Object.keys(this.state.data).map(id => {
                let data = this.state.data[id];
                data.id = id;
                return data;
            });
            updatedList = updatedList
                .filter(function(item) {
                    if (
                        (item.key &&
                            item.key.search(event.target.value) !== -1) ||
                        (item.author &&
                            item.author
                                .toLowerCase()
                                .search(event.target.value.toLowerCase()) !==
                                -1) ||
                        (item.headline &&
                            item.headline
                                .toLowerCase()
                                .search(event.target.value.toLowerCase()) !==
                                -1)
                    ) {
                        return item;
                    }
                })
                .reduce((acc, val) => {
                    acc[val.id] = val;
                    return acc;
                }, []);
            this.setState({ data: updatedList, paginatedAmount: 300 });
        } else {
            let initialData = this.state.initialData;
            this.setState({
                data: initialData,
                paginatedAmount: 20,
                filtering: false
            });
        }
    };

    fetchClick = () => {
        console.log(this.state);
    };

    fetchData = (start, end, dev) => {
        //Reset filters
        // this.fetchData("2018-11-20", "2018-11-30");
        this.setState({
            dateSearched: true,
            filterInput: "",
            currentPage: 0,
            sortByHighest: true,
            sortField: "revenue",
            metricFilterValues: {
                defaultInput: "",
                optionalInput: "",
                selectValue: "<",
                metricSelectValue: "revenue",
                activeFilters: [],
                ampFilter: false
            }
        });
        this.context.setState({ loading: true });
        let date = new Date(end);
        let addone = date.setDate(date.getDate() + 2);
        fetch(`/getrevenue?start=${start}&end=${this.formatter(addone)}`)
            .then(res => res.json())
            .then(res => {
                console.log(res);
                shapeData(res);
            })
            .catch(err => {
                console.log(err);
            });
        let shapeData = res => {
            let data = Object.values(
                Object.keys(res).reduce((acc, k) => {
                    let val = res[k];
                    Object.values(val).map(o => {
                        let popRev = u => {
                            let revenue = 0;
                            let impressions = 0;
                            if (u.hasOwnProperty("AD_EXCHANGE")) {
                                revenue += parseInt(u["AD_EXCHANGE"].revenue);
                                impressions += parseInt(
                                    u["AD_EXCHANGE"].impressions
                                );
                            }
                            if (u.hasOwnProperty("PRICE_PRIORITY")) {
                                revenue += parseInt(
                                    u["PRICE_PRIORITY"].revenue
                                );
                                impressions += parseInt(
                                    u["PRICE_PRIORITY"].impressions
                                );
                            }

                            const {
                                AD_EXCHANGE,
                                PRICE_PRIORITY,
                                ...noBreakdown
                            } = u;
                            const data = {
                                ...noBreakdown,
                                revenue: revenue,
                                impressions: impressions
                            };
                            data.history = data.history || {};
                            data.history[k] = {
                                revenue: revenue,
                                impressions: impressions,
                                pageviews: u.pageviews,
                                sessions: u.sessions,
                                uniquePageviews: u.uniquePageviews
                            };
                            return data;
                        };
                        let x = popRev(o);
                        let add = () => {
                            if (!!x.sessions) {
                                !!acc[x.key].sessions
                                    ? (acc[x.key].sessions += x.sessions)
                                    : (acc[x.key].sessions = x.sessions);
                            }
                            if (!!x.uniquePageviews) {
                                !!acc[x.key].uniquePageviews
                                    ? (acc[x.key].uniquePageviews +=
                                          x.uniquePageviews)
                                    : (acc[x.key].uniquePageviews =
                                          x.uniquePageviews);
                            }
                            if (!!x.pageviews) {
                                !!acc[x.key].pageviews
                                    ? (acc[x.key].pageviews += x.pageviews)
                                    : (acc[x.key].pageviews = x.pageviews);
                            }
                            acc[x.key].revenue = acc[x.key].revenue || 0;

                            acc[x.key].revenue += parseInt(x.revenue);
                            acc[x.key].impressions =
                                acc[x.key].impressions || 0;
                            acc[x.key].impressions += parseInt(x.impressions);
                            acc[x.key].history[k] = {
                                revenue: parseInt(x.revenue),
                                impressions: parseInt(x.impressions),
                                pageviews: x.pageviews,
                                sessions: x.sessions,
                                uniquePageviews: x.uniquePageviews
                            };
                        };

                        acc.hasOwnProperty(x.key) ? add() : (acc[x.key] = x);
                    });

                    return acc;
                }, {})
            );
            let sortedData = data.sort((a, b) => {
                return a.revenue < b.revenue ? 1 : -1;
            });
            this.setState({ data: sortedData });
            this.setInitialData(sortedData);
            this.sortFieldByMetric("add");
            this.context.setState({ loading: false });
        };
    };
    handleClear = () => {
        this.setState({ data: this.state.initialData });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    collapseField = () => {
        if (this.state.fieldsCollapsed === false) {
            this.setState({ fieldsCollapsed: true });
            let table = document.querySelector(".revenue-table");
            let el = table.querySelectorAll("td, th");
            for (let i = 0; i < el.length; i++) {
                el[i].classList.add("table-collapse");
            }
        } else if (this.state.fieldsCollapsed === true) {
            this.setState({ fieldsCollapsed: false });
            let table = document.querySelector(".revenue-table");
            let el = table.querySelectorAll("td, th");
            for (let i = 0; i < el.length; i++) {
                el[i].classList.remove("table-collapse");
            }
        }
    };

    handleDelete = data => {
        let index = this.state.metricFilterValues.activeFilters
            .map(x => {
                return x.metricSelectValue;
            })
            .indexOf(data.metricSelectValue);
        let activeFilters = this.state.metricFilterValues.activeFilters;
        activeFilters.splice(index, 1);
        this.setState(
            {
                metricFilterValues: {
                    ...this.state.metricFilterValues,
                    activeFilters
                }
            },
            this.sortFieldByMetric("del")
        );
    };

    handleClickFilter = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseFilter = () => {
        this.setState({ anchorEl: null });
    };

    handleChangeSelect = event => {
        this.addChip(event.target.value);
    };

    handleResetClick() {
        this.setState({ from: null, to: null, enteredTo: null });
    }

    handleModalToggle = data => {
        this.setState(prevState => ({
            graphModalOpen: !prevState.graphModalOpen,
            modalCurrentData: data.history
        }));
    };

    addFilter() {
        let {
            selectValue,
            metricSelectValue,
            defaultInput,
            optionalInput
        } = this.state.metricFilterValues;
        let filterExists = false;
        this.state.metricFilterValues.activeFilters.map((val, i) => {
            if (val.metricSelectValue === metricSelectValue) {
                filterExists = true;
            }
        });
        if (!filterExists) {
            this.setState(
                {
                    currentPage: 0,
                    metricFilterValues: {
                        ...this.state.metricFilterValues,
                        activeFilters: [
                            ...this.state.metricFilterValues.activeFilters,
                            {
                                metricSelectValue,
                                selectValue,
                                defaultInput,
                                optionalInput
                            }
                        ]
                    }
                },
                () => this.sortFieldByMetric("add")
            );
        } else {
            alert("Filter metric already exists!");
        }
    }

    sortFieldByMetric = action => {
        let { activeFilters } = this.state.metricFilterValues;
        let filter = data => {
            activeFilters.map(val => {
                let filtered = data.filter(a => {
                    if (val.selectValue === ">") {
                        if (val.metricSelectValue === "revenue") {
                            return (
                                this.convertNanoDollars(a.revenue) <
                                val.defaultInput
                            );
                        } else if (
                            val.metricSelectValue === "impressions" ||
                            val.metricSelectValue === "sessions" ||
                            val.metricSelectValue === "pageviews"
                        ) {
                            return a[val.metricSelectValue] < val.defaultInput;
                        } else if (val.metricSelectValue === "pps") {
                            return a.pageviews / a.sessions < val.defaultInput;
                        } else if (val.metricSelectValue === "rpm") {
                            return (
                                a.revenue / a.impressions / 1000 <
                                val.defaultInput
                            );
                        } else if (val.metricSelectValue === "revenuelps") {
                            return (
                                this.convertNanoDollars(a.revenue) /
                                    a.sessions <
                                val.defaultInput
                            );
                        } else if (val.metricSelectValue === "impsessions") {
                            return (
                                a.impressions / a.sessions < val.defaultInput
                            );
                        }
                    } else if (val.selectValue === "<") {
                        if (val.metricSelectValue === "revenue") {
                            return (
                                this.convertNanoDollars(a.revenue) >
                                val.defaultInput
                            );
                        } else if (
                            val.metricSelectValue === "impressions" ||
                            val.metricSelectValue === "sessions" ||
                            val.metricSelectValue === "pageviews"
                        ) {
                            return a[val.metricSelectValue] > val.defaultInput;
                        } else if (val.metricSelectValue === "pps") {
                            return a.pageviews / a.sessions > val.defaultInput;
                        } else if (val.metricSelectValue === "rpm") {
                            return (
                                a.revenue / a.impressions / 1000 >
                                val.defaultInput
                            );
                        } else if (val.metricSelectValue === "revenuelps") {
                            return (
                                this.convertNanoDollars(a.revenue) /
                                    a.sessions >
                                val.defaultInput
                            );
                        } else if (val.metricSelectValue === "impsessions") {
                            return (
                                a.impressions / a.sessions > val.defaultInput
                            );
                        }
                    } else if (val.selectValue === "between") {
                        if (val.metricSelectValue === "revenue") {
                            return (
                                this.convertNanoDollars(a.revenue) <
                                    val.defaultInput &&
                                this.convertNanoDollars(a.revenue) >
                                    val.optionalInput
                            );
                        } else if (
                            val.metricSelectValue === "impressions" ||
                            val.metricSelectValue === "sessions" ||
                            val.metricSelectValue === "pageviews"
                        ) {
                            return (
                                a[val.metricSelectValue] < val.defaultInput &&
                                a[val.metricSelectValue] > val.optionalInput
                            );
                        } else if (val.metricSelectValue === "pps") {
                            return (
                                a.pageviews / a.sessions < val.defaultInput &&
                                a.pageviews / a.sessions > val.optionalInput
                            );
                        } else if (val.metricSelectValue === "rpm") {
                            return (
                                a.revenue / a.impressions / 1000 <
                                    val.defaultInput &&
                                a.revenue / a.impressions / 1000 >
                                    val.optionalInput
                            );
                        } else if (val.metricSelectValue === "revenuelps") {
                            return (
                                this.convertNanoDollars(a.revenue) /
                                    a.sessions <
                                    val.defaultInput &&
                                this.convertNanoDollars(a.revenue) /
                                    a.sessions >
                                    val.optionalInput
                            );
                        } else if (val.metricSelectValue === "impsessions") {
                            return (
                                a.impressions / a.sessions < val.defaultInput &&
                                a.impressions / a.sessions > val.optionalInput
                            );
                        }
                    }
                });
                this.setState({
                    data: filtered
                });
            });
        };
        if (action === "add") {
            let data = this.state.data;
            filter(data);
        } else if (action === "del") {
            this.setState({ data: this.state.initialData });
            let data = this.state.initialData;
            filter(data);
        }
    };

    //FILTERING
    //Field = Revenue, Impressions
    sortField = field => {
        //Click on one... sort
        //Click on same one... reverse\
        //Click on different one... -> sort original data then set to data state
        //click on same one... reverse again
        let newData = this.state.data;

        if (this.state.sortField === field) {
            this.setState(prevState => ({
                sortByHighest: !prevState.sortByHighest
            }));
            newData.reverse();
        } else {
            let newData = this.state.initialData;
            if (
                this.state.metricFilterValues.defaultInput !== "" ||
                this.state.filtering
            ) {
                newData = this.state.data;
            }
            this.setState({ data: newData, currentPage: 0 });
            if (this.state.sortByHighest === false) {
                newData.reverse();
                this.setState({ sortByHighest: true });
            }
            this.setState({ sortField: field });
            if (field === "pageviews") {
                let filtered = newData.filter(a => {
                    if (a.pageviews !== undefined) {
                        return true;
                    } else {
                        return false;
                    }
                });
                filtered.sort((a, b) => {
                    return a.pageviews > b.pageviews ? -1 : 1;
                });
                this.setState({ data: filtered });
            } else if (field === "revenue") {
                let filtered = newData.filter(a => {
                    if (a.revenue !== undefined) {
                        return true;
                    } else {
                        return false;
                    }
                });
                filtered.sort((a, b) => {
                    return a.revenue < b.revenue ? 1 : -1;
                });
                this.setState({ data: filtered });
            } else if (field === "revenuepv") {
                let filtered = newData.filter(a => {
                    if (a.revenue !== undefined && a.sessions !== undefined) {
                        return true;
                    } else {
                        return false;
                    }
                });
                filtered.sort((a, b) => {
                    return b.revenue / b.sessions - a.revenue / a.sessions;
                });
                this.setState({ data: filtered });
            } else if (field === "impsessions") {
                let filtered = newData.filter(a => {
                    if (
                        a.impressions !== undefined &&
                        a.sessions !== undefined
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                });
                filtered.sort((a, b) => {
                    return (
                        b.impressions / b.sessions - a.impressions / a.sessions
                    );
                });
                this.setState({ data: filtered });
            } else if (field === "impressionspv") {
                let filtered = newData.filter(a => {
                    if (
                        a.impressions !== undefined &&
                        a.pageviews !== undefined
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                });
                filtered.sort((a, b) => {
                    return a.impressions / a.pageviews >
                        b.impressions / b.pageviews
                        ? -1
                        : 1;
                });
                this.setState({ data: filtered });
            } else if (field === "sessions") {
                let filtered = newData.filter(a => {
                    if (a.sessions !== undefined) {
                        return true;
                    } else {
                        return false;
                    }
                });
                filtered.sort((a, b) => {
                    return a.sessions > b.sessions ? -1 : 1;
                });
                this.setState({ data: filtered });
            } else if (field === "pps") {
                let filtered = newData.filter(a => {
                    if (a.pageviews !== undefined && a.sessions !== undefined) {
                        return true;
                    } else {
                        return false;
                    }
                });
                filtered.sort((a, b) => {
                    return a.pageviews / a.sessions > b.pageviews / b.sessions
                        ? -1
                        : 1;
                });
                this.setState({ data: filtered });
            } else if (field === "pubdate") {
                newData.sort((a, b) => {
                    let dateA = new Date(a.publishedDate).getTime();
                    let dateB = new Date(b.publishedDate).getTime();
                    return dateA > dateB ? -1 : 1;
                });
            } else if (field === "RPM") {
                let filtered = newData.filter(a => {
                    if (
                        a.revenue !== undefined &&
                        a.impressions !== undefined
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                });
                filtered.sort((a, b) => {
                    return a.revenue / a.impressions > b.revenue / b.impressions
                        ? -1
                        : 1;
                });
                this.setState({ data: filtered });
            }
        }
    };

    //FILTERING DONE

    // addChip = filter => {
    //     let match = false;
    //     this.state.chipData.map((val, i) => {
    //         if (val.label === filter) {
    //             match = true;
    //         }
    //     });
    //     if (filter.length > 0 && match === false) {
    //         if (filter.indexOf(",") > -1) {
    //             let keywords = filter.split(",");
    //             keywords.forEach((val, i) => {
    //                 this.setState(previous => ({
    //                     chipData: [
    //                         ...previous.chipData,
    //                         { key: `${val}--${i}`, label: val }
    //                     ]
    //                 }));
    //             });
    //         } else {
    //             this.setState({
    //                 chipData: [
    //                     ...this.state.chipData,
    //                     { key: `${filter}--${Math.random(100)}`, label: filter }
    //                 ]
    //             });
    //         }
    //     } else {
    //         alert("Invalid Field");
    //     }
    // };

    changePagination = amount => {
        this.setState({ currentPage: 0, paginatedAmount: amount });
    };

    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.addChip(this.state.filterInput);
            this.setState({ filterInput: "" });
        }
    }

    handleFilterChange(event) {
        this.setState({
            filterInput: event.target.value
        });
    }

    isSelectingFirstDay(from, to, day) {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    handleDayClick(day) {
        const { from, to } = this.state;
        if (from && to && day >= from && day <= to) {
            this.handleResetClick();
            return;
        }
        if (this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                from: day,
                to: null,
                enteredTo: null
            });
        } else {
            this.setState({
                to: day,
                enteredTo: day
            });
        }
    }
    handleDayMouseEnter(day) {
        const { from, to } = this.state;
        if (!this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                enteredTo: day
            });
        }
    }

    previousPage = () => {
        if (this.state.currentPage !== 0)
            this.setState(prevState => ({
                currentPage: prevState.currentPage - 1
            }));
    };

    nextPage = () => {
        if (
            this.state.currentPage + 1 * this.state.paginatedAmount <
            this.state.data.length
        ) {
            this.setState(prevState => ({
                currentPage: prevState.currentPage + 1
            }));
        }
    };

    handleFilterReset() {
        let data = this.state.initialData;
        this.setState({
            data,
            filterInput: "",
            metricFilterValues: {
                defaultInput: "",
                optionalInput: "",
                selectValue: "<",
                metricSelectValue: "revenue",
                activeFilters: [],
                ampFilter: false
            }
        });
    }

    render() {
        const { from, to, enteredTo, paginatedAmount } = this.state;
        const modifiers = { start: from, end: enteredTo };
        const disabledDays = {
            before: new Date("2018-12-09"),
            after: new Date(new Date().setDate(new Date().getDate() - 1))
        };
        const selectedDays = [from, { from, to: enteredTo }];
        let pageStart = this.state.currentPage * paginatedAmount;
        let pageEnd =
            this.state.currentPage * paginatedAmount + paginatedAmount;
        const names = [
            "Facebook",
            "Google News",
            "Google Search",
            "Instagram Stories",
            "Taboola",
            "Desktop",
            "Mobile",
            "Mobile App",
            "AMP"
        ];

        let totals = {
            revenue: 0,
            revpv: 0,
            pageviews: 0,
            impspv: 0,
            sessions: 0,
            impressions: 0,
            pps: 0,
            impsessions: 0,
            rpm: 0
        };

        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to="/trafficinsights" />;
        }

        return (
            <div style={styles.container}>
                <div className="revenue-insights-header">
                    {/* <div className="revenue-insights-title">
                        Revenue Insights
                    </div> */}
                    <div className="revenue-insights-left">
                        <div className="revenue-insights-calendar">
                            <div className="revenue-insights-datePicker">
                                <>
                                    {
                                        <DayPickerInput
                                            hideOnDayClick={
                                                this.state.to === null
                                                    ? false
                                                    : true
                                            }
                                            dayPickerProps={{
                                                selectedDays: selectedDays,
                                                numberOfMonths: 1,
                                                className: "Range",
                                                disabledDays: disabledDays,
                                                onDayClick: this.handleDayClick,
                                                onDayMouseEnter: this
                                                    .handleDayMouseEnter,
                                                modifiers: modifiers
                                            }}
                                            component={props => (
                                                <>
                                                    <input
                                                        className="revenue-insights-datePicker__picker"
                                                        {...props}
                                                        readOnly={true}
                                                    />
                                                </>
                                            )}
                                            placeholder={this.formatter(
                                                new Date().setDate(
                                                    new Date().getDate() - 1
                                                )
                                            )}
                                            format="DD/MM/YYYY"
                                            value={
                                                from && to !== null
                                                    ? `${from.toLocaleDateString()} - ${to.toLocaleDateString()}`
                                                    : this.formatter(
                                                          new Date().setDate(
                                                              new Date().getDate() -
                                                                  1
                                                          )
                                                      )
                                            }
                                        />
                                    }
                                </>
                            </div>
                            <div
                                onClick={e =>
                                    this.state.from &&
                                    this.fetchData(
                                        this.formatter(this.state.from),
                                        !!this.state.to
                                            ? this.formatter(this.state.to)
                                            : this.formatter(this.state.from)
                                    )
                                }
                                className="revenue-insights-goButton"
                            >
                                <span className="revenue-insights-goButton__text">
                                    Go
                                </span>
                                <CalendarSearch color="primary" />
                            </div>
                        </div>
                    </div>
                    <div className="revenue-insights-right">
                        <div className="revenue-insights-filter">
                            <input
                                onChange={this.filterList}
                                placeholder="search"
                                type="text"
                                value={this.state.filterInput}
                                className="revenue-insights-filter__search"
                            />
                        </div>
                        <div
                            onClick={e => this.collapseField()}
                            className="revenue-insights-stackedButton"
                        >
                            {!!this.state.fieldsCollapsed ? (
                                <ArrowExpandVertical color="primary" />
                            ) : (
                                <ArrowCollapseVertical color="primary" />
                            )}
                        </div>
                    </div>
                </div>

                <div className="revenue-insights-subheader">
                    <div className="numberFilter">
                        <select
                            value={
                                this.state.metricFilterValues.metricSelectValue
                            }
                            onChange={e => {
                                this.setState({
                                    metricFilterValues: {
                                        ...this.state.metricFilterValues,
                                        metricSelectValue: e.target.value
                                    }
                                });
                            }}
                            name="numberFilterSelect"
                            className="revenue-insights-numberFilter__select"
                        >
                            <option value="revenue">Revenue</option>
                            <option value="sessions">Sessions</option>
                            <option value="impressions">Impressions</option>
                            <option value="pageviews">Pageviews</option>
                            <option value="revenuelps">Revenue/LPS</option>
                            <option value="pps">PPS</option>
                            <option value="rpm">RPM</option>
                            <option value="impsessions">Imps/Session</option>
                        </select>
                        <select
                            value={this.state.metricFilterValues.selectValue}
                            onChange={e => {
                                this.setState({
                                    metricFilterValues: {
                                        ...this.state.metricFilterValues,
                                        selectValue: e.target.value
                                    }
                                });
                            }}
                            name="numberFilterSelect"
                            className="revenue-insights-numberFilter__select"
                        >
                            <option value=">">smaller than</option>
                            <option value="<">bigger than</option>
                            <option value="between">between</option>
                        </select>
                        {this.state.metricFilterValues.selectValue ===
                            "between" && (
                            <input
                                value={
                                    this.state.metricFilterValues.optionalInput
                                }
                                onChange={e => {
                                    this.setState({
                                        metricFilterValues: {
                                            ...this.state.metricFilterValues,
                                            optionalInput: e.target.value
                                        }
                                    });
                                }}
                                style={{ marginRight: "0.5rem" }}
                                placeholder="from"
                                id="numberFilterOptional"
                                type="number"
                                className="revenue-insights-numberFilter"
                            />
                        )}
                        <input
                            value={this.state.metricFilterValues.defaultInput}
                            onChange={e => {
                                this.setState({
                                    metricFilterValues: {
                                        ...this.state.metricFilterValues,
                                        defaultInput: e.target.value
                                    }
                                });
                            }}
                            placeholder="number"
                            id="numberFilterDefault"
                            type="number"
                            className="revenue-insights-numberFilter"
                        />
                        <div
                            style={{ marginRight: "1rem" }}
                            className="revenue-insights-goButton"
                            onClick={e => this.addFilter()}
                        >
                            <span className="revenue-insights-goButton__text">
                                Filter
                            </span>
                            <Magnify color="primary" />
                        </div>
                        <Button
                            width="12rem"
                            style={{ marginLeft: "0" }}
                            onClick={e =>
                                this.setState(prevState => ({
                                    ampFilter: !prevState.ampFilter
                                }))
                            }
                        >
                            <div className="button-text">
                                {this.state.ampFilter ? "Hiding" : "Showing"}{" "}
                                AMP
                            </div>
                        </Button>
                        {this.state.metricFilterValues.activeFilters.length >
                            0 ||
                            (this.state.filterInput.length > 0 && (
                                <div
                                    style={{ marginLeft: "1rem" }}
                                    onClick={e => this.handleFilterReset()}
                                    className="revenue-insights-numberFilterButton"
                                >
                                    Reset
                                </div>
                            ))}
                    </div>
                </div>
                {this.state.metricFilterValues.activeFilters.length > 0 && (
                    <div className="revenue-insights-chipArray">
                        {this.state.metricFilterValues.activeFilters.map(
                            (data, i) => {
                                let icon = null;

                                return (
                                    <Chip
                                        key={i}
                                        icon={icon}
                                        label={
                                            data.selectValue === "between"
                                                ? `${
                                                      data.metricSelectValue
                                                  } | ${data.selectValue} ${
                                                      data.optionalInput
                                                  } & ${data.defaultInput}`
                                                : `${data.defaultInput} ${
                                                      data.selectValue
                                                  } ${data.metricSelectValue}`
                                        }
                                        onDelete={e => this.handleDelete(data)}
                                        style={{
                                            fontSize: "1rem",
                                            marginRight: ".5rem"
                                        }}
                                    />
                                );
                            }
                        )}
                    </div>
                )}

                {/* {this.state.chipData.length > 0 && (
                    <div className="revenue-insights-subheader">
                        <div className="numberFilter">
                            <select className="revenue-insights-NumberFilter__select">
                                Select
                            </select>
                            <input
                                type="text"
                                className="revenue-insights-numberFilter numberFilter-input"
                            />
                            <div className="revenue-insights-numberFilterButton">
                                Filter
                            </div>
                        </div>{" "}
                    </div>
                )}{" "} */}
                <div className="revenue-insights">
                    <table className="revenue-table sortable searchable">
                        <thead>
                            <tr className="rev-table-head">
                                {/* <th className="rev-table-head__heading revenue-table__image">
                                    &nbsp;
                                </th> */}
                                <td className="rev-table-head__heading revenue-table__id">
                                    ID
                                </td>
                                <td className="rev-table-head__heading revenue-table__chart">
                                    &nbsp;
                                </td>
                                <th className="rev-table-head__heading revenue-table__article">
                                    Landing Page
                                </th>
                                <th className="rev-table-head__heading rev-table-head__heading--sortable">
                                    <div className="revenue-table-head table-head__revenue">
                                        <div
                                            className="header-items"
                                            onClick={e =>
                                                this.sortField("revenue")
                                            }
                                        >
                                            Revenue
                                            {this.state.sortField ===
                                                "revenue" &&
                                                this.state.sortByHighest !==
                                                    null &&
                                                (this.state.sortByHighest ===
                                                true ? (
                                                    <ChevronUp />
                                                ) : (
                                                    <ChevronDown />
                                                ))}
                                        </div>
                                    </div>
                                </th>
                                <th className="rev-table-head__heading rev-table-head__heading--sortable revenue-table__rpmpv">
                                    <div
                                        onClick={e =>
                                            this.sortField("revenuepv")
                                        }
                                        className="revenue-table-head table-head__revenuepv"
                                    >
                                        Revenue/LPS
                                        {this.state.sortField === "revenuepv" &&
                                            this.state.sortByHighest !== null &&
                                            (this.state.sortByHighest ===
                                            true ? (
                                                <ChevronUp />
                                            ) : (
                                                <ChevronDown />
                                            ))}
                                    </div>
                                </th>
                                <th className="rev-table-head__heading rev-table-head__heading--sortable">
                                    <div
                                        onClick={e =>
                                            this.sortField("pageviews")
                                        }
                                        className="revenue-table-head table-head__pageviews"
                                    >
                                        Pageviews
                                        {this.state.sortField === "pageviews" &&
                                            this.state.sortByHighest !== null &&
                                            (this.state.sortByHighest ===
                                            true ? (
                                                <ChevronUp />
                                            ) : (
                                                <ChevronDown />
                                            ))}
                                    </div>
                                </th>
                                <th className="rev-table-head__heading rev-table-head__heading--sortable">
                                    <div
                                        onClick={e =>
                                            this.sortField("impsessions")
                                        }
                                        className="revenue-table-head table-head__impsessions"
                                    >
                                        Imps/Session
                                        {this.state.sortField ===
                                            "impsessions" &&
                                            this.state.sortByHighest !== null &&
                                            (this.state.sortByHighest ===
                                            true ? (
                                                <ChevronUp />
                                            ) : (
                                                <ChevronDown />
                                            ))}
                                    </div>
                                </th>
                                <th className="rev-table-head__heading rev-table-head__heading--sortable">
                                    <div
                                        onClick={e =>
                                            this.sortField("sessions")
                                        }
                                        className="revenue-table-head table-head__sessions"
                                    >
                                        Sessions
                                        {this.state.sortField === "sessions" &&
                                            this.state.sortByHighest !== null &&
                                            (this.state.sortByHighest ===
                                            true ? (
                                                <ChevronUp />
                                            ) : (
                                                <ChevronDown />
                                            ))}
                                    </div>
                                </th>
                                <th className="rev-table-head__heading rev-table-head__heading--sortable">
                                    <div
                                        onClick={e => this.sortField("pps")}
                                        className="revenue-table-head table-head__pps"
                                    >
                                        PPS
                                        {this.state.sortField === "pps" &&
                                            this.state.sortByHighest !== null &&
                                            (this.state.sortByHighest ===
                                            true ? (
                                                <ChevronUp />
                                            ) : (
                                                <ChevronDown />
                                            ))}
                                    </div>
                                </th>
                                <th className="rev-table-head__heading rev-table-head__heading--sortable">
                                    <div
                                        onClick={e => this.sortField("RPM")}
                                        className="revenue-table-head table-head__cpm"
                                    >
                                        RPM
                                        {this.state.sortField === "RPM" &&
                                            this.state.sortByHighest !== null &&
                                            (this.state.sortByHighest ===
                                            true ? (
                                                <ChevronUp />
                                            ) : (
                                                <ChevronDown />
                                            ))}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <Modal
                                open={this.state.graphModalOpen}
                                onClose={this.handleModalToggle}
                            >
                                <div className="revenue-modal">
                                    <div className="revenue-modal__title">
                                        Metrics Over Time
                                    </div>
                                    <RevenueMetricsGraph
                                        data={this.state.modalCurrentData}
                                    />
                                </div>
                            </Modal>
                            {this.state.data &&
                                this.state.data
                                    .slice(pageStart, pageEnd)
                                    .filter(val => {
                                        if (this.state.ampFilter) {
                                            return (
                                                val.pagePath &&
                                                !val.pagePath.indexOf("amp") !==
                                                    -1
                                            );
                                        } else {
                                            return val;
                                        }
                                    })
                                    .map((val, i) => {
                                        totals.pageviews =
                                            totals.pageviews +
                                            (val.pageviews ? val.pageviews : 0);
                                        totals.impressions =
                                            totals.impressions +
                                            !!val.impressions
                                                ? val.impressions
                                                : 0;
                                        totals.impspv =
                                            totals.impspv +
                                            (!!val.impressions &&
                                            !!val.pageviews
                                                ? val.impressions /
                                                  val.pageviews
                                                : 0);
                                        totals.revenue =
                                            totals.revenue +
                                            (val.revenue ? val.revenue : 0);
                                        totals.pps =
                                            totals.pps +
                                            (!!val.pageviews && val.sessions
                                                ? val.pageviews / val.sessions
                                                : 0);
                                        totals.impsessions =
                                            totals.impsessions +
                                            (val.impressions && val.sessions
                                                ? val.impressions /
                                                  val.sessions /
                                                  this.state.data.slice(
                                                      pageStart,
                                                      pageEnd
                                                  ).length
                                                : 0);
                                        totals.rpm =
                                            totals.rpm +
                                            (!!val.revenue && !!val.impressions)
                                                ? val.revenue /
                                                  val.impressions /
                                                  1000
                                                : 0;
                                        totals.sessions =
                                            totals.sessions +
                                            (val.sessions ? val.sessions : 0);
                                        totals.revpv =
                                            totals.revpv +
                                            (!!val.revenue && !!val.pageviews
                                                ? val.revenue / val.pageviews
                                                : 0);
                                        return (
                                            <tr
                                                key={i}
                                                className="rev-table-row"
                                            >
                                                {/* <td className="rev-table-item rev-table-item__article--img">
                                    <img
                                        src="https://www.narcity.com/g00/3_c-8yyy.pctekva.eqo_/c-8OQTGRJGWU46x24jvvrux3ax2fx2fyyy.pctekva.eqox2fwx2f4230x2f33x2f40x2f8e225h645fgfd823d565648he43026707d128601.rpi_3422z852.rpix3fk32e.octmx3dkocig_$/$/$/$/$/$"
                                        alt="party pic"
                                        className="rev-table-item--img"
                                    />
                                </td> */}
                                                <td className="rev-table-item rev-table-item__id">
                                                    {!!val.key
                                                        ? val.key
                                                        : "N/A"}
                                                </td>
                                                <td className="rev-table-item rev-table-item__chart">
                                                    <IconButton
                                                        color="primary"
                                                        disabled={
                                                            Object.values(
                                                                val.history
                                                            ).length < 2
                                                        }
                                                        onClick={e =>
                                                            this.handleModalToggle(
                                                                val
                                                            )
                                                        }
                                                    >
                                                        <ChartAreaspline />
                                                    </IconButton>
                                                </td>
                                                <td className="rev-table-item rev-table-item__article">
                                                    {!!val.pagePath ? (
                                                        <a
                                                            href={`https://${
                                                                val.pagePath
                                                            }`}
                                                            target="_blank"
                                                            className="rev-table-item--article-url"
                                                        >
                                                            {val.headline
                                                                ? val.headline
                                                                : "Headline Missing"}
                                                        </a>
                                                    ) : val.headline ? (
                                                        val.headline
                                                    ) : (
                                                        "Headline Missing"
                                                    )}
                                                </td>
                                                <td className="rev-table-item">
                                                    {val.revenue
                                                        ? "$" +
                                                          this.convertNanoDollars(
                                                              val.revenue
                                                          )
                                                              .toFixed(2)
                                                              .toLocaleString()
                                                        : "N/A"}
                                                </td>
                                                <td className="rev-table-item">
                                                    {val.revenue && val.sessions
                                                        ? "$" +
                                                          this.convertNanoDollars(
                                                              val.revenue /
                                                                  val.sessions
                                                          ).toFixed(5)
                                                        : "N/A"}
                                                </td>
                                                <td className="rev-table-item">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            marginLeft: "1rem"
                                                        }}
                                                    >
                                                        {val.pageviews
                                                            ? val.pageviews.toLocaleString()
                                                            : "N/A"}
                                                        {this.state
                                                            .dateSearched && (
                                                            <History
                                                                onClick={e => {
                                                                    this.context.setState(
                                                                        {
                                                                            trafficUrl:
                                                                                val.pagePath
                                                                        }
                                                                    );
                                                                    return this.setState(
                                                                        {
                                                                            redirect: true
                                                                        }
                                                                    );
                                                                }}
                                                                color="primary"
                                                                style={{
                                                                    cursor:
                                                                        "pointer",
                                                                    marginLeft:
                                                                        "0.5rem",
                                                                    width:
                                                                        "0.8em"
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="rev-table-item">
                                                    {val.impressions &&
                                                    val.sessions
                                                        ? (
                                                              val.impressions /
                                                              val.sessions
                                                          ).toLocaleString()
                                                        : "N/A"}
                                                </td>

                                                <td className="rev-table-item">
                                                    {val.sessions
                                                        ? val.sessions.toLocaleString()
                                                        : "N/A"}
                                                </td>
                                                <td className="rev-table-item">
                                                    {val.pageviews &&
                                                    val.sessions
                                                        ? (
                                                              val.pageviews /
                                                              val.sessions
                                                          ).toFixed(2)
                                                        : "N/A"}
                                                </td>
                                                <td className="rev-table-item">
                                                    $
                                                    {val.revenue &&
                                                    val.impressions
                                                        ? (
                                                              val.revenue /
                                                              val.impressions /
                                                              1000
                                                          ).toFixed(2)
                                                        : "N/A"}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            <tr className="rev-table-row rev-table-row__totals">
                                <td className="rev-table-item rev-table-item__total">
                                    &nbsp;
                                </td>
                                <td className="rev-table-item rev-table-item__total">
                                    &nbsp;
                                </td>
                                <td
                                    onClick={e =>
                                        console.log(
                                            this.state.metricFilterValues
                                        )
                                    }
                                    className="rev-table-item rev-table-item__total"
                                >
                                    Totals
                                </td>
                                <td className="rev-table-item rev-table-item__total">
                                    $
                                    {this.convertNanoDollars(totals.revenue)
                                        .toFixed(2)
                                        .toLocaleString()}
                                </td>
                                <td className="rev-table-item rev-table-item__total">
                                    $
                                    {this.convertNanoDollars(
                                        totals.revenue / totals.pageviews
                                    ).toFixed(5)}
                                </td>
                                <td className="rev-table-item rev-table-item__total">
                                    {totals.pageviews.toLocaleString()}
                                </td>
                                <td className="rev-table-item rev-table-item__total">
                                    {totals.impsessions.toFixed(2)}
                                </td>
                                <td className="rev-table-item rev-table-item__total">
                                    {totals.sessions.toLocaleString()}
                                </td>
                                <td className="rev-table-item rev-table-item__total">
                                    {(
                                        totals.pageviews / totals.sessions
                                    ).toFixed(2)}
                                </td>
                                <td className="rev-table-item rev-table-item__total">
                                    {totals.rpm.toFixed(2)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="revenue-table__footer">
                    <div className="pagination-buttons">
                        <div
                            className="paginationButton"
                            onClick={e => this.previousPage()}
                        >
                            <ArrowLeft color="primary" />{" "}
                            <span className="padingationButton__text-left">
                                Back
                            </span>
                        </div>
                        <div className="revenue-table__footer-rowAmount">
                            Page {this.state.currentPage + 1}
                        </div>
                        <div
                            className="paginationButton"
                            onClick={e => this.nextPage()}
                        >
                            <span className="padingationButton__text">
                                Next
                            </span>{" "}
                            <ArrowRight color="primary" />
                        </div>
                    </div>
                </div>
                <div className="pagination-amount">
                    <div
                        onClick={e => this.changePagination(20)}
                        className="pagination-amount__item pagination-amount__item"
                    >
                        20
                    </div>
                    <div
                        onClick={e => this.changePagination(50)}
                        className="pagination-amount__item"
                    >
                        50
                    </div>
                    <div
                        onClick={e => this.changePagination(100)}
                        className="pagination-amount__item"
                    >
                        100
                    </div>
                </div>
            </div>
        );
    }
}

//trending
// {this.isTrending(val) ? (
//     <div className="rev-table-item__impressions">
//         <Fire color="secondary" />
//         {val.PRICE_PRIORITY
//             ? parseFloat(
//                   val
//                       .PRICE_PRIORITY
//                       .impressions
//               ).toLocaleString()
//             : "N/A"}
//     </div>
// ) : val.PRICE_PRIORITY ? (
//     parseFloat(
//         val.PRICE_PRIORITY
//             .impressions
//     ).toLocaleString()
// ) : (
//     "N/A"
// )}
// {
//     /* {this.state.filteredItems
//                                 ? Object.keys(this.state.filteredItems).map(
//                                       y => {
//                                           let x = allUsers[y];
//                                           return (
//                                               <tr
//                                                   key={y}
//                                                   className={`table-row table-item`}
//                                               > */
// }
// {
//     /* {x.photoURL ? (
//                                                           <Avatar
//                                                               src={x.photoURL}
//                                                           />
//                                                       ) : ( */
// }
