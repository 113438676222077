export const config = {
    dev: {
        apiKey: "AIzaSyARBT3AyCFA0378X8-_5MRcVfNEhMoCy7E",
        authDomain: "narcity-data-apps.firebaseapp.com",
        databaseURL: "https://narcity-data-apps.firebaseio.com",
        projectId: "narcity-data-apps",
        storageBucket: "narcity-data-apps.appspot.com",
        messagingSenderId: "708592598499"
    },
    prod: {
        apiKey: "AIzaSyDpyiXcPhMxUhZNfo6CbRfDg_0jVuJhgjA",
        authDomain: "narcity-dev-env.firebaseapp.com",
        databaseURL: "https://narcity-dev-env.firebaseio.com",
        projectId: "narcity-dev-env",
        storageBucket: "narcity-dev-env.appspot.com",
        messagingSenderId: "908807755812"
    }
};
