import React from "react";
import { Error } from "@material-ui/icons";

export default class ReportError extends React.Component {
    render() {
        return (
            <div className={`report-error report-error--${this.props.type}`}>
                <div className="report-error__container">
                    <div className="report-error__icon">
                        <Error />
                    </div>
                    <div className="report-error__msg">
                        {" "}
                        {this.props.title} has encountered an error!
                    </div>
                </div>
            </div>
        );
    }
}
