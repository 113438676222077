import React from "react";

export default class ReportCustom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { report } = this.props;
        return (
            <>
                <div className="reportCustom-images__title">
                    {report.title ? report.title : "Custom Component"}
                </div>
                <div className="report-custom-container">
                    <div className="reportCustom">
                        <div
                            className="reportCustom-images"
                            style={{
                                overflow:
                                    report.media.length > 1 ? "scroll" : null
                            }}
                        >
                            {report.media
                                ? Object.values(report.media).map((val, i) => {
                                      return (
                                          <img
                                              style={{
                                                  width:
                                                      report.media.length > 1
                                                          ? null
                                                          : `100%`,
                                                  paddingLeft:
                                                      report.media.length > 1
                                                          ? "0.3rem"
                                                          : null,
                                                  maxHeight:
                                                      report.media.length > 1
                                                          ? "22rem"
                                                          : "30rem"
                                              }}
                                              className="reportCustom-images__image"
                                              src={val}
                                              key={i}
                                              alt=""
                                          />
                                      );
                                  })
                                : null}
                        </div>
                        <div className="reportCustom-content">
                            <div className="reportCustom-content__list">
                                {Object.values(report).map((val, i) => {
                                    console.log(val);
                                    if (!!val["label"]) {
                                        return (
                                            <div
                                                key={i}
                                                className="content-items"
                                            >
                                                <div className="content-items__label">
                                                    {val.label
                                                        ? val.label
                                                        : "Label"}
                                                </div>
                                                <div className="content-items__value">
                                                    {val.stat
                                                        ? parseFloat(
                                                              val.stat
                                                          ).toLocaleString()
                                                        : "Value"}
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
