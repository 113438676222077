import React from "react";
import { Consumer } from "../Context";
import { Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

export default class Tile extends React.Component {
    render() {
        let { report, id } = { ...this.props };
        return (
            <Consumer>
                {context => {
                    return (
                        <Paper
                            component={Link}
                            to={"reports/" + id}
                            style={{
                                margin: 10,
                                width: 300,
                                height: 250,
                                display: "flex",
                                flexFlow: "column nowrap",
                                justifyContent: "space-between",
                                position: "relative"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: 10,
                                        marginTop: 10
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontFamily: "Roboto Condensed",
                                            fontSize: 18,
                                            textAlign: "left",
                                            textOverflow: "ellipsis",
                                            width: 280,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden"
                                        }}
                                    >
                                        {report.form.projectName}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontSize: 10,
                                            textAlign: "left"
                                        }}
                                    >
                                        {report.form.date.startDate +
                                            " - " +
                                            report.form.date.endDate}
                                    </Typography>
                                </div>
                            </div>
                            {report.content && report.content[0].articleMeta ? (
                                <img
                                    src={report.content[0].articleMeta.image}
                                    alt={"img"}
                                    style={{
                                        width: "100%",
                                        height: 180,
                                        objectFit: "cover"
                                    }}
                                />
                            ) : report.dfp ? (
                                <img
                                    src={require("./SVG/dfpGraphic.svg")}
                                    alt={"img"}
                                    style={{
                                        width: "100%",
                                        height: 180,
                                        objectFit: "cover"
                                    }}
                                />
                            ) : report.fb || report.fbads ? (
                                <img
                                    src={require("./SVG/facebookGraphic.svg")}
                                    alt={"img"}
                                    style={{
                                        width: "100%",
                                        height: 180,
                                        objectFit: "cover"
                                    }}
                                />
                            ) : null}
                            {/* <div style={styles.iconConstainer}>
                                {report.content && (
                                    <img
                                        style={styles.icons}
                                        src={require("./SVG/lillium.svg")}
                                    />
                                )}
                                {report.content && (
                                    <img
                                        style={styles.icons}
                                        src={require("./SVG/google.svg")}
                                    />
                                )}
                                {report.fb && (
                                    <img
                                        style={styles.icons}
                                        src={require("./SVG/facebook.svg")}
                                    />
                                )}
                                {report.fbads && (
                                    <img
                                        style={styles.icons}
                                        src={require("./SVG/facebookAds.svg")}
                                    />
                                )}
                                {report.dfp && (
                                    <img
                                        style={styles.icons}
                                        src={require("./SVG/googleAds.svg")}
                                    />
                                )}
                            </div> */}
                        </Paper>
                    );
                }}
            </Consumer>
        );
    }
}
