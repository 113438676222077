import React from "react";
import Map from "../Map/Map";

export default class MapComponent extends React.Component {
    render() {
        return (
            <div className="reports__content reports__content--map">
                <div className="reports__details reports__details--full">
                    <div className="map-container">
                        <Map
                            markers={
                                this.props.report.content[this.props.index].geo
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}
