import React from "react";
import { Consumer } from "../Context";
import {
    Slide,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText
} from "@material-ui/core";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

export default class DialogPrompt extends React.Component {
    handleClose = context => {
        context.setState({ dialog: null });
    };
    render() {
        return (
            <Consumer>
                {context => {
                    return (
                        <Dialog
                            open={context.state.dialog ? true : false}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => this.handleClose(context)}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">
                                {context.state.dialog.title || "Attention"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    {context.state.dialog.msg}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => this.handleClose(context)}
                                    color="primary"
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    );
                }}
            </Consumer>
        );
    }
}
