import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import { Consumer } from "../Context";
export default class RevenueMetricsGraph extends React.Component {
    static contextType = Consumer;
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            revenue: true,
            revenuelps: false,
            pageviews: false,
            uniquePageviews: false,
            sessions: true,
            pps: true,
            impressions: false,
            impsession: false,
            rpm: true,
            splitChart: true
        };
    }

    toggleChange = state => {
        this.setState({ [state]: !this.state[state] });
    };

    convertNanoDollars = nano => {
        return nano / 1000000;
    };

    componentWillMount() {
        let data = Object.values(this.props.data).reduce((acc, val, i) => {
            return [
                ...acc,
                {
                    name: Object.keys(this.props.data)[i],
                    ...val,
                    revenue: this.convertNanoDollars(val.revenue).toFixed(2),
                    revenuelps: (
                        this.convertNanoDollars(val.revenue) / val.sessions
                    ).toFixed(5),
                    pps: (val.pageviews / val.sessions).toFixed(2),
                    impsession: (val.impressions / val.sessions).toFixed(2),
                    rpm: (val.revenue / val.impressions / 1000).toFixed(2)
                }
            ];
        }, []);
        this.setState({ data });
    }

    componentDidMount() {
        console.log(this.state.data);
    }

    handleCheck() {
        this.setState({
            revenue: true,
            revenuelps: true,
            pageviews: true,
            impsession: true,
            sessions: true,
            pps: true,
            impressions: true,
            uniquePageviews: true,
            rpm: true
        });
    }

    render() {
        return (
            <div className="revenue-modal-graph">
                <div className="revenue-modal-graph__checkboxes">
                    <ul className="revenue-modal-graph__checkboxes-list">
                        <li className="revenue-modal-graph__list-item">
                            <input
                                style={{ marginRight: ".4rem" }}
                                type="checkbox"
                                checked={this.state.revenue}
                                onChange={e => this.toggleChange("revenue")}
                            />
                            Revenue
                        </li>
                        <li className="revenue-modal-graph__list-item">
                            <input
                                style={{ marginRight: ".4rem" }}
                                type="checkbox"
                                checked={this.state.pageviews}
                                onChange={e => this.toggleChange("pageviews")}
                            />
                            Pageviews
                        </li>
                        <li className="revenue-modal-graph__list-item">
                            <input
                                style={{ marginRight: ".4rem" }}
                                type="checkbox"
                                checked={this.state.uniquePageviews}
                                onChange={e =>
                                    this.toggleChange("uniquePageviews")
                                }
                            />
                            Unique. PV
                        </li>
                        <li className="revenue-modal-graph__list-item">
                            <input
                                style={{ marginRight: ".4rem" }}
                                type="checkbox"
                                checked={this.state.impressions}
                                onChange={e => this.toggleChange("impressions")}
                            />
                            Impressions
                        </li>
                        <li className="revenue-modal-graph__list-item">
                            <input
                                style={{ marginRight: ".4rem" }}
                                type="checkbox"
                                checked={this.state.sessions}
                                onChange={e => this.toggleChange("sessions")}
                            />
                            Sessions
                        </li>
                        <li>
                            <div className="revenue-modal-graph__seperator" />
                        </li>
                        <li className="revenue-modal-graph__list-item">
                            <input
                                style={{ marginRight: ".4rem" }}
                                type="checkbox"
                                checked={this.state.impsession}
                                onChange={e => this.toggleChange("impsession")}
                            />
                            Imps/Session
                        </li>
                        <li className="revenue-modal-graph__list-item">
                            <input
                                style={{ marginRight: ".4rem" }}
                                type="checkbox"
                                checked={this.state.revenuelps}
                                onChange={e => this.toggleChange("revenuelps")}
                            />
                            Revenue/LPS
                        </li>
                        <li className="revenue-modal-graph__list-item">
                            <input
                                style={{ marginRight: ".4rem" }}
                                type="checkbox"
                                checked={this.state.pps}
                                onChange={e => this.toggleChange("pps")}
                            />
                            PPS
                        </li>
                        <li className="revenue-modal-graph__list-item">
                            <input
                                style={{ marginRight: ".4rem" }}
                                type="checkbox"
                                checked={this.state.rpm}
                                onChange={e => this.toggleChange("rpm")}
                            />
                            RPM
                        </li>
                        <li className="revenue-modal-graph__list-item">
                            <div
                                onClick={e => this.handleCheck()}
                                className="revenue-button revenue-button--uncheck-button"
                            >
                                Show All
                            </div>
                        </li>
                    </ul>
                </div>
                <div
                    className="revenue-modal-graph__graph"
                    style={{ height: this.state.splitChart ? "50%" : "100%" }}
                >
                    <ResponsiveContainer width={"95%"} height={"100%"}>
                        <LineChart
                            syncId="top"
                            data={this.state.data}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                            <XAxis dataKey="name" />
                            <YAxis
                                scale="log"
                                domain={["dataMin", "dataMax"]}
                            />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            {this.state.revenue && (
                                <Line
                                    type="monotone"
                                    dataKey="revenue"
                                    stroke="#8884d8"
                                />
                            )}
                            {this.state.pageviews && (
                                <Line
                                    type="monotone"
                                    dataKey="pageviews"
                                    stroke="#e773c5"
                                />
                            )}
                            {this.state.sessions && (
                                <Line
                                    type="monotone"
                                    dataKey="sessions"
                                    stroke="#ff6c8a"
                                />
                            )}
                            {this.state.impressions && (
                                <Line
                                    type="monotone"
                                    dataKey="impressions"
                                    stroke="#d587f0"
                                />
                            )}
                            {this.state.uniquePageviews && (
                                <Line
                                    type="monotone"
                                    dataKey="uniquePageviews"
                                    stroke="#ff8d3e"
                                />
                            )}
                            {this.state.pps && !this.state.splitChart && (
                                <Line
                                    type="monotone"
                                    dataKey="pps"
                                    stroke="#ff6bab"
                                />
                            )}
                            {this.state.rpm && !this.state.splitChart && (
                                <Line
                                    type="monotone"
                                    dataKey="rpm"
                                    stroke="#22add6"
                                />
                            )}
                            {this.state.revenuelps &&
                                !this.state.splitChart && (
                                    <Line
                                        type="monotone"
                                        dataKey="revenuelps"
                                        stroke="#d587f0"
                                    />
                                )}
                            {this.state.impsession &&
                                !this.state.splitChart && (
                                    <Line
                                        type="monotone"
                                        dataKey="impsession"
                                        stroke="#ff9882"
                                    />
                                )}
                        </LineChart>
                    </ResponsiveContainer>
                    {this.state.splitChart && (
                        <ResponsiveContainer width={"95%"} height={"100%"}>
                            <LineChart
                                syncId="top"
                                data={this.state.data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <XAxis dataKey="name" />
                                <YAxis
                                    scale="log"
                                    domain={["dataMin", "dataMax"]}
                                />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip />
                                <Legend />
                                {this.state.pps && this.state.splitChart && (
                                    <Line
                                        type="monotone"
                                        dataKey="pps"
                                        stroke="#ff6bab"
                                    />
                                )}
                                {this.state.rpm && this.state.splitChart && (
                                    <Line
                                        type="monotone"
                                        dataKey="rpm"
                                        stroke="#6cadd6"
                                    />
                                )}
                                {this.state.revenuelps &&
                                    this.state.splitChart && (
                                        <Line
                                            type="monotone"
                                            dataKey="revenuelps"
                                            stroke="#d587f0"
                                        />
                                    )}
                                {this.state.impsession &&
                                    this.state.splitChart && (
                                        <Line
                                            type="monotone"
                                            dataKey="impsession"
                                            stroke="#ff9882"
                                        />
                                    )}
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </div>
            </div>
        );
    }
}
