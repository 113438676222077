import React from "react";
import { Button } from "@material-ui/core";
import Report from "./Report";

const styles = {
    page: {
        position: "fixed",
        zIndex: 1,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        overflow: "auto",
        backgroundColor: "rgba(0,0,0,0.4)"
    },
    modal: {
        backgroundColor: "#fefefe",
        margin: "5% auto",
        border: "1px solid #888",
        width: "95%"
    }
};

export default class PreviewData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }
    componentDidCatch(err, info) {
        this.setState({ hasError: true });
    }
    render() {
        return !this.state.hasError ? (
            <div style={styles.page}>
                <div style={styles.modal}>
                    <Report report={this.props.report} />
                </div>
                <div style={{ position: "fixed", bottom: 10, right: 10 }}>
                    <Button
                        style={{ margin: 10 }}
                        color="primary"
                        variant="contained"
                        onClick={e => {
                            this.props.saveReport();
                        }}
                    >
                        CREATE
                    </Button>
                    <Button
                        style={{ margin: 10 }}
                        color="primary"
                        variant="contained"
                        onClick={e => {
                            this.props.editReport();
                        }}
                    >
                        EDIT
                    </Button>
                    <Button
                        style={{ margin: 10 }}
                        color="secondary"
                        variant="contained"
                        onClick={e => {
                            this.props.clearReport();
                        }}
                    >
                        CLEAR
                    </Button>
                </div>
            </div>
        ) : (
            this.props.hasError
        );
    }
}
