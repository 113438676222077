import React from "react";
//import { Paper, Typography } from '@material-ui/core'
let styles = {
    background: {
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        position: "absolute",
        top: 0,
        zIndex: "-1"
    }
};

export default class Landing extends React.Component {
    componentWillMount() {
        window.location.assign("http://narcitymedia.com");
    }
    render() {
        return (
            <div style={styles.background}>
                <div
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(248, 247, 216, 0.3)"
                    }}
                />
            </div>
        );
    }
}
